import React from 'react';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Routes } from 'constants/routes';

import { H1 } from 'ui-atoms/Heading';
import { Flex } from 'ui-atoms/Flex';

import { Logo } from 'ui-atoms/Logo';
import { useAuthentication } from 'store/authentication';
import { useTitle } from 'react-use';
import { LoginForm } from './LoginForm';

import styles from './Login.module.scss';

const Login = () => {
  const { t } = useTranslation();

  const pageTitle = t('Log in');

  useTitle(`MonaTek - ${pageTitle}`);
  const authentication = useAuthentication();

  return (
    <>
      <main className={styles.root}>
        <div className={styles.formWrap}>
          <div className={styles.logoWrap}>
            <Logo />
          </div>
          <Flex direction="column" gap={3}>
            <H1>{pageTitle}</H1>
            <LoginForm />
            {authentication.authenticated && (
              <Navigate to={Routes.home} replace />
            )}
          </Flex>
        </div>
      </main>
      <footer className={styles.footer}>
        <p>
          {t('Email')}: <a href="mailto:info@monatek.eu">info@monatek.eu</a>
        </p>
      </footer>
    </>
  );
};

export default Login;
