import type { ReactNode } from 'react';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';

import { Routes } from 'constants/routes';
import { Logo } from 'ui-atoms/Logo';
import { useNavigation } from 'store/navigation';
import { Search } from './Search';
import { UserMenu } from './UserMenu';
import styles from './Shell.module.scss';
import { NavigationPanel } from './NavigationPanel';

type ShellProps = {
  children: ReactNode;
};

export const Shell = ({ children }: ShellProps) => {
  const navigation = useNavigation();

  // Scroll to top on route change
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div
      className={classNames(styles.root, {
        [styles.isHeaderOpen]: navigation.opened,
      })}
    >
      <div className={styles.view}>
        <header className={styles.toolbar}>
          <div className={styles.toolbarLeft}>
            <NavigationPanel />
            <NavLink className={styles.logo} to={Routes.home} end>
              <Logo />
            </NavLink>
          </div>
          <Search />
          <UserMenu />
        </header>
        <main className={styles.content}>{children}</main>
      </div>
    </div>
  );
};
