import React, { useEffect } from 'react';

import { getScrollbarWidth } from 'helpers/getScrollbarWidth';

import { useWindowSize } from 'hooks/useWindowSize';

import { useNavigation } from 'store/navigation';

import { Logo } from 'ui-atoms/Logo';
import { NavLink } from 'react-router-dom';
import { Routes } from 'constants/routes';
import { Navigation } from './Navigation';

import styles from './NavigationPanel.module.scss';

import { PrivacyPolicy } from './PrivacyPolicy';

const scrollbarWidth = getScrollbarWidth();

export const Container = () => {
  const navigation = useNavigation();

  const { windowWidth } = useWindowSize();

  const isSmallScreen = windowWidth <= 540;

  useEffect(() => {
    const isNoBodyScroll = isSmallScreen && navigation.opened;
    document.body.style.paddingRight = !isNoBodyScroll
      ? ''
      : `${scrollbarWidth}px`;
    document.body.style.overflow = !isNoBodyScroll ? '' : 'hidden';

    return () => {
      document.body.style.paddingRight = '';
      document.body.style.overflow = '';
    };
  }, [isSmallScreen, navigation.opened]);

  return (
    <nav className={styles.root}>
      {isSmallScreen && (
        <div className={styles.header}>
          <NavLink
            className={styles.logo}
            to={Routes.home}
            end
            onClick={() => navigation.close()}
          >
            <Logo />
          </NavLink>
        </div>
      )}
      <div className={styles.content}>
        <Navigation />
        <PrivacyPolicy />
      </div>
    </nav>
  );
};
