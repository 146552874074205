import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDebounce } from '@react-hook/debounce';

import { TextField } from 'ui-atoms/TextField';
import { Search } from 'ui-atoms/Icons/Search';
import { Routes } from 'constants/routes';
import { useSearch } from 'store/search';
import { useWindowSize } from 'hooks/useWindowSize';

export const SearchField = () => {
  const { t } = useTranslation();

  const { search, setSearch } = useSearch();
  const [value, setValue] = useDebounce(search, 600);
  const navigate = useNavigate();
  const location = useLocation();

  const { windowWidth } = useWindowSize();
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSearch(value);
    if (value) {
      // So correct tab is opened when navigating to search page
      navigate(Routes.search, { state: { previousRoute: location.pathname } });
    }
  }, [value]);

  useEffect(() => {
    if (search === '') {
      setValue('');

      if (ref?.current?.value) {
        ref.current.value = '';
      }
    }
  }, [search]);

  useEffect(() => {
    if (windowWidth <= 540) {
      ref.current?.focus();
    }
  }, [windowWidth]);

  return (
    <TextField
      ref={ref}
      name="search"
      placeholder={t('Search')}
      defaultValue={value}
      suffix={<Search size={24} />}
      autoComplete="off"
      onChange={(e) => setValue(e.target.value)}
    />
  );
};
