import React from 'react';

import styles from './Icon.module.scss';

interface Props {
  size?: number;
}

export const LogIn = ({ size }: Props) => (
  <svg
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.root}
    style={!size ? {} : { width: size, height: size }}
  >
    <path
      d="M8.33325 15.0064L12.5003 10.8394L8.33325 6.67236"
      stroke="currentColor"
      strokeWidth="1.66678"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5001 10.8393H2.49927"
      stroke="currentColor"
      strokeWidth="1.66678"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 3.33864H15.8336C16.2756 3.33864 16.6996 3.51425 17.0122 3.82684C17.3248 4.13942 17.5004 4.56338 17.5004 5.00544V16.673C17.5004 17.1151 17.3248 17.539 17.0122 17.8516C16.6996 18.1642 16.2756 18.3398 15.8336 18.3398H12.5"
      stroke="currentColor"
      strokeWidth="1.66678"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
