import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useSwitchLanguageMutation, useUserMenuQuery } from 'generated/graphql';

import { makeToast } from 'ui-atoms/Toast';
import styles from './Language.module.scss';

export const Language = () => {
  const { i18n, t } = useTranslation();

  const { data } = useUserMenuQuery();

  const [switchLanguage] = useSwitchLanguageMutation({
    onError: () => makeToast.error(t('An error has occurred.')),
  });

  const languages = Array.isArray(i18n.options.supportedLngs)
    ? i18n.options.supportedLngs
    : [];

  const user = data?.userCurrent;

  return !user ? null : (
    <div className={styles.root}>
      {languages
        .filter(
          (language) =>
            // "cimode" could be useful for testing https://www.i18next.com/how-to/faq#how-to-handle-with-changes-in-e2e-tests
            language !== 'cimode',
        )
        .map((language) => (
          <button
            key={language}
            type="button"
            className={classNames(styles.button, {
              [styles.active]: language === i18n.language,
            })}
            onClick={() => {
              i18n.changeLanguage(language);
              switchLanguage({
                variables: {
                  id: user._id,
                  language,
                },
              });
            }}
          >
            {language.toUpperCase()}
          </button>
        ))}
    </div>
  );
};
