import { create } from 'zustand';
import { useEffect } from 'react';

import { get } from 'helpers/api';

type AuthenticationState = {
  authenticated: null | boolean;
  authenticate: () => void;
  refuse: () => void;
};

const useStore = create<AuthenticationState>()((set) => ({
  authenticated: null,
  authenticate: async () => {
    const person = await get<Promise<{ isAuthenticated: null | boolean }>>(
      '/cloud/user/authenticated',
    );
    set({ authenticated: person.isAuthenticated });
  },
  refuse: () => set(() => ({ authenticated: false })),
}));

export const useAuthentication = () => {
  return useStore((state) => state);
};

export const useAuthenticate = () => {
  const authentication = useAuthentication();

  useEffect(() => {
    authentication.authenticate();
  }, []);

  return authentication;
};
