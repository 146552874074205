import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type AlertRule = {
  __typename?: 'AlertRule';
  _id: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  deviceIds: Array<Scalars['ID']['output']>;
  deviceTypeCategory: DeviceTypeCategory;
  devicesWhichHaveBrokenTheRule?: Maybe<Array<Scalars['ID']['output']>>;
  emails: Array<Scalars['String']['output']>;
  organisationId: Scalars['ID']['output'];
  rules: Array<Rule>;
  updatedAt: Scalars['DateTime']['output'];
};

export type AlertRuleCreateParameters = {
  deviceIds: Array<Scalars['String']['input']>;
  deviceTypeCategory: DeviceTypeCategory;
  rules: Array<AlertRuleParameters>;
};

export type AlertRuleListQuery = {
  __typename?: 'AlertRuleListQuery';
  paging: Paging;
  result: Array<AlertRule>;
};

export enum AlertRuleLogicalOperator {
  And = 'AND',
  Or = 'OR',
  When = 'WHEN'
}

export type AlertRuleParameters = {
  logicalOperator: AlertRuleLogicalOperator;
  measurementName: MeasurementName;
  range: AlertRuleRange;
  reducer: AlertRuleReducer;
  timeSpan: Scalars['Float']['input'];
  value: Array<Scalars['Float']['input']>;
};

export enum AlertRuleRange {
  Above = 'ABOVE',
  Below = 'BELOW',
  HasNoValue = 'HAS_NO_VALUE',
  OutsideRange = 'OUTSIDE_RANGE',
  WithinRange = 'WITHIN_RANGE'
}

export enum AlertRuleReducer {
  Avg = 'AVG',
  Count = 'COUNT',
  CountNonNull = 'COUNT_NON_NULL',
  Diff = 'DIFF',
  DiffAbs = 'DIFF_ABS',
  Last = 'LAST',
  Max = 'MAX',
  Median = 'MEDIAN',
  Min = 'MIN',
  PercentDiff = 'PERCENT_DIFF',
  PercentDiffAbs = 'PERCENT_DIFF_ABS',
  Sum = 'SUM'
}

export type AlertRuleUpdateParameters = {
  _id: Scalars['String']['input'];
  deviceIds: Array<Scalars['String']['input']>;
  deviceTypeCategory: DeviceTypeCategory;
  rules: Array<AlertRuleParameters>;
};

export type ApiToken = {
  __typename?: 'ApiToken';
  token: Scalars['String']['output'];
};

export type BaseStation = {
  __typename?: 'BaseStation';
  _id: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  decdevid: Scalars['String']['output'];
  devid: Scalars['String']['output'];
  lastSignalTime?: Maybe<Scalars['Float']['output']>;
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['ID']['output']>;
  organisationId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BaseStationCreateParameters = {
  decdevid: Scalars['String']['input'];
  locationId?: InputMaybe<Scalars['String']['input']>;
};

export type BaseStationListQuery = {
  __typename?: 'BaseStationListQuery';
  paging: Paging;
  result: Array<BaseStation>;
};

export type BaseStationListSortParameters = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
};

export type BaseStationUpdateParameters = {
  _id: Scalars['String']['input'];
  decdevid?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserParameters = {
  admin: Scalars['Boolean']['input'];
  allowedLocations?: InputMaybe<Array<Scalars['String']['input']>>;
  baseStationsEdit: Scalars['Boolean']['input'];
  baseStationsView: Scalars['Boolean']['input'];
  devicesEdit: Scalars['Boolean']['input'];
  devicesView: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  locationsEdit: Scalars['Boolean']['input'];
  locationsView: Scalars['Boolean']['input'];
  logView: Scalars['Boolean']['input'];
  organisationId?: InputMaybe<Scalars['String']['input']>;
  organisationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  password: Scalars['String']['input'];
};

export type CurrentPulses = {
  __typename?: 'CurrentPulses';
  counter?: Maybe<Scalars['Float']['output']>;
  time: Scalars['Float']['output'];
};

export type Dashboard = {
  __typename?: 'Dashboard';
  _id: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  layout: Array<Layout>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['ID']['output'];
};

export type DashboardCreateParameters = {
  layout: LayoutParameters;
  name: Scalars['String']['input'];
};

export type DeviceType = {
  __typename?: 'DeviceType';
  category: DeviceTypeCategory;
  deviceCount?: Maybe<Scalars['Float']['output']>;
};

export enum DeviceTypeCategory {
  Ch4 = 'CH4',
  Co2 = 'CO2',
  DanfossEcl110 = 'DANFOSS_ECL110',
  Dust = 'DUST',
  Electricity = 'ELECTRICITY',
  Gas = 'GAS',
  Heat = 'HEAT',
  HumidityTemperature = 'HUMIDITY_TEMPERATURE',
  ImpulseElectricity = 'IMPULSE_ELECTRICITY',
  MbusHeat = 'MBUS_HEAT',
  ModbusElectricity = 'MODBUS_ELECTRICITY',
  Pressure = 'PRESSURE',
  RsHeat = 'RS_HEAT',
  Temperature = 'TEMPERATURE',
  Water = 'WATER'
}

export enum DeviceTypeName {
  Ch4 = 'CH4',
  Co2 = 'CO2',
  ColdWater = 'COLD_WATER',
  DanfossEcl110 = 'DANFOSS_ECL110',
  Dust = 'DUST',
  Electricity = 'ELECTRICITY',
  Gas = 'GAS',
  Heat = 'HEAT',
  HotWater = 'HOT_WATER',
  HumidityTemperature = 'HUMIDITY_TEMPERATURE',
  ImpulseElectricity = 'IMPULSE_ELECTRICITY',
  MbusHeat = 'MBUS_HEAT',
  ModbusElectricity = 'MODBUS_ELECTRICITY',
  Pressure = 'PRESSURE',
  RsHeat = 'RS_HEAT',
  Temperature = 'TEMPERATURE'
}

export type Layout = {
  __typename?: 'Layout';
  lg: Array<LayoutItem>;
  md: Array<LayoutItem>;
  sm: Array<LayoutItem>;
  xs: Array<LayoutItem>;
  xxs: Array<LayoutItem>;
};

export type LayoutItem = {
  __typename?: 'LayoutItem';
  h: Scalars['Float']['output'];
  i: Scalars['String']['output'];
  w: Scalars['Float']['output'];
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type LayoutItemParameters = {
  h: Scalars['Float']['input'];
  i: Scalars['String']['input'];
  w: Scalars['Float']['input'];
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type LayoutParameters = {
  lg: Array<LayoutItemParameters>;
  md: Array<LayoutItemParameters>;
  sm: Array<LayoutItemParameters>;
  xs: Array<LayoutItemParameters>;
  xxs: Array<LayoutItemParameters>;
};

export type Location = {
  __typename?: 'Location';
  _id: Scalars['ID']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  meteringNodes?: Maybe<Array<MeteringNode>>;
  organisationId: Scalars['ID']['output'];
  street: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LocationCreateParameters = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  externalId?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  meteringNodes?: InputMaybe<Array<MeteringNodeParameters>>;
  street?: InputMaybe<Scalars['String']['input']>;
};

export type LocationEditParameters = {
  _id: Scalars['String']['input'];
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  meteringNodes?: InputMaybe<Array<MeteringNodeParameters>>;
  street?: InputMaybe<Scalars['String']['input']>;
};

export type LocationListQuery = {
  __typename?: 'LocationListQuery';
  paging: Paging;
  result: Array<Location>;
};

export type Log = {
  __typename?: 'Log';
  _id: Scalars['ID']['output'];
  address: Scalars['String']['output'];
  date: Scalars['Float']['output'];
  decdevid?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  kind: Scalars['String']['output'];
  lhs?: Maybe<Scalars['JSON']['output']>;
  locationId: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organisationId: Scalars['ID']['output'];
  rhs?: Maybe<Scalars['JSON']['output']>;
  serial?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type LogApiDataAccess = {
  __typename?: 'LogApiDataAccess';
  _id: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  data: Scalars['JSON']['output'];
  url: Scalars['String']['output'];
};

export type LogApiDataAccessListQuery = {
  __typename?: 'LogApiDataAccessListQuery';
  paging: Paging;
  result: Array<LogApiDataAccess>;
};

export type LogListQuery = {
  __typename?: 'LogListQuery';
  paging: Paging;
  result: Array<Log>;
};

export type Measurement = {
  __typename?: 'Measurement';
  Act_power_L1?: Maybe<Scalars['Float']['output']>;
  Act_power_L2?: Maybe<Scalars['Float']['output']>;
  Act_power_L3?: Maybe<Scalars['Float']['output']>;
  Act_power_total?: Maybe<Scalars['Float']['output']>;
  Current_L1?: Maybe<Scalars['Float']['output']>;
  Current_L2?: Maybe<Scalars['Float']['output']>;
  Current_L3?: Maybe<Scalars['Float']['output']>;
  Current_Lavg?: Maybe<Scalars['Float']['output']>;
  Current_Ln?: Maybe<Scalars['Float']['output']>;
  Irms1?: Maybe<Scalars['Float']['output']>;
  Irms2?: Maybe<Scalars['Float']['output']>;
  Irms3?: Maybe<Scalars['Float']['output']>;
  M3?: Maybe<Scalars['Float']['output']>;
  PF1?: Maybe<Scalars['Float']['output']>;
  PF2?: Maybe<Scalars['Float']['output']>;
  PF3?: Maybe<Scalars['Float']['output']>;
  Realpower1?: Maybe<Scalars['Float']['output']>;
  Realpower2?: Maybe<Scalars['Float']['output']>;
  Realpower3?: Maybe<Scalars['Float']['output']>;
  Vrms1?: Maybe<Scalars['Float']['output']>;
  Vrms2?: Maybe<Scalars['Float']['output']>;
  Vrms3?: Maybe<Scalars['Float']['output']>;
  Wh?: Maybe<Scalars['Float']['output']>;
  bar?: Maybe<Scalars['Float']['output']>;
  ch4?: Maybe<Scalars['Float']['output']>;
  co2?: Maybe<Scalars['Float']['output']>;
  counter?: Maybe<Scalars['Float']['output']>;
  desired_mode?: Maybe<Scalars['Float']['output']>;
  desired_room_temp?: Maybe<Scalars['Float']['output']>;
  displace?: Maybe<Scalars['Float']['output']>;
  eq?: Maybe<Scalars['Float']['output']>;
  ev?: Maybe<Scalars['Float']['output']>;
  flow_temp_limit_max?: Maybe<Scalars['Float']['output']>;
  flow_temp_limit_min?: Maybe<Scalars['Float']['output']>;
  ht?: Maybe<Scalars['Float']['output']>;
  humidity?: Maybe<Scalars['Float']['output']>;
  m1_excercise?: Maybe<Scalars['Float']['output']>;
  manual_temp?: Maybe<Scalars['Float']['output']>;
  p1_excercise?: Maybe<Scalars['Float']['output']>;
  ppm10?: Maybe<Scalars['Float']['output']>;
  ppm25?: Maybe<Scalars['Float']['output']>;
  priority?: Maybe<Scalars['Float']['output']>;
  pump_status?: Maybe<Scalars['Float']['output']>;
  return_temp_limit?: Maybe<Scalars['Float']['output']>;
  rssi?: Maybe<Scalars['Float']['output']>;
  slope?: Maybe<Scalars['Float']['output']>;
  standby_temp?: Maybe<Scalars['Float']['output']>;
  tdelta?: Maybe<Scalars['Float']['output']>;
  temperature?: Maybe<Scalars['Float']['output']>;
  tflow?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
  treturn?: Maybe<Scalars['Float']['output']>;
  valve_open?: Maybe<Scalars['Float']['output']>;
  valve_shut?: Maybe<Scalars['Float']['output']>;
};

export type MeasurementAggregatedValue = {
  __typename?: 'MeasurementAggregatedValue';
  difference?: Maybe<Scalars['Float']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  mean?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

export type MeasurementAggregatedValues = {
  __typename?: 'MeasurementAggregatedValues';
  Irms1?: Maybe<MeasurementAggregatedValue>;
  Irms2?: Maybe<MeasurementAggregatedValue>;
  Irms3?: Maybe<MeasurementAggregatedValue>;
  M3?: Maybe<MeasurementAggregatedValue>;
  Realpower1?: Maybe<MeasurementAggregatedValue>;
  Realpower2?: Maybe<MeasurementAggregatedValue>;
  Realpower3?: Maybe<MeasurementAggregatedValue>;
  Vrms1?: Maybe<MeasurementAggregatedValue>;
  Vrms2?: Maybe<MeasurementAggregatedValue>;
  Vrms3?: Maybe<MeasurementAggregatedValue>;
  Wh?: Maybe<MeasurementAggregatedValue>;
  counter?: Maybe<MeasurementAggregatedValue>;
};

export enum MeasurementName {
  ActPowerL1 = 'Act_power_L1',
  ActPowerL2 = 'Act_power_L2',
  ActPowerL3 = 'Act_power_L3',
  ActPowerTotal = 'Act_power_total',
  CurrentL1 = 'Current_L1',
  CurrentL2 = 'Current_L2',
  CurrentL3 = 'Current_L3',
  CurrentLavg = 'Current_Lavg',
  CurrentLn = 'Current_Ln',
  Irms1 = 'Irms1',
  Irms2 = 'Irms2',
  Irms3 = 'Irms3',
  M3 = 'M3',
  Pf1 = 'PF1',
  Pf2 = 'PF2',
  Pf3 = 'PF3',
  Realpower1 = 'Realpower1',
  Realpower2 = 'Realpower2',
  Realpower3 = 'Realpower3',
  Vrms1 = 'Vrms1',
  Vrms2 = 'Vrms2',
  Vrms3 = 'Vrms3',
  Wh = 'Wh',
  Bar = 'bar',
  Ch4 = 'ch4',
  Co2 = 'co2',
  Counter = 'counter',
  DesiredMode = 'desired_mode',
  DesiredRoomTemp = 'desired_room_temp',
  Displace = 'displace',
  Eq = 'eq',
  Ev = 'ev',
  FlowTempLimitMax = 'flow_temp_limit_max',
  FlowTempLimitMin = 'flow_temp_limit_min',
  Ht = 'ht',
  Humidity = 'humidity',
  M1Excercise = 'm1_excercise',
  ManualTemp = 'manual_temp',
  P1Excercise = 'p1_excercise',
  Ppm10 = 'ppm10',
  Ppm25 = 'ppm25',
  Priority = 'priority',
  PumpStatus = 'pump_status',
  ReturnTempLimit = 'return_temp_limit',
  Slope = 'slope',
  StandbyTemp = 'standby_temp',
  Tdelta = 'tdelta',
  Temperature = 'temperature',
  Tflow = 'tflow',
  Treturn = 'treturn',
  ValveOpen = 'valve_open',
  ValveShut = 'valve_shut'
}

export type MeteringNode = {
  __typename?: 'MeteringNode';
  _id: Scalars['ID']['output'];
  category: DeviceTypeCategory;
  currentDecdevid?: Maybe<Scalars['String']['output']>;
  currentDevice?: Maybe<MeteringNodeDevice>;
  devices?: Maybe<Array<MeteringNodeDevice>>;
  leaking?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<Location>;
  measurementAggregatedValues?: Maybe<MeasurementAggregatedValues>;
  measurementLast?: Maybe<Measurement>;
  measurementList?: Maybe<Array<Measurement>>;
  measurementThroughputList?: Maybe<Array<Measurement>>;
  name: Scalars['String']['output'];
  restart?: Maybe<Scalars['Boolean']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  type: DeviceTypeName;
  unit?: Maybe<Unit>;
};


export type MeteringNodeMeasurementAggregatedValuesArgs = {
  from: Scalars['Float']['input'];
  to: Scalars['Float']['input'];
};


export type MeteringNodeMeasurementListArgs = {
  from: Scalars['Float']['input'];
  interval: Scalars['Float']['input'];
  to: Scalars['Float']['input'];
};


export type MeteringNodeMeasurementThroughputListArgs = {
  from: Scalars['Float']['input'];
  interval: Scalars['Float']['input'];
  to: Scalars['Float']['input'];
};

export type MeteringNodeDevice = {
  __typename?: 'MeteringNodeDevice';
  _id: Scalars['ID']['output'];
  currentModem?: Maybe<MeteringNodeModem>;
  dismantlingDate?: Maybe<Scalars['Float']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  initialMeasurement?: Maybe<Scalars['Float']['output']>;
  installationDate?: Maybe<Scalars['Float']['output']>;
  modems?: Maybe<Array<MeteringNodeModem>>;
  pulseValue?: Maybe<Scalars['Float']['output']>;
  serial: Scalars['String']['output'];
  verificationDate?: Maybe<Scalars['Float']['output']>;
};

export type MeteringNodeDeviceParameters = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  dismantlingDate?: InputMaybe<Scalars['Float']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  initialMeasurement?: InputMaybe<Scalars['Float']['input']>;
  installationDate?: InputMaybe<Scalars['Float']['input']>;
  modems?: InputMaybe<Array<MeteringNodeModemParameters>>;
  pulseValue?: InputMaybe<Scalars['Float']['input']>;
  serial: Scalars['String']['input'];
  verificationDate?: InputMaybe<Scalars['Float']['input']>;
};

export type MeteringNodeListQuery = {
  __typename?: 'MeteringNodeListQuery';
  paging: Paging;
  result: Array<MeteringNode>;
};

export type MeteringNodeListSortParameters = {
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  serial?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  verificationDate?: InputMaybe<Scalars['String']['input']>;
};

export type MeteringNodeModem = {
  __typename?: 'MeteringNodeModem';
  _id: Scalars['ID']['output'];
  currentPulses?: Maybe<CurrentPulses>;
  decdevid: Scalars['String']['output'];
  dismantlingDate?: Maybe<Scalars['Float']['output']>;
  initialPulses: Scalars['Float']['output'];
  installationDate?: Maybe<Scalars['Float']['output']>;
  port: Scalars['Float']['output'];
};

export type MeteringNodeModemParameters = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  decdevid: Scalars['String']['input'];
  dismantlingDate?: InputMaybe<Scalars['Float']['input']>;
  initialPulses?: InputMaybe<Scalars['Float']['input']>;
  installationDate?: InputMaybe<Scalars['Float']['input']>;
  port: Scalars['Float']['input'];
};

export type MeteringNodeParameters = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  devices?: InputMaybe<Array<MeteringNodeDeviceParameters>>;
  name: Scalars['String']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type Modbus = {
  __typename?: 'Modbus';
  _id: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  decdevid: Scalars['Float']['output'];
  desired_mode: Scalars['Float']['output'];
  devid: Scalars['String']['output'];
  displace: Scalars['Float']['output'];
  flow_temp_limit_max: Scalars['Float']['output'];
  flow_temp_limit_min: Scalars['Float']['output'];
  manual_temp: Scalars['Float']['output'];
  organisationId: Scalars['ID']['output'];
  priority: Scalars['Float']['output'];
  return_temp_limit: Scalars['Float']['output'];
  slope: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ModbusUpdateParameters = {
  _id?: InputMaybe<Scalars['String']['input']>;
  decdevid?: InputMaybe<Scalars['Float']['input']>;
  desired_mode?: InputMaybe<Scalars['Float']['input']>;
  devid?: InputMaybe<Scalars['String']['input']>;
  displace?: InputMaybe<Scalars['Float']['input']>;
  flow_temp_limit_max?: InputMaybe<Scalars['Float']['input']>;
  flow_temp_limit_min?: InputMaybe<Scalars['Float']['input']>;
  manual_temp?: InputMaybe<Scalars['Float']['input']>;
  priority?: InputMaybe<Scalars['Float']['input']>;
  return_temp_limit?: InputMaybe<Scalars['Float']['input']>;
  slope?: InputMaybe<Scalars['Float']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  alertRuleCreate: AlertRule;
  alertRuleDelete: Scalars['Boolean']['output'];
  alertRuleUpdate: AlertRule;
  baseStationCreate: BaseStation;
  baseStationCreateMany?: Maybe<Array<BaseStation>>;
  baseStationDelete: Scalars['Boolean']['output'];
  baseStationUpdate: BaseStation;
  dashboardCreate: Dashboard;
  dashboardUpdate?: Maybe<Dashboard>;
  impersonate: User;
  locationCreate: Location;
  locationDelete: Scalars['Boolean']['output'];
  locationImportFromHorizon: Scalars['Boolean']['output'];
  locationSendMeasurementsToHorizon: Scalars['Boolean']['output'];
  locationUpdate: Location;
  login: User;
  logout: Scalars['Boolean']['output'];
  modbusUpdate: Modbus;
  organisationCreate: Organisation;
  organisationDelete: Scalars['Boolean']['output'];
  organisationUpdate: Organisation;
  userCreate: User;
  userDelete: Scalars['Boolean']['output'];
  userUpdate: User;
};


export type MutationAlertRuleCreateArgs = {
  parameters: AlertRuleCreateParameters;
};


export type MutationAlertRuleDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationAlertRuleUpdateArgs = {
  parameters: AlertRuleUpdateParameters;
};


export type MutationBaseStationCreateArgs = {
  parameters: BaseStationCreateParameters;
};


export type MutationBaseStationCreateManyArgs = {
  items: Array<BaseStationCreateParameters>;
};


export type MutationBaseStationDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationBaseStationUpdateArgs = {
  parameters: BaseStationUpdateParameters;
};


export type MutationDashboardCreateArgs = {
  parameters: DashboardCreateParameters;
};


export type MutationDashboardUpdateArgs = {
  parameters: DashboardCreateParameters;
};


export type MutationImpersonateArgs = {
  id: Scalars['String']['input'];
};


export type MutationLocationCreateArgs = {
  parameters: LocationCreateParameters;
};


export type MutationLocationDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationLocationImportFromHorizonArgs = {
  controlCounters?: InputMaybe<Scalars['Boolean']['input']>;
  counters?: InputMaybe<Scalars['Boolean']['input']>;
  locations?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationLocationUpdateArgs = {
  parameters: LocationEditParameters;
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationModbusUpdateArgs = {
  parameters: ModbusUpdateParameters;
};


export type MutationOrganisationCreateArgs = {
  devToken: Scalars['String']['input'];
  name: Scalars['String']['input'];
  table: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationOrganisationDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationOrganisationUpdateArgs = {
  devToken: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  table: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationUserCreateArgs = {
  parameters: CreateUserParameters;
};


export type MutationUserDeleteArgs = {
  id: Scalars['String']['input'];
  organisationId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUserUpdateArgs = {
  parameters: UpdateUserParameters;
};

export type Organisation = {
  __typename?: 'Organisation';
  _id: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  devToken: Scalars['String']['output'];
  name: Scalars['String']['output'];
  table: Scalars['String']['output'];
  token: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type OrganisationQuery = {
  __typename?: 'OrganisationQuery';
  paging: Paging;
  result: Array<Organisation>;
};

export type Paging = {
  __typename?: 'Paging';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  alertRuleGet?: Maybe<AlertRule>;
  alertRuleList: AlertRuleListQuery;
  apiTokenGet: ApiToken;
  baseStationGet?: Maybe<BaseStation>;
  baseStationList: BaseStationListQuery;
  currentPulsesGet?: Maybe<CurrentPulses>;
  dashboardGet?: Maybe<Dashboard>;
  deviceTypeList: Array<DeviceType>;
  locationGet?: Maybe<Location>;
  locationList: LocationListQuery;
  logApiDataAccessList: LogApiDataAccessListQuery;
  logList: LogListQuery;
  meteringNodeGet?: Maybe<MeteringNode>;
  meteringNodeList: MeteringNodeListQuery;
  modbusGet?: Maybe<Modbus>;
  organisationGet?: Maybe<Organisation>;
  organisationList: OrganisationQuery;
  tagList: Array<Scalars['String']['output']>;
  userCurrent?: Maybe<User>;
  userGet: User;
  userList: UserListQuery;
  userListAdmin: UserListQuery;
};


export type QueryAlertRuleGetArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAlertRuleListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryBaseStationGetArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBaseStationListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<BaseStationListSortParameters>;
};


export type QueryCurrentPulsesGetArgs = {
  decdevid: Scalars['String']['input'];
  port: Scalars['Float']['input'];
};


export type QueryDashboardGetArgs = {
  name: Scalars['String']['input'];
};


export type QueryDeviceTypeListArgs = {
  filterByOrganisation?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryLocationGetArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLocationListArgs = {
  date?: InputMaybe<Scalars['Float']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLogApiDataAccessListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLogListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMeteringNodeGetArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMeteringNodeListArgs = {
  category?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['Float']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<MeteringNodeListSortParameters>;
  tag?: InputMaybe<Scalars['String']['input']>;
};


export type QueryModbusGetArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOrganisationGetArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOrganisationListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserGetArgs = {
  id: Scalars['String']['input'];
  organisationId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserListAdminArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  organisationId?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type Rule = {
  __typename?: 'Rule';
  logicalOperator: AlertRuleLogicalOperator;
  measurementName: MeasurementName;
  range: AlertRuleRange;
  reducer: AlertRuleReducer;
  timeSpan: Scalars['Float']['output'];
  value: Array<Scalars['Float']['output']>;
};

export type Unit = {
  __typename?: 'Unit';
  Irms1?: Maybe<Scalars['String']['output']>;
  Irms2?: Maybe<Scalars['String']['output']>;
  Irms3?: Maybe<Scalars['String']['output']>;
  M3?: Maybe<Scalars['String']['output']>;
  PF1?: Maybe<Scalars['String']['output']>;
  PF2?: Maybe<Scalars['String']['output']>;
  PF3?: Maybe<Scalars['String']['output']>;
  Realpower1?: Maybe<Scalars['String']['output']>;
  Realpower2?: Maybe<Scalars['String']['output']>;
  Realpower3?: Maybe<Scalars['String']['output']>;
  Vrms1?: Maybe<Scalars['String']['output']>;
  Vrms2?: Maybe<Scalars['String']['output']>;
  Vrms3?: Maybe<Scalars['String']['output']>;
  Wh?: Maybe<Scalars['String']['output']>;
  bar?: Maybe<Scalars['String']['output']>;
  ch4?: Maybe<Scalars['String']['output']>;
  co2?: Maybe<Scalars['String']['output']>;
  counter?: Maybe<Scalars['String']['output']>;
  desired_mode?: Maybe<Scalars['String']['output']>;
  desired_room_temp?: Maybe<Scalars['String']['output']>;
  displace?: Maybe<Scalars['String']['output']>;
  eq?: Maybe<Scalars['String']['output']>;
  ev?: Maybe<Scalars['String']['output']>;
  flow_temp_limit_max?: Maybe<Scalars['String']['output']>;
  flow_temp_limit_min?: Maybe<Scalars['String']['output']>;
  ht?: Maybe<Scalars['String']['output']>;
  humidity?: Maybe<Scalars['String']['output']>;
  m1_excercise?: Maybe<Scalars['String']['output']>;
  manual_temp?: Maybe<Scalars['String']['output']>;
  p1_excercise?: Maybe<Scalars['String']['output']>;
  ppm10?: Maybe<Scalars['String']['output']>;
  ppm25?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['String']['output']>;
  pump_status?: Maybe<Scalars['String']['output']>;
  return_temp_limit?: Maybe<Scalars['String']['output']>;
  slope?: Maybe<Scalars['String']['output']>;
  standby_temp?: Maybe<Scalars['String']['output']>;
  tdelta?: Maybe<Scalars['String']['output']>;
  temperature?: Maybe<Scalars['String']['output']>;
  tflow?: Maybe<Scalars['String']['output']>;
  treturn?: Maybe<Scalars['String']['output']>;
  valve_open?: Maybe<Scalars['String']['output']>;
  valve_shut?: Maybe<Scalars['String']['output']>;
};

export type UpdateUserParameters = {
  _id: Scalars['String']['input'];
  admin?: InputMaybe<Scalars['Boolean']['input']>;
  allowedLocations?: InputMaybe<Array<Scalars['String']['input']>>;
  baseStationsEdit?: InputMaybe<Scalars['Boolean']['input']>;
  baseStationsView?: InputMaybe<Scalars['Boolean']['input']>;
  devicesEdit?: InputMaybe<Scalars['Boolean']['input']>;
  devicesView?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  horizonClientKey?: InputMaybe<Scalars['String']['input']>;
  horizonDataSourceId?: InputMaybe<Scalars['String']['input']>;
  horizonMeasurementReportingType?: InputMaybe<Scalars['String']['input']>;
  horizonPassword?: InputMaybe<Scalars['String']['input']>;
  horizonUsername?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  locationsEdit?: InputMaybe<Scalars['Boolean']['input']>;
  locationsView?: InputMaybe<Scalars['Boolean']['input']>;
  logView?: InputMaybe<Scalars['Boolean']['input']>;
  organisationId?: InputMaybe<Scalars['String']['input']>;
  organisationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ID']['output'];
  admin: Scalars['Boolean']['output'];
  allowedDevices?: Maybe<Array<Scalars['ID']['output']>>;
  allowedLocations?: Maybe<Array<Scalars['ID']['output']>>;
  baseStationsEdit: Scalars['Boolean']['output'];
  baseStationsView: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  devicesEdit: Scalars['Boolean']['output'];
  devicesView: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  horizonClientKey: Scalars['String']['output'];
  horizonDataSourceId: Scalars['String']['output'];
  horizonMeasurementReportingType?: Maybe<Scalars['String']['output']>;
  horizonUsername: Scalars['String']['output'];
  language?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  locationsEdit: Scalars['Boolean']['output'];
  locationsView: Scalars['Boolean']['output'];
  logView: Scalars['Boolean']['output'];
  organisation: Organisation;
  organisationId: Scalars['ID']['output'];
  organisationIds: Array<Scalars['ID']['output']>;
  organisations: Array<Organisation>;
  superAdmin: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type UserListQuery = {
  __typename?: 'UserListQuery';
  paging: Paging;
  result: Array<User>;
};

export type SwitchLanguageMutationVariables = Exact<{
  id: Scalars['String']['input'];
  language: Scalars['String']['input'];
}>;


export type SwitchLanguageMutation = { __typename?: 'Mutation', userUpdate: { __typename?: 'User', _id: string, language?: string | null } };

export type SwitchOrganisationMutationVariables = Exact<{
  id: Scalars['String']['input'];
  organisationId: Scalars['String']['input'];
}>;


export type SwitchOrganisationMutation = { __typename?: 'Mutation', userUpdate: { __typename?: 'User', _id: string, organisationId: string } };

export type UserMenuQueryVariables = Exact<{ [key: string]: never; }>;


export type UserMenuQuery = { __typename?: 'Query', userCurrent?: { __typename?: 'User', _id: string, firstName: string, lastName: string, email: string, language?: string | null, organisationIds: Array<string>, organisation: { __typename?: 'Organisation', _id: string, name: string }, organisations: Array<{ __typename?: 'Organisation', _id: string, name: string }> } | null };

export type InputCounterQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;


export type InputCounterQuery = { __typename?: 'Query', meteringNodeList: { __typename?: 'MeteringNodeListQuery', result: Array<{ __typename?: 'MeteringNode', _id: string, name: string, currentDevice?: { __typename?: 'MeteringNodeDevice', _id: string, serial: string, currentModem?: { __typename?: 'MeteringNodeModem', _id: string } | null } | null, location?: { __typename?: 'Location', city: string, street: string } | null }>, paging: { __typename?: 'Paging', offset: number, limit: number, total: number } } };

export type InputLocationQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;


export type InputLocationQuery = { __typename?: 'Query', locationList: { __typename?: 'LocationListQuery', result: Array<{ __typename?: 'Location', _id: string, country: string, city: string, street: string }>, paging: { __typename?: 'Paging', offset: number, limit: number, total: number } } };

export type DeviceTypeListQueryVariables = Exact<{ [key: string]: never; }>;


export type DeviceTypeListQuery = { __typename?: 'Query', deviceTypeList: Array<{ __typename?: 'DeviceType', category: DeviceTypeCategory, deviceCount?: number | null }> };

export type ExistingUserListQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  search: Scalars['String']['input'];
}>;


export type ExistingUserListQuery = { __typename?: 'Query', userListAdmin: { __typename?: 'UserListQuery', result: Array<{ __typename?: 'User', _id: string, firstName: string, lastName: string, email: string, organisationId: string, organisationIds: Array<string>, admin: boolean, superAdmin: boolean }>, paging: { __typename?: 'Paging', offset: number, limit: number, total: number } } };

export type AddUserToOrganisationMutationVariables = Exact<{
  id: Scalars['String']['input'];
  organisationIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type AddUserToOrganisationMutation = { __typename?: 'Mutation', userUpdate: { __typename?: 'User', _id: string, organisationIds: Array<string> } };

export type UserCreateMutationVariables = Exact<{
  organisationId: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  language: Scalars['String']['input'];
  admin: Scalars['Boolean']['input'];
  devicesView: Scalars['Boolean']['input'];
  devicesEdit: Scalars['Boolean']['input'];
  baseStationsView: Scalars['Boolean']['input'];
  baseStationsEdit: Scalars['Boolean']['input'];
  locationsView: Scalars['Boolean']['input'];
  locationsEdit: Scalars['Boolean']['input'];
  logView: Scalars['Boolean']['input'];
}>;


export type UserCreateMutation = { __typename?: 'Mutation', userCreate: { __typename?: 'User', _id: string, firstName: string, lastName: string, email: string, admin: boolean, devicesView: boolean, devicesEdit: boolean, baseStationsView: boolean, baseStationsEdit: boolean, locationsView: boolean, locationsEdit: boolean, logView: boolean } };

export type DeleteUserAdminMutationVariables = Exact<{
  id: Scalars['String']['input'];
  organisationId: Scalars['String']['input'];
}>;


export type DeleteUserAdminMutation = { __typename?: 'Mutation', userDelete: boolean };

export type UserGetQueryVariables = Exact<{
  id: Scalars['String']['input'];
  organisationId?: InputMaybe<Scalars['String']['input']>;
}>;


export type UserGetQuery = { __typename?: 'Query', userGet: { __typename?: 'User', _id: string, organisationId: string, organisationIds: Array<string>, firstName: string, lastName: string, email: string, allowedLocations?: Array<string> | null, admin: boolean, superAdmin: boolean, devicesView: boolean, devicesEdit: boolean, baseStationsView: boolean, baseStationsEdit: boolean, locationsView: boolean, locationsEdit: boolean, logView: boolean } };

export type UserUpdateMutationVariables = Exact<{
  id: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  admin: Scalars['Boolean']['input'];
  devicesView: Scalars['Boolean']['input'];
  devicesEdit: Scalars['Boolean']['input'];
  baseStationsView: Scalars['Boolean']['input'];
  baseStationsEdit: Scalars['Boolean']['input'];
  locationsView: Scalars['Boolean']['input'];
  locationsEdit: Scalars['Boolean']['input'];
  logView: Scalars['Boolean']['input'];
}>;


export type UserUpdateMutation = { __typename?: 'Mutation', userUpdate: { __typename?: 'User', _id: string, organisationId: string, firstName: string, lastName: string, email: string, admin: boolean, devicesView: boolean, devicesEdit: boolean, baseStationsView: boolean, baseStationsEdit: boolean, locationsView: boolean, locationsEdit: boolean, logView: boolean } };

export type ImpersonateMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ImpersonateMutation = { __typename?: 'Mutation', impersonate: { __typename?: 'User', _id: string, firstName: string, lastName: string, email: string } };

export type AdminUserListQueryVariables = Exact<{
  organisationId: Scalars['String']['input'];
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;


export type AdminUserListQuery = { __typename?: 'Query', userListAdmin: { __typename?: 'UserListQuery', result: Array<{ __typename?: 'User', _id: string, firstName: string, lastName: string, email: string, admin: boolean, superAdmin: boolean, organisationIds: Array<string> }>, paging: { __typename?: 'Paging', offset: number, limit: number, total: number } } };

export type OrganisationCreateMutationVariables = Exact<{
  table: Scalars['String']['input'];
  name: Scalars['String']['input'];
  token: Scalars['String']['input'];
  devToken: Scalars['String']['input'];
}>;


export type OrganisationCreateMutation = { __typename?: 'Mutation', organisationCreate: { __typename?: 'Organisation', _id: string, name: string, table: string, token: string, devToken: string } };

export type OrganisationUpdateMutationVariables = Exact<{
  id: Scalars['String']['input'];
  table: Scalars['String']['input'];
  name: Scalars['String']['input'];
  token: Scalars['String']['input'];
  devToken: Scalars['String']['input'];
}>;


export type OrganisationUpdateMutation = { __typename?: 'Mutation', organisationUpdate: { __typename?: 'Organisation', _id: string, name: string, table: string, token: string, devToken: string } };

export type OrganisationDeleteMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type OrganisationDeleteMutation = { __typename?: 'Mutation', organisationDelete: boolean };

export type OrganisationCountQueryVariables = Exact<{
  search: Scalars['String']['input'];
}>;


export type OrganisationCountQuery = { __typename?: 'Query', organisationList: { __typename?: 'OrganisationQuery', paging: { __typename?: 'Paging', total: number } } };

export type OrganisationGetQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type OrganisationGetQuery = { __typename?: 'Query', organisationGet?: { __typename?: 'Organisation', _id: string, name: string, table: string, token: string, devToken: string } | null };

export type OrganisationListQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  search: Scalars['String']['input'];
}>;


export type OrganisationListQuery = { __typename?: 'Query', organisationList: { __typename?: 'OrganisationQuery', result: Array<{ __typename?: 'Organisation', _id: string, name: string, table: string, createdAt: any }>, paging: { __typename?: 'Paging', offset: number, limit: number, total: number } } };

export type AlertRulesQueryVariables = Exact<{ [key: string]: never; }>;


export type AlertRulesQuery = { __typename?: 'Query', alertRuleList: { __typename?: 'AlertRuleListQuery', result: Array<{ __typename?: 'AlertRule', _id: string, organisationId: string, deviceTypeCategory: DeviceTypeCategory, deviceIds: Array<string>, rules: Array<{ __typename?: 'Rule', logicalOperator: AlertRuleLogicalOperator, reducer: AlertRuleReducer, range: AlertRuleRange, measurementName: MeasurementName, timeSpan: number, value: Array<number> }> }>, paging: { __typename?: 'Paging', offset: number, limit: number, total: number } } };

export type CreateAlertRuleMutationVariables = Exact<{
  deviceTypeCategory: DeviceTypeCategory;
  deviceIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  rules: Array<AlertRuleParameters> | AlertRuleParameters;
}>;


export type CreateAlertRuleMutation = { __typename?: 'Mutation', alertRuleCreate: { __typename?: 'AlertRule', _id: string, deviceIds: Array<string>, rules: Array<{ __typename?: 'Rule', logicalOperator: AlertRuleLogicalOperator, reducer: AlertRuleReducer, range: AlertRuleRange, measurementName: MeasurementName, timeSpan: number, value: Array<number> }> } };

export type DeleteAlertRuleMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteAlertRuleMutation = { __typename?: 'Mutation', alertRuleDelete: boolean };

export type UpdateAlertRuleMutationVariables = Exact<{
  id: Scalars['String']['input'];
  deviceTypeCategory: DeviceTypeCategory;
  deviceIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  rules: Array<AlertRuleParameters> | AlertRuleParameters;
}>;


export type UpdateAlertRuleMutation = { __typename?: 'Mutation', alertRuleUpdate: { __typename?: 'AlertRule', _id: string, deviceIds: Array<string>, rules: Array<{ __typename?: 'Rule', logicalOperator: AlertRuleLogicalOperator, reducer: AlertRuleReducer, range: AlertRuleRange, measurementName: MeasurementName, timeSpan: number, value: Array<number> }> } };

export type CounterInputQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;


export type CounterInputQuery = { __typename?: 'Query', meteringNodeList: { __typename?: 'MeteringNodeListQuery', result: Array<{ __typename?: 'MeteringNode', _id: string, name: string, category: DeviceTypeCategory, currentDevice?: { __typename?: 'MeteringNodeDevice', _id: string, serial: string } | null, location?: { __typename?: 'Location', _id: string, city: string, street: string } | null }>, paging: { __typename?: 'Paging', offset: number, limit: number, total: number } } };

export type AddBaseStationMutationVariables = Exact<{
  items: Array<BaseStationCreateParameters> | BaseStationCreateParameters;
}>;


export type AddBaseStationMutation = { __typename?: 'Mutation', baseStationCreateMany?: Array<{ __typename?: 'BaseStation', _id: string, decdevid: string, locationId?: string | null }> | null };

export type BaseStationsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  search: Scalars['String']['input'];
  sort: BaseStationListSortParameters;
}>;


export type BaseStationsQuery = { __typename?: 'Query', baseStationList: { __typename?: 'BaseStationListQuery', result: Array<{ __typename?: 'BaseStation', _id: string, decdevid: string, lastSignalTime?: number | null, createdAt?: any | null, location?: { __typename?: 'Location', _id: string, city: string, street: string } | null }>, paging: { __typename?: 'Paging', offset: number, limit: number, total: number } } };

export type DeleteBaseStationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteBaseStationMutation = { __typename?: 'Mutation', baseStationDelete: boolean };

export type EditBaseStationMutationVariables = Exact<{
  id: Scalars['String']['input'];
  decdevid: Scalars['String']['input'];
  locationId?: InputMaybe<Scalars['String']['input']>;
}>;


export type EditBaseStationMutation = { __typename?: 'Mutation', baseStationUpdate: { __typename?: 'BaseStation', _id: string, decdevid: string, locationId?: string | null } };

export type GetBaseStationQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetBaseStationQuery = { __typename?: 'Query', baseStationGet?: { __typename?: 'BaseStation', _id: string, decdevid: string, locationId?: string | null } | null };

export type DeviceNavQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;


export type DeviceNavQuery = { __typename?: 'Query', meteringNodeList: { __typename?: 'MeteringNodeListQuery', result: Array<{ __typename?: 'MeteringNode', _id: string, name: string, category: DeviceTypeCategory, leaking?: boolean | null, restart?: boolean | null, location?: { __typename?: 'Location', _id: string, city: string, street: string } | null }>, paging: { __typename?: 'Paging', offset: number, limit: number, total: number } } };

export type Ch4QueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  from: Scalars['Float']['input'];
  to: Scalars['Float']['input'];
  interval: Scalars['Float']['input'];
}>;


export type Ch4Query = { __typename?: 'Query', meteringNodeGet?: { __typename?: 'MeteringNode', _id: string, devices?: Array<{ __typename?: 'MeteringNodeDevice', installationDate?: number | null }> | null, measurementList?: Array<{ __typename?: 'Measurement', time?: number | null, ch4?: number | null }> | null, unit?: { __typename?: 'Unit', ch4?: string | null } | null } | null };

export type Co2QueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  from: Scalars['Float']['input'];
  to: Scalars['Float']['input'];
  interval: Scalars['Float']['input'];
}>;


export type Co2Query = { __typename?: 'Query', meteringNodeGet?: { __typename?: 'MeteringNode', _id: string, devices?: Array<{ __typename?: 'MeteringNodeDevice', installationDate?: number | null }> | null, measurementList?: Array<{ __typename?: 'Measurement', time?: number | null, co2?: number | null }> | null, unit?: { __typename?: 'Unit', co2?: string | null } | null } | null };

export type DustQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  from: Scalars['Float']['input'];
  to: Scalars['Float']['input'];
  interval: Scalars['Float']['input'];
}>;


export type DustQuery = { __typename?: 'Query', meteringNodeGet?: { __typename?: 'MeteringNode', _id: string, devices?: Array<{ __typename?: 'MeteringNodeDevice', installationDate?: number | null }> | null, measurementList?: Array<{ __typename?: 'Measurement', time?: number | null, ppm10?: number | null, ppm25?: number | null }> | null, unit?: { __typename?: 'Unit', ppm10?: string | null, ppm25?: string | null } | null } | null };

export type EditMetricsLayoutMutationVariables = Exact<{
  name: Scalars['String']['input'];
  layout: LayoutParameters;
}>;


export type EditMetricsLayoutMutation = { __typename?: 'Mutation', dashboardUpdate?: { __typename?: 'Dashboard', _id: string } | null };

export type ElectricityQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  from: Scalars['Float']['input'];
  to: Scalars['Float']['input'];
  interval: Scalars['Float']['input'];
}>;


export type ElectricityQuery = { __typename?: 'Query', meteringNodeGet?: { __typename?: 'MeteringNode', _id: string, devices?: Array<{ __typename?: 'MeteringNodeDevice', installationDate?: number | null }> | null, measurementList?: Array<{ __typename?: 'Measurement', time?: number | null, Irms1?: number | null, Irms2?: number | null, Irms3?: number | null, PF1?: number | null, PF2?: number | null, PF3?: number | null, Realpower1?: number | null, Realpower2?: number | null, Realpower3?: number | null, Vrms1?: number | null, Vrms2?: number | null, Vrms3?: number | null }> | null } | null };

export type ElectricityAggregatedValuesQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  from: Scalars['Float']['input'];
  to: Scalars['Float']['input'];
}>;


export type ElectricityAggregatedValuesQuery = { __typename?: 'Query', meteringNodeGet?: { __typename?: 'MeteringNode', _id: string, measurementAggregatedValues?: { __typename?: 'MeasurementAggregatedValues', Vrms1?: { __typename?: 'MeasurementAggregatedValue', min?: number | null, max?: number | null } | null, Vrms2?: { __typename?: 'MeasurementAggregatedValue', min?: number | null, max?: number | null } | null, Vrms3?: { __typename?: 'MeasurementAggregatedValue', min?: number | null, max?: number | null } | null, Irms1?: { __typename?: 'MeasurementAggregatedValue', max?: number | null, mean?: number | null } | null, Irms2?: { __typename?: 'MeasurementAggregatedValue', max?: number | null, mean?: number | null } | null, Irms3?: { __typename?: 'MeasurementAggregatedValue', max?: number | null, mean?: number | null } | null, Realpower1?: { __typename?: 'MeasurementAggregatedValue', min?: number | null, max?: number | null, mean?: number | null } | null, Realpower2?: { __typename?: 'MeasurementAggregatedValue', min?: number | null, max?: number | null, mean?: number | null } | null, Realpower3?: { __typename?: 'MeasurementAggregatedValue', min?: number | null, max?: number | null, mean?: number | null } | null } | null } | null };

export type HeatQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  from: Scalars['Float']['input'];
  to: Scalars['Float']['input'];
  interval: Scalars['Float']['input'];
}>;


export type HeatQuery = { __typename?: 'Query', meteringNodeGet?: { __typename?: 'MeteringNode', _id: string, measurementThroughputList?: Array<{ __typename?: 'Measurement', time?: number | null, counter?: number | null }> | null, measurementList?: Array<{ __typename?: 'Measurement', time?: number | null, counter?: number | null }> | null, measurementLast?: { __typename?: 'Measurement', time?: number | null, counter?: number | null } | null, measurementAggregatedValues?: { __typename?: 'MeasurementAggregatedValues', counter?: { __typename?: 'MeasurementAggregatedValue', difference?: number | null } | null } | null, unit?: { __typename?: 'Unit', counter?: string | null } | null, devices?: Array<{ __typename?: 'MeteringNodeDevice', installationDate?: number | null }> | null } | null };

export type HeatCurrentThroughputQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  from: Scalars['Float']['input'];
  to: Scalars['Float']['input'];
  interval: Scalars['Float']['input'];
}>;


export type HeatCurrentThroughputQuery = { __typename?: 'Query', meteringNodeGet?: { __typename?: 'MeteringNode', _id: string, measurementThroughputList?: Array<{ __typename?: 'Measurement', time?: number | null, counter?: number | null }> | null, unit?: { __typename?: 'Unit', counter?: string | null } | null } | null };

export type ModbusGetQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ModbusGetQuery = { __typename?: 'Query', modbusGet?: { __typename?: 'Modbus', _id: string, devid: string, decdevid: number, slope: number, displace: number, flow_temp_limit_min: number, flow_temp_limit_max: number, return_temp_limit: number, priority: number, manual_temp: number, desired_mode: number } | null };

export type ModbusUpdateMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  devid?: InputMaybe<Scalars['String']['input']>;
  decdevid?: InputMaybe<Scalars['Float']['input']>;
  slope?: InputMaybe<Scalars['Float']['input']>;
  displace?: InputMaybe<Scalars['Float']['input']>;
  flow_temp_limit_min?: InputMaybe<Scalars['Float']['input']>;
  flow_temp_limit_max?: InputMaybe<Scalars['Float']['input']>;
  return_temp_limit?: InputMaybe<Scalars['Float']['input']>;
  priority?: InputMaybe<Scalars['Float']['input']>;
  manual_temp?: InputMaybe<Scalars['Float']['input']>;
  desired_mode?: InputMaybe<Scalars['Float']['input']>;
}>;


export type ModbusUpdateMutation = { __typename?: 'Mutation', modbusUpdate: { __typename?: 'Modbus', _id: string, slope: number, displace: number, flow_temp_limit_min: number, flow_temp_limit_max: number, return_temp_limit: number, priority: number, manual_temp: number, desired_mode: number } };

export type HeatingUnitControllerQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  from: Scalars['Float']['input'];
  to: Scalars['Float']['input'];
  interval: Scalars['Float']['input'];
}>;


export type HeatingUnitControllerQuery = { __typename?: 'Query', meteringNodeGet?: { __typename?: 'MeteringNode', _id: string, currentDevice?: { __typename?: 'MeteringNodeDevice', _id: string, currentModem?: { __typename?: 'MeteringNodeModem', _id: string, decdevid: string } | null } | null, measurementList?: Array<{ __typename?: 'Measurement', time?: number | null, desired_mode?: number | null, desired_room_temp?: number | null, flow_temp_limit_max?: number | null, flow_temp_limit_min?: number | null, m1_excercise?: number | null, manual_temp?: number | null, p1_excercise?: number | null, pump_status?: number | null, return_temp_limit?: number | null, standby_temp?: number | null, valve_open?: number | null, valve_shut?: number | null, slope?: number | null, displace?: number | null, priority?: number | null }> | null, unit?: { __typename?: 'Unit', desired_mode?: string | null, desired_room_temp?: string | null, flow_temp_limit_max?: string | null, flow_temp_limit_min?: string | null, m1_excercise?: string | null, manual_temp?: string | null, p1_excercise?: string | null, pump_status?: string | null, return_temp_limit?: string | null, standby_temp?: string | null, valve_open?: string | null, valve_shut?: string | null, slope?: string | null, displace?: string | null, priority?: string | null } | null } | null };

export type HumidityTemperatureQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  from: Scalars['Float']['input'];
  to: Scalars['Float']['input'];
  interval: Scalars['Float']['input'];
}>;


export type HumidityTemperatureQuery = { __typename?: 'Query', meteringNodeGet?: { __typename?: 'MeteringNode', _id: string, devices?: Array<{ __typename?: 'MeteringNodeDevice', installationDate?: number | null }> | null, measurementList?: Array<{ __typename?: 'Measurement', time?: number | null, temperature?: number | null, humidity?: number | null }> | null, measurementLast?: { __typename?: 'Measurement', time?: number | null, temperature?: number | null, humidity?: number | null } | null, unit?: { __typename?: 'Unit', temperature?: string | null, humidity?: string | null } | null } | null };

export type ImpulseQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  from: Scalars['Float']['input'];
  to: Scalars['Float']['input'];
  interval: Scalars['Float']['input'];
}>;


export type ImpulseQuery = { __typename?: 'Query', meteringNodeGet?: { __typename?: 'MeteringNode', _id: string, measurementThroughputList?: Array<{ __typename?: 'Measurement', time?: number | null, counter?: number | null }> | null, measurementList?: Array<{ __typename?: 'Measurement', time?: number | null, counter?: number | null }> | null, measurementLast?: { __typename?: 'Measurement', time?: number | null, counter?: number | null } | null, measurementAggregatedValues?: { __typename?: 'MeasurementAggregatedValues', counter?: { __typename?: 'MeasurementAggregatedValue', difference?: number | null } | null } | null, unit?: { __typename?: 'Unit', counter?: string | null } | null, devices?: Array<{ __typename?: 'MeteringNodeDevice', installationDate?: number | null }> | null } | null };

export type ImpulseCurrentThroughputQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  from: Scalars['Float']['input'];
  to: Scalars['Float']['input'];
  interval: Scalars['Float']['input'];
}>;


export type ImpulseCurrentThroughputQuery = { __typename?: 'Query', meteringNodeGet?: { __typename?: 'MeteringNode', _id: string, measurementThroughputList?: Array<{ __typename?: 'Measurement', time?: number | null, counter?: number | null }> | null, unit?: { __typename?: 'Unit', counter?: string | null } | null } | null };

export type MbusHeatQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  from: Scalars['Float']['input'];
  to: Scalars['Float']['input'];
  interval: Scalars['Float']['input'];
}>;


export type MbusHeatQuery = { __typename?: 'Query', meteringNodeGet?: { __typename?: 'MeteringNode', _id: string, devices?: Array<{ __typename?: 'MeteringNodeDevice', installationDate?: number | null }> | null, measurementThroughputList?: Array<{ __typename?: 'Measurement', time?: number | null, M3?: number | null, Wh?: number | null }> | null, measurementList?: Array<{ __typename?: 'Measurement', time?: number | null, M3?: number | null, Wh?: number | null, tdelta?: number | null, tflow?: number | null, treturn?: number | null }> | null, measurementAggregatedValues?: { __typename?: 'MeasurementAggregatedValues', M3?: { __typename?: 'MeasurementAggregatedValue', min?: number | null, max?: number | null } | null, Wh?: { __typename?: 'MeasurementAggregatedValue', min?: number | null, max?: number | null } | null } | null, unit?: { __typename?: 'Unit', M3?: string | null, Wh?: string | null, tdelta?: string | null, tflow?: string | null, treturn?: string | null } | null } | null };

export type MetricsLayoutQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type MetricsLayoutQuery = { __typename?: 'Query', dashboardGet?: { __typename?: 'Dashboard', _id: string, name: string, layout: Array<{ __typename?: 'Layout', xxs: Array<{ __typename?: 'LayoutItem', w: number, h: number, x: number, y: number, i: string }>, xs: Array<{ __typename?: 'LayoutItem', w: number, h: number, x: number, y: number, i: string }>, sm: Array<{ __typename?: 'LayoutItem', w: number, h: number, x: number, y: number, i: string }>, md: Array<{ __typename?: 'LayoutItem', w: number, h: number, x: number, y: number, i: string }>, lg: Array<{ __typename?: 'LayoutItem', w: number, h: number, x: number, y: number, i: string }> }> } | null };

export type DevicesWithMeasurementsQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  ids?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  from: Scalars['Float']['input'];
  to: Scalars['Float']['input'];
  interval: Scalars['Float']['input'];
}>;


export type DevicesWithMeasurementsQuery = { __typename?: 'Query', meteringNodeList: { __typename?: 'MeteringNodeListQuery', result: Array<{ __typename?: 'MeteringNode', _id: string, category: DeviceTypeCategory, restart?: boolean | null, measurementList?: Array<{ __typename?: 'Measurement', time?: number | null, counter?: number | null, temperature?: number | null, humidity?: number | null, eq?: number | null, M3?: number | null, Wh?: number | null, co2?: number | null, ch4?: number | null, ppm10?: number | null, ppm25?: number | null, Irms1?: number | null, Vrms1?: number | null, bar?: number | null }> | null, measurementThroughputList?: Array<{ __typename?: 'Measurement', time?: number | null, counter?: number | null, temperature?: number | null, humidity?: number | null, eq?: number | null, M3?: number | null, Wh?: number | null, co2?: number | null, ch4?: number | null, ppm10?: number | null, ppm25?: number | null, Irms1?: number | null, Vrms1?: number | null, bar?: number | null }> | null, unit?: { __typename?: 'Unit', counter?: string | null, temperature?: string | null, humidity?: string | null, eq?: string | null, M3?: string | null, Wh?: string | null, co2?: string | null, ch4?: string | null, ppm10?: string | null, ppm25?: string | null, Irms1?: string | null, Vrms1?: string | null, bar?: string | null } | null }>, paging: { __typename?: 'Paging', offset: number, limit: number, total: number } } };

export type RsHeatQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  from: Scalars['Float']['input'];
  to: Scalars['Float']['input'];
  interval: Scalars['Float']['input'];
}>;


export type RsHeatQuery = { __typename?: 'Query', meteringNodeGet?: { __typename?: 'MeteringNode', _id: string, currentDevice?: { __typename?: 'MeteringNodeDevice', _id: string, pulseValue?: number | null } | null, measurementThroughputList?: Array<{ __typename?: 'Measurement', time?: number | null, eq?: number | null, ev?: number | null }> | null, measurementList?: Array<{ __typename?: 'Measurement', time?: number | null, eq?: number | null, ev?: number | null, ht?: number | null }> | null, unit?: { __typename?: 'Unit', eq?: string | null, ev?: string | null, ht?: string | null } | null, devices?: Array<{ __typename?: 'MeteringNodeDevice', installationDate?: number | null }> | null } | null };

export type TemperatureQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  from: Scalars['Float']['input'];
  to: Scalars['Float']['input'];
  interval: Scalars['Float']['input'];
}>;


export type TemperatureQuery = { __typename?: 'Query', meteringNodeGet?: { __typename?: 'MeteringNode', _id: string, devices?: Array<{ __typename?: 'MeteringNodeDevice', installationDate?: number | null }> | null, measurementList?: Array<{ __typename?: 'Measurement', time?: number | null, temperature?: number | null }> | null, measurementLast?: { __typename?: 'Measurement', time?: number | null, temperature?: number | null } | null, unit?: { __typename?: 'Unit', temperature?: string | null } | null } | null };

export type DeviceBasicInfoQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeviceBasicInfoQuery = { __typename?: 'Query', meteringNodeGet?: { __typename?: 'MeteringNode', _id: string, name: string, type: DeviceTypeName, category: DeviceTypeCategory, leaking?: boolean | null, restart?: boolean | null, location?: { __typename?: 'Location', _id: string, city: string, street: string } | null, currentDevice?: { __typename?: 'MeteringNodeDevice', _id: string, serial: string } | null } | null };

export type DeviceTypesQueryVariables = Exact<{
  filterByOrganisation: Scalars['Boolean']['input'];
}>;


export type DeviceTypesQuery = { __typename?: 'Query', deviceTypeList: Array<{ __typename?: 'DeviceType', category: DeviceTypeCategory }> };

export type DevicesQueryVariables = Exact<{
  date: Scalars['Float']['input'];
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  search: Scalars['String']['input'];
  category: Scalars['String']['input'];
  sort: MeteringNodeListSortParameters;
}>;


export type DevicesQuery = { __typename?: 'Query', meteringNodeList: { __typename?: 'MeteringNodeListQuery', result: Array<{ __typename?: 'MeteringNode', _id: string, name: string, category: DeviceTypeCategory, leaking?: boolean | null, restart?: boolean | null, currentDevice?: { __typename?: 'MeteringNodeDevice', serial: string, verificationDate?: number | null, currentModem?: { __typename?: 'MeteringNodeModem', _id: string, decdevid: string } | null } | null, measurementLast?: { __typename?: 'Measurement', time?: number | null, rssi?: number | null, counter?: number | null, temperature?: number | null, humidity?: number | null, eq?: number | null, M3?: number | null, Wh?: number | null, co2?: number | null, ch4?: number | null, ppm10?: number | null, ppm25?: number | null, Irms1?: number | null, Vrms1?: number | null, bar?: number | null } | null, unit?: { __typename?: 'Unit', counter?: string | null, temperature?: string | null, humidity?: string | null, eq?: string | null, M3?: string | null, Wh?: string | null, co2?: string | null, ch4?: string | null, ppm10?: string | null, ppm25?: string | null, Irms1?: string | null, Vrms1?: string | null, bar?: string | null } | null, location?: { __typename?: 'Location', _id: string, city: string, street: string } | null }>, paging: { __typename?: 'Paging', offset: number, limit: number, total: number } } };

export type SendToHorizonMutationVariables = Exact<{ [key: string]: never; }>;


export type SendToHorizonMutation = { __typename?: 'Mutation', locationSendMeasurementsToHorizon: boolean };

export type GroupQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  from: Scalars['Float']['input'];
  to: Scalars['Float']['input'];
  interval: Scalars['Float']['input'];
  tag?: InputMaybe<Scalars['String']['input']>;
}>;


export type GroupQuery = { __typename?: 'Query', meteringNodeList: { __typename?: 'MeteringNodeListQuery', result: Array<{ __typename?: 'MeteringNode', _id: string, name: string, category: DeviceTypeCategory, leaking?: boolean | null, restart?: boolean | null, currentDevice?: { __typename?: 'MeteringNodeDevice', serial: string } | null, location?: { __typename?: 'Location', _id: string, city: string, street: string } | null, measurementList?: Array<{ __typename?: 'Measurement', time?: number | null, counter?: number | null, bar?: number | null }> | null, measurementThroughputList?: Array<{ __typename?: 'Measurement', time?: number | null, counter?: number | null, bar?: number | null }> | null, measurementLast?: { __typename?: 'Measurement', time?: number | null, counter?: number | null, bar?: number | null } | null, unit?: { __typename?: 'Unit', counter?: string | null, bar?: string | null } | null }>, paging: { __typename?: 'Paging', offset: number, limit: number, total: number } } };

export type GroupMeteringNodesQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  tag: Scalars['String']['input'];
}>;


export type GroupMeteringNodesQuery = { __typename?: 'Query', meteringNodeList: { __typename?: 'MeteringNodeListQuery', result: Array<{ __typename?: 'MeteringNode', _id: string, name: string, category: DeviceTypeCategory, currentDevice?: { __typename?: 'MeteringNodeDevice', _id: string, serial: string } | null, location?: { __typename?: 'Location', _id: string, city: string, street: string } | null }>, paging: { __typename?: 'Paging', offset: number, limit: number, total: number } } };

export type ListTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListTagsQuery = { __typename?: 'Query', tagList: Array<string> };

export type GetCurrentPulsesQueryVariables = Exact<{
  decdevid: Scalars['String']['input'];
  port: Scalars['Float']['input'];
}>;


export type GetCurrentPulsesQuery = { __typename?: 'Query', currentPulsesGet?: { __typename?: 'CurrentPulses', time: number, counter?: number | null } | null };

export type LocationCreateMutationVariables = Exact<{
  country: Scalars['String']['input'];
  city: Scalars['String']['input'];
  street: Scalars['String']['input'];
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  meteringNodes?: InputMaybe<Array<MeteringNodeParameters> | MeteringNodeParameters>;
}>;


export type LocationCreateMutation = { __typename?: 'Mutation', locationCreate: { __typename?: 'Location', _id: string, country: string, city: string, street: string, latitude?: number | null, longitude?: number | null } };

export type LocationGetQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type LocationGetQuery = { __typename?: 'Query', locationGet?: { __typename?: 'Location', _id: string, country: string, city: string, street: string, latitude?: number | null, longitude?: number | null, externalId?: string | null, meteringNodes?: Array<{ __typename?: 'MeteringNode', _id: string, name: string, type: DeviceTypeName, tags?: Array<string> | null, devices?: Array<{ __typename?: 'MeteringNodeDevice', _id: string, serial: string, initialMeasurement?: number | null, pulseValue?: number | null, installationDate?: number | null, externalId?: string | null, dismantlingDate?: number | null, verificationDate?: number | null, modems?: Array<{ __typename?: 'MeteringNodeModem', _id: string, decdevid: string, initialPulses: number, port: number, installationDate?: number | null, dismantlingDate?: number | null }> | null }> | null }> | null } | null };

export type LocationUpdateMutationVariables = Exact<{
  id: Scalars['String']['input'];
  country: Scalars['String']['input'];
  city: Scalars['String']['input'];
  street: Scalars['String']['input'];
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  meteringNodes?: InputMaybe<Array<MeteringNodeParameters> | MeteringNodeParameters>;
}>;


export type LocationUpdateMutation = { __typename?: 'Mutation', locationUpdate: { __typename?: 'Location', _id: string, country: string, city: string, street: string, latitude?: number | null, longitude?: number | null } };

export type DeleteLocationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteLocationMutation = { __typename?: 'Mutation', locationDelete: boolean };

export type LocationImportFromHorizonMutationVariables = Exact<{
  controlCounters: Scalars['Boolean']['input'];
  counters: Scalars['Boolean']['input'];
  locations: Scalars['Boolean']['input'];
}>;


export type LocationImportFromHorizonMutation = { __typename?: 'Mutation', locationImportFromHorizon: boolean };

export type ListLocationQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  search: Scalars['String']['input'];
}>;


export type ListLocationQuery = { __typename?: 'Query', locationList: { __typename?: 'LocationListQuery', result: Array<{ __typename?: 'Location', _id: string, country: string, city: string, street: string, latitude?: number | null, longitude?: number | null }>, paging: { __typename?: 'Paging', offset: number, limit: number, total: number } } };

export type UserOrganisationQueryVariables = Exact<{ [key: string]: never; }>;


export type UserOrganisationQuery = { __typename?: 'Query', userCurrent?: { __typename?: 'User', _id: string, organisation: { __typename?: 'Organisation', _id: string, name: string } } | null };

export type LogQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;


export type LogQuery = { __typename?: 'Query', logList: { __typename?: 'LogListQuery', result: Array<{ __typename?: 'Log', _id: string, type: string, kind: string, key: string, lhs?: any | null, rhs?: any | null, address: string, name?: string | null, serial?: string | null, decdevid?: string | null, date: number }>, paging: { __typename?: 'Paging', offset: number, limit: number, total: number } } };

export type LogApiDataAccessQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;


export type LogApiDataAccessQuery = { __typename?: 'Query', logApiDataAccessList: { __typename?: 'LogApiDataAccessListQuery', result: Array<{ __typename?: 'LogApiDataAccess', _id: string, url: string, data: any, createdAt?: any | null }>, paging: { __typename?: 'Paging', offset: number, limit: number, total: number } } };

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'User', lastName: string } };

export type SearchTabsQueryVariables = Exact<{
  search: Scalars['String']['input'];
}>;


export type SearchTabsQuery = { __typename?: 'Query', meteringNodeList: { __typename?: 'MeteringNodeListQuery', paging: { __typename?: 'Paging', total: number } }, baseStationList: { __typename?: 'BaseStationListQuery', paging: { __typename?: 'Paging', total: number } }, locationList: { __typename?: 'LocationListQuery', paging: { __typename?: 'Paging', total: number } }, userList: { __typename?: 'UserListQuery', paging: { __typename?: 'Paging', total: number } } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', userCurrent?: { __typename?: 'User', _id: string } | null };

export type LoginToHorizonMutationVariables = Exact<{
  id: Scalars['String']['input'];
  horizonUsername: Scalars['String']['input'];
  horizonPassword: Scalars['String']['input'];
  horizonClientKey: Scalars['String']['input'];
  horizonDataSourceId: Scalars['String']['input'];
  horizonMeasurementReportingType: Scalars['String']['input'];
}>;


export type LoginToHorizonMutation = { __typename?: 'Mutation', userUpdate: { __typename?: 'User', _id: string, horizonUsername: string, horizonClientKey: string, horizonDataSourceId: string, horizonMeasurementReportingType?: string | null } };

export type GetApiTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type GetApiTokenQuery = { __typename?: 'Query', apiTokenGet: { __typename?: 'ApiToken', token: string } };

export type AddUserMutationVariables = Exact<{
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  language: Scalars['String']['input'];
  allowedLocations?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  admin: Scalars['Boolean']['input'];
  devicesView: Scalars['Boolean']['input'];
  devicesEdit: Scalars['Boolean']['input'];
  baseStationsView: Scalars['Boolean']['input'];
  baseStationsEdit: Scalars['Boolean']['input'];
  locationsView: Scalars['Boolean']['input'];
  locationsEdit: Scalars['Boolean']['input'];
  logView: Scalars['Boolean']['input'];
}>;


export type AddUserMutation = { __typename?: 'Mutation', userCreate: { __typename?: 'User', _id: string, firstName: string, lastName: string, email: string, allowedLocations?: Array<string> | null, admin: boolean, devicesView: boolean, devicesEdit: boolean, baseStationsView: boolean, baseStationsEdit: boolean, locationsView: boolean, locationsEdit: boolean, logView: boolean } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', userDelete: boolean };

export type EditUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  allowedLocations?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  admin: Scalars['Boolean']['input'];
  devicesView: Scalars['Boolean']['input'];
  devicesEdit: Scalars['Boolean']['input'];
  baseStationsView: Scalars['Boolean']['input'];
  baseStationsEdit: Scalars['Boolean']['input'];
  locationsView: Scalars['Boolean']['input'];
  locationsEdit: Scalars['Boolean']['input'];
  logView: Scalars['Boolean']['input'];
}>;


export type EditUserMutation = { __typename?: 'Mutation', userUpdate: { __typename?: 'User', _id: string, firstName: string, lastName: string, email: string, allowedLocations?: Array<string> | null, admin: boolean, devicesView: boolean, devicesEdit: boolean, baseStationsView: boolean, baseStationsEdit: boolean, locationsView: boolean, locationsEdit: boolean, logView: boolean } };

export type ListUserQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  search: Scalars['String']['input'];
}>;


export type ListUserQuery = { __typename?: 'Query', userList: { __typename?: 'UserListQuery', result: Array<{ __typename?: 'User', _id: string, firstName: string, lastName: string, email: string, admin: boolean, devicesView: boolean, devicesEdit: boolean, baseStationsView: boolean, baseStationsEdit: boolean, locationsView: boolean, locationsEdit: boolean, logView: boolean }>, paging: { __typename?: 'Paging', offset: number, limit: number, total: number } } };


export const SwitchLanguageDocument = gql`
    mutation switchLanguage($id: String!, $language: String!) {
  userUpdate(parameters: {_id: $id, language: $language}) {
    _id
    language
  }
}
    `;
export type SwitchLanguageMutationFn = Apollo.MutationFunction<SwitchLanguageMutation, SwitchLanguageMutationVariables>;

/**
 * __useSwitchLanguageMutation__
 *
 * To run a mutation, you first call `useSwitchLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchLanguageMutation, { data, loading, error }] = useSwitchLanguageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useSwitchLanguageMutation(baseOptions?: Apollo.MutationHookOptions<SwitchLanguageMutation, SwitchLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SwitchLanguageMutation, SwitchLanguageMutationVariables>(SwitchLanguageDocument, options);
      }
export type SwitchLanguageMutationHookResult = ReturnType<typeof useSwitchLanguageMutation>;
export type SwitchLanguageMutationResult = Apollo.MutationResult<SwitchLanguageMutation>;
export type SwitchLanguageMutationOptions = Apollo.BaseMutationOptions<SwitchLanguageMutation, SwitchLanguageMutationVariables>;
export const SwitchOrganisationDocument = gql`
    mutation SwitchOrganisation($id: String!, $organisationId: String!) {
  userUpdate(parameters: {_id: $id, organisationId: $organisationId}) {
    _id
    organisationId
  }
}
    `;
export type SwitchOrganisationMutationFn = Apollo.MutationFunction<SwitchOrganisationMutation, SwitchOrganisationMutationVariables>;

/**
 * __useSwitchOrganisationMutation__
 *
 * To run a mutation, you first call `useSwitchOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchOrganisationMutation, { data, loading, error }] = useSwitchOrganisationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useSwitchOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<SwitchOrganisationMutation, SwitchOrganisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SwitchOrganisationMutation, SwitchOrganisationMutationVariables>(SwitchOrganisationDocument, options);
      }
export type SwitchOrganisationMutationHookResult = ReturnType<typeof useSwitchOrganisationMutation>;
export type SwitchOrganisationMutationResult = Apollo.MutationResult<SwitchOrganisationMutation>;
export type SwitchOrganisationMutationOptions = Apollo.BaseMutationOptions<SwitchOrganisationMutation, SwitchOrganisationMutationVariables>;
export const UserMenuDocument = gql`
    query userMenu {
  userCurrent {
    _id
    firstName
    lastName
    email
    language
    organisationIds
    organisation {
      _id
      name
    }
    organisations {
      _id
      name
    }
  }
}
    `;

/**
 * __useUserMenuQuery__
 *
 * To run a query within a React component, call `useUserMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserMenuQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserMenuQuery(baseOptions?: Apollo.QueryHookOptions<UserMenuQuery, UserMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserMenuQuery, UserMenuQueryVariables>(UserMenuDocument, options);
      }
export function useUserMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserMenuQuery, UserMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserMenuQuery, UserMenuQueryVariables>(UserMenuDocument, options);
        }
export type UserMenuQueryHookResult = ReturnType<typeof useUserMenuQuery>;
export type UserMenuLazyQueryHookResult = ReturnType<typeof useUserMenuLazyQuery>;
export type UserMenuQueryResult = Apollo.QueryResult<UserMenuQuery, UserMenuQueryVariables>;
export const InputCounterDocument = gql`
    query InputCounter($offset: Int!, $limit: Int!) {
  meteringNodeList(offset: $offset, limit: $limit) {
    result {
      _id
      name
      currentDevice {
        _id
        serial
        currentModem {
          _id
        }
      }
      location {
        city
        street
      }
    }
    paging {
      offset
      limit
      total
    }
  }
}
    `;

/**
 * __useInputCounterQuery__
 *
 * To run a query within a React component, call `useInputCounterQuery` and pass it any options that fit your needs.
 * When your component renders, `useInputCounterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInputCounterQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useInputCounterQuery(baseOptions: Apollo.QueryHookOptions<InputCounterQuery, InputCounterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InputCounterQuery, InputCounterQueryVariables>(InputCounterDocument, options);
      }
export function useInputCounterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InputCounterQuery, InputCounterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InputCounterQuery, InputCounterQueryVariables>(InputCounterDocument, options);
        }
export type InputCounterQueryHookResult = ReturnType<typeof useInputCounterQuery>;
export type InputCounterLazyQueryHookResult = ReturnType<typeof useInputCounterLazyQuery>;
export type InputCounterQueryResult = Apollo.QueryResult<InputCounterQuery, InputCounterQueryVariables>;
export const InputLocationDocument = gql`
    query InputLocation($offset: Int!, $limit: Int!) {
  locationList(offset: $offset, limit: $limit) {
    result {
      _id
      country
      city
      street
    }
    paging {
      offset
      limit
      total
    }
  }
}
    `;

/**
 * __useInputLocationQuery__
 *
 * To run a query within a React component, call `useInputLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useInputLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInputLocationQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useInputLocationQuery(baseOptions: Apollo.QueryHookOptions<InputLocationQuery, InputLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InputLocationQuery, InputLocationQueryVariables>(InputLocationDocument, options);
      }
export function useInputLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InputLocationQuery, InputLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InputLocationQuery, InputLocationQueryVariables>(InputLocationDocument, options);
        }
export type InputLocationQueryHookResult = ReturnType<typeof useInputLocationQuery>;
export type InputLocationLazyQueryHookResult = ReturnType<typeof useInputLocationLazyQuery>;
export type InputLocationQueryResult = Apollo.QueryResult<InputLocationQuery, InputLocationQueryVariables>;
export const DeviceTypeListDocument = gql`
    query DeviceTypeList {
  deviceTypeList {
    category
    deviceCount
  }
}
    `;

/**
 * __useDeviceTypeListQuery__
 *
 * To run a query within a React component, call `useDeviceTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceTypeListQuery({
 *   variables: {
 *   },
 * });
 */
export function useDeviceTypeListQuery(baseOptions?: Apollo.QueryHookOptions<DeviceTypeListQuery, DeviceTypeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceTypeListQuery, DeviceTypeListQueryVariables>(DeviceTypeListDocument, options);
      }
export function useDeviceTypeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceTypeListQuery, DeviceTypeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceTypeListQuery, DeviceTypeListQueryVariables>(DeviceTypeListDocument, options);
        }
export type DeviceTypeListQueryHookResult = ReturnType<typeof useDeviceTypeListQuery>;
export type DeviceTypeListLazyQueryHookResult = ReturnType<typeof useDeviceTypeListLazyQuery>;
export type DeviceTypeListQueryResult = Apollo.QueryResult<DeviceTypeListQuery, DeviceTypeListQueryVariables>;
export const ExistingUserListDocument = gql`
    query ExistingUserList($offset: Int!, $limit: Int!, $search: String!) {
  userListAdmin(offset: $offset, limit: $limit, search: $search) {
    result {
      _id
      firstName
      lastName
      email
      organisationId
      organisationIds
      admin
      superAdmin
    }
    paging {
      offset
      limit
      total
    }
  }
}
    `;

/**
 * __useExistingUserListQuery__
 *
 * To run a query within a React component, call `useExistingUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExistingUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExistingUserListQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useExistingUserListQuery(baseOptions: Apollo.QueryHookOptions<ExistingUserListQuery, ExistingUserListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExistingUserListQuery, ExistingUserListQueryVariables>(ExistingUserListDocument, options);
      }
export function useExistingUserListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExistingUserListQuery, ExistingUserListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExistingUserListQuery, ExistingUserListQueryVariables>(ExistingUserListDocument, options);
        }
export type ExistingUserListQueryHookResult = ReturnType<typeof useExistingUserListQuery>;
export type ExistingUserListLazyQueryHookResult = ReturnType<typeof useExistingUserListLazyQuery>;
export type ExistingUserListQueryResult = Apollo.QueryResult<ExistingUserListQuery, ExistingUserListQueryVariables>;
export const AddUserToOrganisationDocument = gql`
    mutation addUserToOrganisation($id: String!, $organisationIds: [String!]) {
  userUpdate(parameters: {_id: $id, organisationIds: $organisationIds}) {
    _id
    organisationIds
  }
}
    `;
export type AddUserToOrganisationMutationFn = Apollo.MutationFunction<AddUserToOrganisationMutation, AddUserToOrganisationMutationVariables>;

/**
 * __useAddUserToOrganisationMutation__
 *
 * To run a mutation, you first call `useAddUserToOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToOrganisationMutation, { data, loading, error }] = useAddUserToOrganisationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organisationIds: // value for 'organisationIds'
 *   },
 * });
 */
export function useAddUserToOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<AddUserToOrganisationMutation, AddUserToOrganisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserToOrganisationMutation, AddUserToOrganisationMutationVariables>(AddUserToOrganisationDocument, options);
      }
export type AddUserToOrganisationMutationHookResult = ReturnType<typeof useAddUserToOrganisationMutation>;
export type AddUserToOrganisationMutationResult = Apollo.MutationResult<AddUserToOrganisationMutation>;
export type AddUserToOrganisationMutationOptions = Apollo.BaseMutationOptions<AddUserToOrganisationMutation, AddUserToOrganisationMutationVariables>;
export const UserCreateDocument = gql`
    mutation userCreate($organisationId: String!, $firstName: String!, $lastName: String!, $email: String!, $password: String!, $language: String!, $admin: Boolean!, $devicesView: Boolean!, $devicesEdit: Boolean!, $baseStationsView: Boolean!, $baseStationsEdit: Boolean!, $locationsView: Boolean!, $locationsEdit: Boolean!, $logView: Boolean!) {
  userCreate(
    parameters: {organisationId: $organisationId, firstName: $firstName, lastName: $lastName, email: $email, password: $password, language: $language, admin: $admin, devicesView: $devicesView, devicesEdit: $devicesEdit, baseStationsView: $baseStationsView, baseStationsEdit: $baseStationsEdit, locationsView: $locationsView, locationsEdit: $locationsEdit, logView: $logView}
  ) {
    _id
    firstName
    lastName
    email
    admin
    devicesView
    devicesEdit
    baseStationsView
    baseStationsEdit
    locationsView
    locationsEdit
    logView
  }
}
    `;
export type UserCreateMutationFn = Apollo.MutationFunction<UserCreateMutation, UserCreateMutationVariables>;

/**
 * __useUserCreateMutation__
 *
 * To run a mutation, you first call `useUserCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCreateMutation, { data, loading, error }] = useUserCreateMutation({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      language: // value for 'language'
 *      admin: // value for 'admin'
 *      devicesView: // value for 'devicesView'
 *      devicesEdit: // value for 'devicesEdit'
 *      baseStationsView: // value for 'baseStationsView'
 *      baseStationsEdit: // value for 'baseStationsEdit'
 *      locationsView: // value for 'locationsView'
 *      locationsEdit: // value for 'locationsEdit'
 *      logView: // value for 'logView'
 *   },
 * });
 */
export function useUserCreateMutation(baseOptions?: Apollo.MutationHookOptions<UserCreateMutation, UserCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserCreateMutation, UserCreateMutationVariables>(UserCreateDocument, options);
      }
export type UserCreateMutationHookResult = ReturnType<typeof useUserCreateMutation>;
export type UserCreateMutationResult = Apollo.MutationResult<UserCreateMutation>;
export type UserCreateMutationOptions = Apollo.BaseMutationOptions<UserCreateMutation, UserCreateMutationVariables>;
export const DeleteUserAdminDocument = gql`
    mutation DeleteUserAdmin($id: String!, $organisationId: String!) {
  userDelete(id: $id, organisationId: $organisationId)
}
    `;
export type DeleteUserAdminMutationFn = Apollo.MutationFunction<DeleteUserAdminMutation, DeleteUserAdminMutationVariables>;

/**
 * __useDeleteUserAdminMutation__
 *
 * To run a mutation, you first call `useDeleteUserAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserAdminMutation, { data, loading, error }] = useDeleteUserAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useDeleteUserAdminMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserAdminMutation, DeleteUserAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserAdminMutation, DeleteUserAdminMutationVariables>(DeleteUserAdminDocument, options);
      }
export type DeleteUserAdminMutationHookResult = ReturnType<typeof useDeleteUserAdminMutation>;
export type DeleteUserAdminMutationResult = Apollo.MutationResult<DeleteUserAdminMutation>;
export type DeleteUserAdminMutationOptions = Apollo.BaseMutationOptions<DeleteUserAdminMutation, DeleteUserAdminMutationVariables>;
export const UserGetDocument = gql`
    query userGet($id: String!, $organisationId: String) {
  userGet(id: $id, organisationId: $organisationId) {
    _id
    organisationId
    organisationIds
    firstName
    lastName
    email
    allowedLocations
    admin
    superAdmin
    devicesView
    devicesEdit
    baseStationsView
    baseStationsEdit
    locationsView
    locationsEdit
    logView
  }
}
    `;

/**
 * __useUserGetQuery__
 *
 * To run a query within a React component, call `useUserGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useUserGetQuery(baseOptions: Apollo.QueryHookOptions<UserGetQuery, UserGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGetQuery, UserGetQueryVariables>(UserGetDocument, options);
      }
export function useUserGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGetQuery, UserGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGetQuery, UserGetQueryVariables>(UserGetDocument, options);
        }
export type UserGetQueryHookResult = ReturnType<typeof useUserGetQuery>;
export type UserGetLazyQueryHookResult = ReturnType<typeof useUserGetLazyQuery>;
export type UserGetQueryResult = Apollo.QueryResult<UserGetQuery, UserGetQueryVariables>;
export const UserUpdateDocument = gql`
    mutation userUpdate($id: String!, $firstName: String!, $lastName: String!, $email: String!, $password: String, $admin: Boolean!, $devicesView: Boolean!, $devicesEdit: Boolean!, $baseStationsView: Boolean!, $baseStationsEdit: Boolean!, $locationsView: Boolean!, $locationsEdit: Boolean!, $logView: Boolean!) {
  userUpdate(
    parameters: {_id: $id, firstName: $firstName, lastName: $lastName, email: $email, password: $password, admin: $admin, devicesView: $devicesView, devicesEdit: $devicesEdit, baseStationsView: $baseStationsView, baseStationsEdit: $baseStationsEdit, locationsView: $locationsView, locationsEdit: $locationsEdit, logView: $logView}
  ) {
    _id
    organisationId
    firstName
    lastName
    email
    admin
    devicesView
    devicesEdit
    baseStationsView
    baseStationsEdit
    locationsView
    locationsEdit
    logView
  }
}
    `;
export type UserUpdateMutationFn = Apollo.MutationFunction<UserUpdateMutation, UserUpdateMutationVariables>;

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      admin: // value for 'admin'
 *      devicesView: // value for 'devicesView'
 *      devicesEdit: // value for 'devicesEdit'
 *      baseStationsView: // value for 'baseStationsView'
 *      baseStationsEdit: // value for 'baseStationsEdit'
 *      locationsView: // value for 'locationsView'
 *      locationsEdit: // value for 'locationsEdit'
 *      logView: // value for 'logView'
 *   },
 * });
 */
export function useUserUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateMutation, UserUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdateMutation, UserUpdateMutationVariables>(UserUpdateDocument, options);
      }
export type UserUpdateMutationHookResult = ReturnType<typeof useUserUpdateMutation>;
export type UserUpdateMutationResult = Apollo.MutationResult<UserUpdateMutation>;
export type UserUpdateMutationOptions = Apollo.BaseMutationOptions<UserUpdateMutation, UserUpdateMutationVariables>;
export const ImpersonateDocument = gql`
    mutation impersonate($id: String!) {
  impersonate(id: $id) {
    _id
    firstName
    lastName
    email
  }
}
    `;
export type ImpersonateMutationFn = Apollo.MutationFunction<ImpersonateMutation, ImpersonateMutationVariables>;

/**
 * __useImpersonateMutation__
 *
 * To run a mutation, you first call `useImpersonateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImpersonateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [impersonateMutation, { data, loading, error }] = useImpersonateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useImpersonateMutation(baseOptions?: Apollo.MutationHookOptions<ImpersonateMutation, ImpersonateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImpersonateMutation, ImpersonateMutationVariables>(ImpersonateDocument, options);
      }
export type ImpersonateMutationHookResult = ReturnType<typeof useImpersonateMutation>;
export type ImpersonateMutationResult = Apollo.MutationResult<ImpersonateMutation>;
export type ImpersonateMutationOptions = Apollo.BaseMutationOptions<ImpersonateMutation, ImpersonateMutationVariables>;
export const AdminUserListDocument = gql`
    query AdminUserList($organisationId: String!, $offset: Int!, $limit: Int!) {
  userListAdmin(organisationId: $organisationId, offset: $offset, limit: $limit) {
    result {
      _id
      firstName
      lastName
      email
      admin
      superAdmin
      organisationIds
    }
    paging {
      offset
      limit
      total
    }
  }
}
    `;

/**
 * __useAdminUserListQuery__
 *
 * To run a query within a React component, call `useAdminUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUserListQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAdminUserListQuery(baseOptions: Apollo.QueryHookOptions<AdminUserListQuery, AdminUserListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminUserListQuery, AdminUserListQueryVariables>(AdminUserListDocument, options);
      }
export function useAdminUserListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminUserListQuery, AdminUserListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminUserListQuery, AdminUserListQueryVariables>(AdminUserListDocument, options);
        }
export type AdminUserListQueryHookResult = ReturnType<typeof useAdminUserListQuery>;
export type AdminUserListLazyQueryHookResult = ReturnType<typeof useAdminUserListLazyQuery>;
export type AdminUserListQueryResult = Apollo.QueryResult<AdminUserListQuery, AdminUserListQueryVariables>;
export const OrganisationCreateDocument = gql`
    mutation OrganisationCreate($table: String!, $name: String!, $token: String!, $devToken: String!) {
  organisationCreate(
    table: $table
    name: $name
    token: $token
    devToken: $devToken
  ) {
    _id
    name
    table
    token
    devToken
  }
}
    `;
export type OrganisationCreateMutationFn = Apollo.MutationFunction<OrganisationCreateMutation, OrganisationCreateMutationVariables>;

/**
 * __useOrganisationCreateMutation__
 *
 * To run a mutation, you first call `useOrganisationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationCreateMutation, { data, loading, error }] = useOrganisationCreateMutation({
 *   variables: {
 *      table: // value for 'table'
 *      name: // value for 'name'
 *      token: // value for 'token'
 *      devToken: // value for 'devToken'
 *   },
 * });
 */
export function useOrganisationCreateMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationCreateMutation, OrganisationCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationCreateMutation, OrganisationCreateMutationVariables>(OrganisationCreateDocument, options);
      }
export type OrganisationCreateMutationHookResult = ReturnType<typeof useOrganisationCreateMutation>;
export type OrganisationCreateMutationResult = Apollo.MutationResult<OrganisationCreateMutation>;
export type OrganisationCreateMutationOptions = Apollo.BaseMutationOptions<OrganisationCreateMutation, OrganisationCreateMutationVariables>;
export const OrganisationUpdateDocument = gql`
    mutation OrganisationUpdate($id: String!, $table: String!, $name: String!, $token: String!, $devToken: String!) {
  organisationUpdate(
    id: $id
    table: $table
    name: $name
    token: $token
    devToken: $devToken
  ) {
    _id
    name
    table
    token
    devToken
  }
}
    `;
export type OrganisationUpdateMutationFn = Apollo.MutationFunction<OrganisationUpdateMutation, OrganisationUpdateMutationVariables>;

/**
 * __useOrganisationUpdateMutation__
 *
 * To run a mutation, you first call `useOrganisationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationUpdateMutation, { data, loading, error }] = useOrganisationUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      table: // value for 'table'
 *      name: // value for 'name'
 *      token: // value for 'token'
 *      devToken: // value for 'devToken'
 *   },
 * });
 */
export function useOrganisationUpdateMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationUpdateMutation, OrganisationUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationUpdateMutation, OrganisationUpdateMutationVariables>(OrganisationUpdateDocument, options);
      }
export type OrganisationUpdateMutationHookResult = ReturnType<typeof useOrganisationUpdateMutation>;
export type OrganisationUpdateMutationResult = Apollo.MutationResult<OrganisationUpdateMutation>;
export type OrganisationUpdateMutationOptions = Apollo.BaseMutationOptions<OrganisationUpdateMutation, OrganisationUpdateMutationVariables>;
export const OrganisationDeleteDocument = gql`
    mutation OrganisationDelete($id: String!) {
  organisationDelete(id: $id)
}
    `;
export type OrganisationDeleteMutationFn = Apollo.MutationFunction<OrganisationDeleteMutation, OrganisationDeleteMutationVariables>;

/**
 * __useOrganisationDeleteMutation__
 *
 * To run a mutation, you first call `useOrganisationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationDeleteMutation, { data, loading, error }] = useOrganisationDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganisationDeleteMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationDeleteMutation, OrganisationDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationDeleteMutation, OrganisationDeleteMutationVariables>(OrganisationDeleteDocument, options);
      }
export type OrganisationDeleteMutationHookResult = ReturnType<typeof useOrganisationDeleteMutation>;
export type OrganisationDeleteMutationResult = Apollo.MutationResult<OrganisationDeleteMutation>;
export type OrganisationDeleteMutationOptions = Apollo.BaseMutationOptions<OrganisationDeleteMutation, OrganisationDeleteMutationVariables>;
export const OrganisationCountDocument = gql`
    query organisationCount($search: String!) {
  organisationList(search: $search) {
    paging {
      total
    }
  }
}
    `;

/**
 * __useOrganisationCountQuery__
 *
 * To run a query within a React component, call `useOrganisationCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationCountQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useOrganisationCountQuery(baseOptions: Apollo.QueryHookOptions<OrganisationCountQuery, OrganisationCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationCountQuery, OrganisationCountQueryVariables>(OrganisationCountDocument, options);
      }
export function useOrganisationCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationCountQuery, OrganisationCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationCountQuery, OrganisationCountQueryVariables>(OrganisationCountDocument, options);
        }
export type OrganisationCountQueryHookResult = ReturnType<typeof useOrganisationCountQuery>;
export type OrganisationCountLazyQueryHookResult = ReturnType<typeof useOrganisationCountLazyQuery>;
export type OrganisationCountQueryResult = Apollo.QueryResult<OrganisationCountQuery, OrganisationCountQueryVariables>;
export const OrganisationGetDocument = gql`
    query OrganisationGet($id: String) {
  organisationGet(id: $id) {
    _id
    name
    table
    token
    devToken
  }
}
    `;

/**
 * __useOrganisationGetQuery__
 *
 * To run a query within a React component, call `useOrganisationGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganisationGetQuery(baseOptions?: Apollo.QueryHookOptions<OrganisationGetQuery, OrganisationGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationGetQuery, OrganisationGetQueryVariables>(OrganisationGetDocument, options);
      }
export function useOrganisationGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationGetQuery, OrganisationGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationGetQuery, OrganisationGetQueryVariables>(OrganisationGetDocument, options);
        }
export type OrganisationGetQueryHookResult = ReturnType<typeof useOrganisationGetQuery>;
export type OrganisationGetLazyQueryHookResult = ReturnType<typeof useOrganisationGetLazyQuery>;
export type OrganisationGetQueryResult = Apollo.QueryResult<OrganisationGetQuery, OrganisationGetQueryVariables>;
export const OrganisationListDocument = gql`
    query OrganisationList($offset: Int!, $limit: Int!, $search: String!) {
  organisationList(offset: $offset, limit: $limit, search: $search) {
    result {
      _id
      name
      table
      createdAt
    }
    paging {
      offset
      limit
      total
    }
  }
}
    `;

/**
 * __useOrganisationListQuery__
 *
 * To run a query within a React component, call `useOrganisationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationListQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useOrganisationListQuery(baseOptions: Apollo.QueryHookOptions<OrganisationListQuery, OrganisationListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationListQuery, OrganisationListQueryVariables>(OrganisationListDocument, options);
      }
export function useOrganisationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationListQuery, OrganisationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationListQuery, OrganisationListQueryVariables>(OrganisationListDocument, options);
        }
export type OrganisationListQueryHookResult = ReturnType<typeof useOrganisationListQuery>;
export type OrganisationListLazyQueryHookResult = ReturnType<typeof useOrganisationListLazyQuery>;
export type OrganisationListQueryResult = Apollo.QueryResult<OrganisationListQuery, OrganisationListQueryVariables>;
export const AlertRulesDocument = gql`
    query AlertRules {
  alertRuleList {
    result {
      _id
      organisationId
      deviceTypeCategory
      deviceIds
      rules {
        logicalOperator
        reducer
        range
        measurementName
        timeSpan
        value
      }
    }
    paging {
      offset
      limit
      total
    }
  }
}
    `;

/**
 * __useAlertRulesQuery__
 *
 * To run a query within a React component, call `useAlertRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlertRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlertRulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAlertRulesQuery(baseOptions?: Apollo.QueryHookOptions<AlertRulesQuery, AlertRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AlertRulesQuery, AlertRulesQueryVariables>(AlertRulesDocument, options);
      }
export function useAlertRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AlertRulesQuery, AlertRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AlertRulesQuery, AlertRulesQueryVariables>(AlertRulesDocument, options);
        }
export type AlertRulesQueryHookResult = ReturnType<typeof useAlertRulesQuery>;
export type AlertRulesLazyQueryHookResult = ReturnType<typeof useAlertRulesLazyQuery>;
export type AlertRulesQueryResult = Apollo.QueryResult<AlertRulesQuery, AlertRulesQueryVariables>;
export const CreateAlertRuleDocument = gql`
    mutation createAlertRule($deviceTypeCategory: DeviceTypeCategory!, $deviceIds: [String!]!, $rules: [AlertRuleParameters!]!) {
  alertRuleCreate(
    parameters: {deviceTypeCategory: $deviceTypeCategory, deviceIds: $deviceIds, rules: $rules}
  ) {
    _id
    deviceIds
    rules {
      logicalOperator
      reducer
      range
      measurementName
      timeSpan
      value
    }
  }
}
    `;
export type CreateAlertRuleMutationFn = Apollo.MutationFunction<CreateAlertRuleMutation, CreateAlertRuleMutationVariables>;

/**
 * __useCreateAlertRuleMutation__
 *
 * To run a mutation, you first call `useCreateAlertRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAlertRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAlertRuleMutation, { data, loading, error }] = useCreateAlertRuleMutation({
 *   variables: {
 *      deviceTypeCategory: // value for 'deviceTypeCategory'
 *      deviceIds: // value for 'deviceIds'
 *      rules: // value for 'rules'
 *   },
 * });
 */
export function useCreateAlertRuleMutation(baseOptions?: Apollo.MutationHookOptions<CreateAlertRuleMutation, CreateAlertRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAlertRuleMutation, CreateAlertRuleMutationVariables>(CreateAlertRuleDocument, options);
      }
export type CreateAlertRuleMutationHookResult = ReturnType<typeof useCreateAlertRuleMutation>;
export type CreateAlertRuleMutationResult = Apollo.MutationResult<CreateAlertRuleMutation>;
export type CreateAlertRuleMutationOptions = Apollo.BaseMutationOptions<CreateAlertRuleMutation, CreateAlertRuleMutationVariables>;
export const DeleteAlertRuleDocument = gql`
    mutation deleteAlertRule($id: String!) {
  alertRuleDelete(id: $id)
}
    `;
export type DeleteAlertRuleMutationFn = Apollo.MutationFunction<DeleteAlertRuleMutation, DeleteAlertRuleMutationVariables>;

/**
 * __useDeleteAlertRuleMutation__
 *
 * To run a mutation, you first call `useDeleteAlertRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAlertRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAlertRuleMutation, { data, loading, error }] = useDeleteAlertRuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAlertRuleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAlertRuleMutation, DeleteAlertRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAlertRuleMutation, DeleteAlertRuleMutationVariables>(DeleteAlertRuleDocument, options);
      }
export type DeleteAlertRuleMutationHookResult = ReturnType<typeof useDeleteAlertRuleMutation>;
export type DeleteAlertRuleMutationResult = Apollo.MutationResult<DeleteAlertRuleMutation>;
export type DeleteAlertRuleMutationOptions = Apollo.BaseMutationOptions<DeleteAlertRuleMutation, DeleteAlertRuleMutationVariables>;
export const UpdateAlertRuleDocument = gql`
    mutation updateAlertRule($id: String!, $deviceTypeCategory: DeviceTypeCategory!, $deviceIds: [String!]!, $rules: [AlertRuleParameters!]!) {
  alertRuleUpdate(
    parameters: {_id: $id, deviceTypeCategory: $deviceTypeCategory, deviceIds: $deviceIds, rules: $rules}
  ) {
    _id
    deviceIds
    rules {
      logicalOperator
      reducer
      range
      measurementName
      timeSpan
      value
    }
  }
}
    `;
export type UpdateAlertRuleMutationFn = Apollo.MutationFunction<UpdateAlertRuleMutation, UpdateAlertRuleMutationVariables>;

/**
 * __useUpdateAlertRuleMutation__
 *
 * To run a mutation, you first call `useUpdateAlertRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlertRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlertRuleMutation, { data, loading, error }] = useUpdateAlertRuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deviceTypeCategory: // value for 'deviceTypeCategory'
 *      deviceIds: // value for 'deviceIds'
 *      rules: // value for 'rules'
 *   },
 * });
 */
export function useUpdateAlertRuleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAlertRuleMutation, UpdateAlertRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAlertRuleMutation, UpdateAlertRuleMutationVariables>(UpdateAlertRuleDocument, options);
      }
export type UpdateAlertRuleMutationHookResult = ReturnType<typeof useUpdateAlertRuleMutation>;
export type UpdateAlertRuleMutationResult = Apollo.MutationResult<UpdateAlertRuleMutation>;
export type UpdateAlertRuleMutationOptions = Apollo.BaseMutationOptions<UpdateAlertRuleMutation, UpdateAlertRuleMutationVariables>;
export const CounterInputDocument = gql`
    query CounterInput($offset: Int!, $limit: Int!) {
  meteringNodeList(offset: $offset, limit: $limit) {
    result {
      _id
      name
      category
      currentDevice {
        _id
        serial
      }
      location {
        _id
        city
        street
      }
    }
    paging {
      offset
      limit
      total
    }
  }
}
    `;

/**
 * __useCounterInputQuery__
 *
 * To run a query within a React component, call `useCounterInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounterInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounterInputQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCounterInputQuery(baseOptions: Apollo.QueryHookOptions<CounterInputQuery, CounterInputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CounterInputQuery, CounterInputQueryVariables>(CounterInputDocument, options);
      }
export function useCounterInputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CounterInputQuery, CounterInputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CounterInputQuery, CounterInputQueryVariables>(CounterInputDocument, options);
        }
export type CounterInputQueryHookResult = ReturnType<typeof useCounterInputQuery>;
export type CounterInputLazyQueryHookResult = ReturnType<typeof useCounterInputLazyQuery>;
export type CounterInputQueryResult = Apollo.QueryResult<CounterInputQuery, CounterInputQueryVariables>;
export const AddBaseStationDocument = gql`
    mutation AddBaseStation($items: [BaseStationCreateParameters!]!) {
  baseStationCreateMany(items: $items) {
    _id
    decdevid
    locationId
  }
}
    `;
export type AddBaseStationMutationFn = Apollo.MutationFunction<AddBaseStationMutation, AddBaseStationMutationVariables>;

/**
 * __useAddBaseStationMutation__
 *
 * To run a mutation, you first call `useAddBaseStationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBaseStationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBaseStationMutation, { data, loading, error }] = useAddBaseStationMutation({
 *   variables: {
 *      items: // value for 'items'
 *   },
 * });
 */
export function useAddBaseStationMutation(baseOptions?: Apollo.MutationHookOptions<AddBaseStationMutation, AddBaseStationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBaseStationMutation, AddBaseStationMutationVariables>(AddBaseStationDocument, options);
      }
export type AddBaseStationMutationHookResult = ReturnType<typeof useAddBaseStationMutation>;
export type AddBaseStationMutationResult = Apollo.MutationResult<AddBaseStationMutation>;
export type AddBaseStationMutationOptions = Apollo.BaseMutationOptions<AddBaseStationMutation, AddBaseStationMutationVariables>;
export const BaseStationsDocument = gql`
    query BaseStations($offset: Int!, $limit: Int!, $search: String!, $sort: BaseStationListSortParameters!) {
  baseStationList(offset: $offset, limit: $limit, search: $search, sort: $sort) {
    result {
      _id
      decdevid
      location {
        _id
        city
        street
      }
      lastSignalTime
      createdAt
    }
    paging {
      offset
      limit
      total
    }
  }
}
    `;

/**
 * __useBaseStationsQuery__
 *
 * To run a query within a React component, call `useBaseStationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBaseStationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaseStationsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useBaseStationsQuery(baseOptions: Apollo.QueryHookOptions<BaseStationsQuery, BaseStationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BaseStationsQuery, BaseStationsQueryVariables>(BaseStationsDocument, options);
      }
export function useBaseStationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BaseStationsQuery, BaseStationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BaseStationsQuery, BaseStationsQueryVariables>(BaseStationsDocument, options);
        }
export type BaseStationsQueryHookResult = ReturnType<typeof useBaseStationsQuery>;
export type BaseStationsLazyQueryHookResult = ReturnType<typeof useBaseStationsLazyQuery>;
export type BaseStationsQueryResult = Apollo.QueryResult<BaseStationsQuery, BaseStationsQueryVariables>;
export const DeleteBaseStationDocument = gql`
    mutation DeleteBaseStation($id: String!) {
  baseStationDelete(id: $id)
}
    `;
export type DeleteBaseStationMutationFn = Apollo.MutationFunction<DeleteBaseStationMutation, DeleteBaseStationMutationVariables>;

/**
 * __useDeleteBaseStationMutation__
 *
 * To run a mutation, you first call `useDeleteBaseStationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBaseStationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBaseStationMutation, { data, loading, error }] = useDeleteBaseStationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBaseStationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBaseStationMutation, DeleteBaseStationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBaseStationMutation, DeleteBaseStationMutationVariables>(DeleteBaseStationDocument, options);
      }
export type DeleteBaseStationMutationHookResult = ReturnType<typeof useDeleteBaseStationMutation>;
export type DeleteBaseStationMutationResult = Apollo.MutationResult<DeleteBaseStationMutation>;
export type DeleteBaseStationMutationOptions = Apollo.BaseMutationOptions<DeleteBaseStationMutation, DeleteBaseStationMutationVariables>;
export const EditBaseStationDocument = gql`
    mutation EditBaseStation($id: String!, $decdevid: String!, $locationId: String) {
  baseStationUpdate(
    parameters: {_id: $id, decdevid: $decdevid, locationId: $locationId}
  ) {
    _id
    decdevid
    locationId
  }
}
    `;
export type EditBaseStationMutationFn = Apollo.MutationFunction<EditBaseStationMutation, EditBaseStationMutationVariables>;

/**
 * __useEditBaseStationMutation__
 *
 * To run a mutation, you first call `useEditBaseStationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditBaseStationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editBaseStationMutation, { data, loading, error }] = useEditBaseStationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      decdevid: // value for 'decdevid'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useEditBaseStationMutation(baseOptions?: Apollo.MutationHookOptions<EditBaseStationMutation, EditBaseStationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditBaseStationMutation, EditBaseStationMutationVariables>(EditBaseStationDocument, options);
      }
export type EditBaseStationMutationHookResult = ReturnType<typeof useEditBaseStationMutation>;
export type EditBaseStationMutationResult = Apollo.MutationResult<EditBaseStationMutation>;
export type EditBaseStationMutationOptions = Apollo.BaseMutationOptions<EditBaseStationMutation, EditBaseStationMutationVariables>;
export const GetBaseStationDocument = gql`
    query GetBaseStation($id: String) {
  baseStationGet(id: $id) {
    _id
    decdevid
    locationId
  }
}
    `;

/**
 * __useGetBaseStationQuery__
 *
 * To run a query within a React component, call `useGetBaseStationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseStationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseStationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBaseStationQuery(baseOptions?: Apollo.QueryHookOptions<GetBaseStationQuery, GetBaseStationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBaseStationQuery, GetBaseStationQueryVariables>(GetBaseStationDocument, options);
      }
export function useGetBaseStationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBaseStationQuery, GetBaseStationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBaseStationQuery, GetBaseStationQueryVariables>(GetBaseStationDocument, options);
        }
export type GetBaseStationQueryHookResult = ReturnType<typeof useGetBaseStationQuery>;
export type GetBaseStationLazyQueryHookResult = ReturnType<typeof useGetBaseStationLazyQuery>;
export type GetBaseStationQueryResult = Apollo.QueryResult<GetBaseStationQuery, GetBaseStationQueryVariables>;
export const DeviceNavDocument = gql`
    query DeviceNav($offset: Int!, $limit: Int!) {
  meteringNodeList(offset: $offset, limit: $limit) {
    result {
      _id
      name
      category
      leaking
      restart
      location {
        _id
        city
        street
      }
    }
    paging {
      offset
      limit
      total
    }
  }
}
    `;

/**
 * __useDeviceNavQuery__
 *
 * To run a query within a React component, call `useDeviceNavQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceNavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceNavQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDeviceNavQuery(baseOptions: Apollo.QueryHookOptions<DeviceNavQuery, DeviceNavQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceNavQuery, DeviceNavQueryVariables>(DeviceNavDocument, options);
      }
export function useDeviceNavLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceNavQuery, DeviceNavQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceNavQuery, DeviceNavQueryVariables>(DeviceNavDocument, options);
        }
export type DeviceNavQueryHookResult = ReturnType<typeof useDeviceNavQuery>;
export type DeviceNavLazyQueryHookResult = ReturnType<typeof useDeviceNavLazyQuery>;
export type DeviceNavQueryResult = Apollo.QueryResult<DeviceNavQuery, DeviceNavQueryVariables>;
export const Ch4Document = gql`
    query CH4($id: String, $from: Float!, $to: Float!, $interval: Float!) {
  meteringNodeGet(id: $id) {
    _id
    devices {
      installationDate
    }
    measurementList(from: $from, to: $to, interval: $interval) {
      time
      ch4
    }
    unit {
      ch4
    }
  }
}
    `;

/**
 * __useCh4Query__
 *
 * To run a query within a React component, call `useCh4Query` and pass it any options that fit your needs.
 * When your component renders, `useCh4Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCh4Query({
 *   variables: {
 *      id: // value for 'id'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      interval: // value for 'interval'
 *   },
 * });
 */
export function useCh4Query(baseOptions: Apollo.QueryHookOptions<Ch4Query, Ch4QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Ch4Query, Ch4QueryVariables>(Ch4Document, options);
      }
export function useCh4LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Ch4Query, Ch4QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Ch4Query, Ch4QueryVariables>(Ch4Document, options);
        }
export type Ch4QueryHookResult = ReturnType<typeof useCh4Query>;
export type Ch4LazyQueryHookResult = ReturnType<typeof useCh4LazyQuery>;
export type Ch4QueryResult = Apollo.QueryResult<Ch4Query, Ch4QueryVariables>;
export const Co2Document = gql`
    query CO2($id: String, $from: Float!, $to: Float!, $interval: Float!) {
  meteringNodeGet(id: $id) {
    _id
    devices {
      installationDate
    }
    measurementList(from: $from, to: $to, interval: $interval) {
      time
      co2
    }
    unit {
      co2
    }
  }
}
    `;

/**
 * __useCo2Query__
 *
 * To run a query within a React component, call `useCo2Query` and pass it any options that fit your needs.
 * When your component renders, `useCo2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCo2Query({
 *   variables: {
 *      id: // value for 'id'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      interval: // value for 'interval'
 *   },
 * });
 */
export function useCo2Query(baseOptions: Apollo.QueryHookOptions<Co2Query, Co2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Co2Query, Co2QueryVariables>(Co2Document, options);
      }
export function useCo2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Co2Query, Co2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Co2Query, Co2QueryVariables>(Co2Document, options);
        }
export type Co2QueryHookResult = ReturnType<typeof useCo2Query>;
export type Co2LazyQueryHookResult = ReturnType<typeof useCo2LazyQuery>;
export type Co2QueryResult = Apollo.QueryResult<Co2Query, Co2QueryVariables>;
export const DustDocument = gql`
    query Dust($id: String, $from: Float!, $to: Float!, $interval: Float!) {
  meteringNodeGet(id: $id) {
    _id
    devices {
      installationDate
    }
    measurementList(from: $from, to: $to, interval: $interval) {
      time
      ppm10
      ppm25
    }
    unit {
      ppm10
      ppm25
    }
  }
}
    `;

/**
 * __useDustQuery__
 *
 * To run a query within a React component, call `useDustQuery` and pass it any options that fit your needs.
 * When your component renders, `useDustQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDustQuery({
 *   variables: {
 *      id: // value for 'id'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      interval: // value for 'interval'
 *   },
 * });
 */
export function useDustQuery(baseOptions: Apollo.QueryHookOptions<DustQuery, DustQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DustQuery, DustQueryVariables>(DustDocument, options);
      }
export function useDustLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DustQuery, DustQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DustQuery, DustQueryVariables>(DustDocument, options);
        }
export type DustQueryHookResult = ReturnType<typeof useDustQuery>;
export type DustLazyQueryHookResult = ReturnType<typeof useDustLazyQuery>;
export type DustQueryResult = Apollo.QueryResult<DustQuery, DustQueryVariables>;
export const EditMetricsLayoutDocument = gql`
    mutation EditMetricsLayout($name: String!, $layout: LayoutParameters!) {
  dashboardUpdate(parameters: {name: $name, layout: $layout}) {
    _id
  }
}
    `;
export type EditMetricsLayoutMutationFn = Apollo.MutationFunction<EditMetricsLayoutMutation, EditMetricsLayoutMutationVariables>;

/**
 * __useEditMetricsLayoutMutation__
 *
 * To run a mutation, you first call `useEditMetricsLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMetricsLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMetricsLayoutMutation, { data, loading, error }] = useEditMetricsLayoutMutation({
 *   variables: {
 *      name: // value for 'name'
 *      layout: // value for 'layout'
 *   },
 * });
 */
export function useEditMetricsLayoutMutation(baseOptions?: Apollo.MutationHookOptions<EditMetricsLayoutMutation, EditMetricsLayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMetricsLayoutMutation, EditMetricsLayoutMutationVariables>(EditMetricsLayoutDocument, options);
      }
export type EditMetricsLayoutMutationHookResult = ReturnType<typeof useEditMetricsLayoutMutation>;
export type EditMetricsLayoutMutationResult = Apollo.MutationResult<EditMetricsLayoutMutation>;
export type EditMetricsLayoutMutationOptions = Apollo.BaseMutationOptions<EditMetricsLayoutMutation, EditMetricsLayoutMutationVariables>;
export const ElectricityDocument = gql`
    query Electricity($id: String, $from: Float!, $to: Float!, $interval: Float!) {
  meteringNodeGet(id: $id) {
    _id
    devices {
      installationDate
    }
    measurementList(from: $from, to: $to, interval: $interval) {
      time
      Irms1
      Irms2
      Irms3
      PF1
      PF2
      PF3
      Realpower1
      Realpower2
      Realpower3
      Vrms1
      Vrms2
      Vrms3
    }
  }
}
    `;

/**
 * __useElectricityQuery__
 *
 * To run a query within a React component, call `useElectricityQuery` and pass it any options that fit your needs.
 * When your component renders, `useElectricityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useElectricityQuery({
 *   variables: {
 *      id: // value for 'id'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      interval: // value for 'interval'
 *   },
 * });
 */
export function useElectricityQuery(baseOptions: Apollo.QueryHookOptions<ElectricityQuery, ElectricityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ElectricityQuery, ElectricityQueryVariables>(ElectricityDocument, options);
      }
export function useElectricityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ElectricityQuery, ElectricityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ElectricityQuery, ElectricityQueryVariables>(ElectricityDocument, options);
        }
export type ElectricityQueryHookResult = ReturnType<typeof useElectricityQuery>;
export type ElectricityLazyQueryHookResult = ReturnType<typeof useElectricityLazyQuery>;
export type ElectricityQueryResult = Apollo.QueryResult<ElectricityQuery, ElectricityQueryVariables>;
export const ElectricityAggregatedValuesDocument = gql`
    query ElectricityAggregatedValues($id: String, $from: Float!, $to: Float!) {
  meteringNodeGet(id: $id) {
    _id
    measurementAggregatedValues(from: $from, to: $to) {
      Vrms1 {
        min
        max
      }
      Vrms2 {
        min
        max
      }
      Vrms3 {
        min
        max
      }
      Irms1 {
        max
        mean
      }
      Irms2 {
        max
        mean
      }
      Irms3 {
        max
        mean
      }
      Realpower1 {
        min
        max
        mean
      }
      Realpower2 {
        min
        max
        mean
      }
      Realpower3 {
        min
        max
        mean
      }
    }
  }
}
    `;

/**
 * __useElectricityAggregatedValuesQuery__
 *
 * To run a query within a React component, call `useElectricityAggregatedValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useElectricityAggregatedValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useElectricityAggregatedValuesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useElectricityAggregatedValuesQuery(baseOptions: Apollo.QueryHookOptions<ElectricityAggregatedValuesQuery, ElectricityAggregatedValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ElectricityAggregatedValuesQuery, ElectricityAggregatedValuesQueryVariables>(ElectricityAggregatedValuesDocument, options);
      }
export function useElectricityAggregatedValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ElectricityAggregatedValuesQuery, ElectricityAggregatedValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ElectricityAggregatedValuesQuery, ElectricityAggregatedValuesQueryVariables>(ElectricityAggregatedValuesDocument, options);
        }
export type ElectricityAggregatedValuesQueryHookResult = ReturnType<typeof useElectricityAggregatedValuesQuery>;
export type ElectricityAggregatedValuesLazyQueryHookResult = ReturnType<typeof useElectricityAggregatedValuesLazyQuery>;
export type ElectricityAggregatedValuesQueryResult = Apollo.QueryResult<ElectricityAggregatedValuesQuery, ElectricityAggregatedValuesQueryVariables>;
export const HeatDocument = gql`
    query Heat($id: String, $from: Float!, $to: Float!, $interval: Float!) {
  meteringNodeGet(id: $id) {
    _id
    measurementThroughputList(from: $from, to: $to, interval: $interval) {
      time
      counter
    }
    measurementList(from: $from, to: $to, interval: $interval) {
      time
      counter
    }
    measurementLast {
      time
      counter
    }
    measurementAggregatedValues(from: $from, to: $to) {
      counter {
        difference
      }
    }
    unit {
      counter
    }
    devices {
      installationDate
    }
  }
}
    `;

/**
 * __useHeatQuery__
 *
 * To run a query within a React component, call `useHeatQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeatQuery({
 *   variables: {
 *      id: // value for 'id'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      interval: // value for 'interval'
 *   },
 * });
 */
export function useHeatQuery(baseOptions: Apollo.QueryHookOptions<HeatQuery, HeatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HeatQuery, HeatQueryVariables>(HeatDocument, options);
      }
export function useHeatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeatQuery, HeatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HeatQuery, HeatQueryVariables>(HeatDocument, options);
        }
export type HeatQueryHookResult = ReturnType<typeof useHeatQuery>;
export type HeatLazyQueryHookResult = ReturnType<typeof useHeatLazyQuery>;
export type HeatQueryResult = Apollo.QueryResult<HeatQuery, HeatQueryVariables>;
export const HeatCurrentThroughputDocument = gql`
    query HeatCurrentThroughput($id: String, $from: Float!, $to: Float!, $interval: Float!) {
  meteringNodeGet(id: $id) {
    _id
    measurementThroughputList(from: $from, to: $to, interval: $interval) {
      time
      counter
    }
    unit {
      counter
    }
  }
}
    `;

/**
 * __useHeatCurrentThroughputQuery__
 *
 * To run a query within a React component, call `useHeatCurrentThroughputQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeatCurrentThroughputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeatCurrentThroughputQuery({
 *   variables: {
 *      id: // value for 'id'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      interval: // value for 'interval'
 *   },
 * });
 */
export function useHeatCurrentThroughputQuery(baseOptions: Apollo.QueryHookOptions<HeatCurrentThroughputQuery, HeatCurrentThroughputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HeatCurrentThroughputQuery, HeatCurrentThroughputQueryVariables>(HeatCurrentThroughputDocument, options);
      }
export function useHeatCurrentThroughputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeatCurrentThroughputQuery, HeatCurrentThroughputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HeatCurrentThroughputQuery, HeatCurrentThroughputQueryVariables>(HeatCurrentThroughputDocument, options);
        }
export type HeatCurrentThroughputQueryHookResult = ReturnType<typeof useHeatCurrentThroughputQuery>;
export type HeatCurrentThroughputLazyQueryHookResult = ReturnType<typeof useHeatCurrentThroughputLazyQuery>;
export type HeatCurrentThroughputQueryResult = Apollo.QueryResult<HeatCurrentThroughputQuery, HeatCurrentThroughputQueryVariables>;
export const ModbusGetDocument = gql`
    query modbusGet($id: String!) {
  modbusGet(id: $id) {
    _id
    devid
    decdevid
    slope
    displace
    flow_temp_limit_min
    flow_temp_limit_max
    return_temp_limit
    priority
    manual_temp
    desired_mode
  }
}
    `;

/**
 * __useModbusGetQuery__
 *
 * To run a query within a React component, call `useModbusGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useModbusGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModbusGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useModbusGetQuery(baseOptions: Apollo.QueryHookOptions<ModbusGetQuery, ModbusGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModbusGetQuery, ModbusGetQueryVariables>(ModbusGetDocument, options);
      }
export function useModbusGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModbusGetQuery, ModbusGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModbusGetQuery, ModbusGetQueryVariables>(ModbusGetDocument, options);
        }
export type ModbusGetQueryHookResult = ReturnType<typeof useModbusGetQuery>;
export type ModbusGetLazyQueryHookResult = ReturnType<typeof useModbusGetLazyQuery>;
export type ModbusGetQueryResult = Apollo.QueryResult<ModbusGetQuery, ModbusGetQueryVariables>;
export const ModbusUpdateDocument = gql`
    mutation modbusUpdate($id: String, $devid: String, $decdevid: Float, $slope: Float, $displace: Float, $flow_temp_limit_min: Float, $flow_temp_limit_max: Float, $return_temp_limit: Float, $priority: Float, $manual_temp: Float, $desired_mode: Float) {
  modbusUpdate(
    parameters: {_id: $id, devid: $devid, decdevid: $decdevid, slope: $slope, displace: $displace, flow_temp_limit_min: $flow_temp_limit_min, flow_temp_limit_max: $flow_temp_limit_max, return_temp_limit: $return_temp_limit, priority: $priority, manual_temp: $manual_temp, desired_mode: $desired_mode}
  ) {
    _id
    slope
    displace
    flow_temp_limit_min
    flow_temp_limit_max
    return_temp_limit
    priority
    manual_temp
    desired_mode
  }
}
    `;
export type ModbusUpdateMutationFn = Apollo.MutationFunction<ModbusUpdateMutation, ModbusUpdateMutationVariables>;

/**
 * __useModbusUpdateMutation__
 *
 * To run a mutation, you first call `useModbusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModbusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modbusUpdateMutation, { data, loading, error }] = useModbusUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      devid: // value for 'devid'
 *      decdevid: // value for 'decdevid'
 *      slope: // value for 'slope'
 *      displace: // value for 'displace'
 *      flow_temp_limit_min: // value for 'flow_temp_limit_min'
 *      flow_temp_limit_max: // value for 'flow_temp_limit_max'
 *      return_temp_limit: // value for 'return_temp_limit'
 *      priority: // value for 'priority'
 *      manual_temp: // value for 'manual_temp'
 *      desired_mode: // value for 'desired_mode'
 *   },
 * });
 */
export function useModbusUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ModbusUpdateMutation, ModbusUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModbusUpdateMutation, ModbusUpdateMutationVariables>(ModbusUpdateDocument, options);
      }
export type ModbusUpdateMutationHookResult = ReturnType<typeof useModbusUpdateMutation>;
export type ModbusUpdateMutationResult = Apollo.MutationResult<ModbusUpdateMutation>;
export type ModbusUpdateMutationOptions = Apollo.BaseMutationOptions<ModbusUpdateMutation, ModbusUpdateMutationVariables>;
export const HeatingUnitControllerDocument = gql`
    query HeatingUnitController($id: String, $from: Float!, $to: Float!, $interval: Float!) {
  meteringNodeGet(id: $id) {
    _id
    currentDevice {
      _id
      currentModem {
        _id
        decdevid
      }
    }
    measurementList(from: $from, to: $to, interval: $interval) {
      time
      desired_mode
      desired_room_temp
      flow_temp_limit_max
      flow_temp_limit_min
      m1_excercise
      manual_temp
      p1_excercise
      pump_status
      return_temp_limit
      standby_temp
      valve_open
      valve_shut
      slope
      displace
      priority
    }
    unit {
      desired_mode
      desired_room_temp
      flow_temp_limit_max
      flow_temp_limit_min
      m1_excercise
      manual_temp
      p1_excercise
      pump_status
      return_temp_limit
      standby_temp
      valve_open
      valve_shut
      slope
      displace
      priority
    }
  }
}
    `;

/**
 * __useHeatingUnitControllerQuery__
 *
 * To run a query within a React component, call `useHeatingUnitControllerQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeatingUnitControllerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeatingUnitControllerQuery({
 *   variables: {
 *      id: // value for 'id'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      interval: // value for 'interval'
 *   },
 * });
 */
export function useHeatingUnitControllerQuery(baseOptions: Apollo.QueryHookOptions<HeatingUnitControllerQuery, HeatingUnitControllerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HeatingUnitControllerQuery, HeatingUnitControllerQueryVariables>(HeatingUnitControllerDocument, options);
      }
export function useHeatingUnitControllerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeatingUnitControllerQuery, HeatingUnitControllerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HeatingUnitControllerQuery, HeatingUnitControllerQueryVariables>(HeatingUnitControllerDocument, options);
        }
export type HeatingUnitControllerQueryHookResult = ReturnType<typeof useHeatingUnitControllerQuery>;
export type HeatingUnitControllerLazyQueryHookResult = ReturnType<typeof useHeatingUnitControllerLazyQuery>;
export type HeatingUnitControllerQueryResult = Apollo.QueryResult<HeatingUnitControllerQuery, HeatingUnitControllerQueryVariables>;
export const HumidityTemperatureDocument = gql`
    query HumidityTemperature($id: String, $from: Float!, $to: Float!, $interval: Float!) {
  meteringNodeGet(id: $id) {
    _id
    devices {
      installationDate
    }
    measurementList(from: $from, to: $to, interval: $interval) {
      time
      temperature
      humidity
    }
    measurementLast {
      time
      temperature
      humidity
    }
    unit {
      temperature
      humidity
    }
  }
}
    `;

/**
 * __useHumidityTemperatureQuery__
 *
 * To run a query within a React component, call `useHumidityTemperatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useHumidityTemperatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHumidityTemperatureQuery({
 *   variables: {
 *      id: // value for 'id'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      interval: // value for 'interval'
 *   },
 * });
 */
export function useHumidityTemperatureQuery(baseOptions: Apollo.QueryHookOptions<HumidityTemperatureQuery, HumidityTemperatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HumidityTemperatureQuery, HumidityTemperatureQueryVariables>(HumidityTemperatureDocument, options);
      }
export function useHumidityTemperatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HumidityTemperatureQuery, HumidityTemperatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HumidityTemperatureQuery, HumidityTemperatureQueryVariables>(HumidityTemperatureDocument, options);
        }
export type HumidityTemperatureQueryHookResult = ReturnType<typeof useHumidityTemperatureQuery>;
export type HumidityTemperatureLazyQueryHookResult = ReturnType<typeof useHumidityTemperatureLazyQuery>;
export type HumidityTemperatureQueryResult = Apollo.QueryResult<HumidityTemperatureQuery, HumidityTemperatureQueryVariables>;
export const ImpulseDocument = gql`
    query Impulse($id: String, $from: Float!, $to: Float!, $interval: Float!) {
  meteringNodeGet(id: $id) {
    _id
    measurementThroughputList(from: $from, to: $to, interval: $interval) {
      time
      counter
    }
    measurementList(from: $from, to: $to, interval: $interval) {
      time
      counter
    }
    measurementLast {
      time
      counter
    }
    measurementAggregatedValues(from: $from, to: $to) {
      counter {
        difference
      }
    }
    unit {
      counter
    }
    devices {
      installationDate
    }
  }
}
    `;

/**
 * __useImpulseQuery__
 *
 * To run a query within a React component, call `useImpulseQuery` and pass it any options that fit your needs.
 * When your component renders, `useImpulseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImpulseQuery({
 *   variables: {
 *      id: // value for 'id'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      interval: // value for 'interval'
 *   },
 * });
 */
export function useImpulseQuery(baseOptions: Apollo.QueryHookOptions<ImpulseQuery, ImpulseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImpulseQuery, ImpulseQueryVariables>(ImpulseDocument, options);
      }
export function useImpulseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImpulseQuery, ImpulseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImpulseQuery, ImpulseQueryVariables>(ImpulseDocument, options);
        }
export type ImpulseQueryHookResult = ReturnType<typeof useImpulseQuery>;
export type ImpulseLazyQueryHookResult = ReturnType<typeof useImpulseLazyQuery>;
export type ImpulseQueryResult = Apollo.QueryResult<ImpulseQuery, ImpulseQueryVariables>;
export const ImpulseCurrentThroughputDocument = gql`
    query ImpulseCurrentThroughput($id: String, $from: Float!, $to: Float!, $interval: Float!) {
  meteringNodeGet(id: $id) {
    _id
    measurementThroughputList(from: $from, to: $to, interval: $interval) {
      time
      counter
    }
    unit {
      counter
    }
  }
}
    `;

/**
 * __useImpulseCurrentThroughputQuery__
 *
 * To run a query within a React component, call `useImpulseCurrentThroughputQuery` and pass it any options that fit your needs.
 * When your component renders, `useImpulseCurrentThroughputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImpulseCurrentThroughputQuery({
 *   variables: {
 *      id: // value for 'id'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      interval: // value for 'interval'
 *   },
 * });
 */
export function useImpulseCurrentThroughputQuery(baseOptions: Apollo.QueryHookOptions<ImpulseCurrentThroughputQuery, ImpulseCurrentThroughputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImpulseCurrentThroughputQuery, ImpulseCurrentThroughputQueryVariables>(ImpulseCurrentThroughputDocument, options);
      }
export function useImpulseCurrentThroughputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImpulseCurrentThroughputQuery, ImpulseCurrentThroughputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImpulseCurrentThroughputQuery, ImpulseCurrentThroughputQueryVariables>(ImpulseCurrentThroughputDocument, options);
        }
export type ImpulseCurrentThroughputQueryHookResult = ReturnType<typeof useImpulseCurrentThroughputQuery>;
export type ImpulseCurrentThroughputLazyQueryHookResult = ReturnType<typeof useImpulseCurrentThroughputLazyQuery>;
export type ImpulseCurrentThroughputQueryResult = Apollo.QueryResult<ImpulseCurrentThroughputQuery, ImpulseCurrentThroughputQueryVariables>;
export const MbusHeatDocument = gql`
    query MbusHeat($id: String, $from: Float!, $to: Float!, $interval: Float!) {
  meteringNodeGet(id: $id) {
    _id
    devices {
      installationDate
    }
    measurementThroughputList(from: $from, to: $to, interval: $interval) {
      time
      M3
      Wh
    }
    measurementList(from: $from, to: $to, interval: $interval) {
      time
      M3
      Wh
      tdelta
      tflow
      treturn
    }
    measurementAggregatedValues(from: $from, to: $to) {
      M3 {
        min
        max
      }
      Wh {
        min
        max
      }
    }
    unit {
      M3
      Wh
      tdelta
      tflow
      treturn
    }
  }
}
    `;

/**
 * __useMbusHeatQuery__
 *
 * To run a query within a React component, call `useMbusHeatQuery` and pass it any options that fit your needs.
 * When your component renders, `useMbusHeatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMbusHeatQuery({
 *   variables: {
 *      id: // value for 'id'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      interval: // value for 'interval'
 *   },
 * });
 */
export function useMbusHeatQuery(baseOptions: Apollo.QueryHookOptions<MbusHeatQuery, MbusHeatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MbusHeatQuery, MbusHeatQueryVariables>(MbusHeatDocument, options);
      }
export function useMbusHeatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MbusHeatQuery, MbusHeatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MbusHeatQuery, MbusHeatQueryVariables>(MbusHeatDocument, options);
        }
export type MbusHeatQueryHookResult = ReturnType<typeof useMbusHeatQuery>;
export type MbusHeatLazyQueryHookResult = ReturnType<typeof useMbusHeatLazyQuery>;
export type MbusHeatQueryResult = Apollo.QueryResult<MbusHeatQuery, MbusHeatQueryVariables>;
export const MetricsLayoutDocument = gql`
    query MetricsLayout($name: String!) {
  dashboardGet(name: $name) {
    _id
    name
    layout {
      xxs {
        w
        h
        x
        y
        i
      }
      xs {
        w
        h
        x
        y
        i
      }
      sm {
        w
        h
        x
        y
        i
      }
      md {
        w
        h
        x
        y
        i
      }
      lg {
        w
        h
        x
        y
        i
      }
    }
  }
}
    `;

/**
 * __useMetricsLayoutQuery__
 *
 * To run a query within a React component, call `useMetricsLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetricsLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetricsLayoutQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useMetricsLayoutQuery(baseOptions: Apollo.QueryHookOptions<MetricsLayoutQuery, MetricsLayoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MetricsLayoutQuery, MetricsLayoutQueryVariables>(MetricsLayoutDocument, options);
      }
export function useMetricsLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MetricsLayoutQuery, MetricsLayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MetricsLayoutQuery, MetricsLayoutQueryVariables>(MetricsLayoutDocument, options);
        }
export type MetricsLayoutQueryHookResult = ReturnType<typeof useMetricsLayoutQuery>;
export type MetricsLayoutLazyQueryHookResult = ReturnType<typeof useMetricsLayoutLazyQuery>;
export type MetricsLayoutQueryResult = Apollo.QueryResult<MetricsLayoutQuery, MetricsLayoutQueryVariables>;
export const DevicesWithMeasurementsDocument = gql`
    query DevicesWithMeasurements($limit: Int!, $ids: [String!], $from: Float!, $to: Float!, $interval: Float!) {
  meteringNodeList(limit: $limit, ids: $ids) {
    result {
      _id
      category
      restart
      measurementList(from: $from, to: $to, interval: $interval) {
        time
        counter
        temperature
        humidity
        eq
        M3
        Wh
        co2
        ch4
        ppm10
        ppm25
        Irms1
        Vrms1
        bar
      }
      measurementThroughputList(from: $from, to: $to, interval: $interval) {
        time
        counter
        temperature
        humidity
        eq
        M3
        Wh
        co2
        ch4
        ppm10
        ppm25
        Irms1
        Vrms1
        bar
      }
      unit {
        counter
        temperature
        humidity
        eq
        M3
        Wh
        co2
        ch4
        ppm10
        ppm25
        Irms1
        Vrms1
        bar
      }
    }
    paging {
      offset
      limit
      total
    }
  }
}
    `;

/**
 * __useDevicesWithMeasurementsQuery__
 *
 * To run a query within a React component, call `useDevicesWithMeasurementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicesWithMeasurementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesWithMeasurementsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      ids: // value for 'ids'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      interval: // value for 'interval'
 *   },
 * });
 */
export function useDevicesWithMeasurementsQuery(baseOptions: Apollo.QueryHookOptions<DevicesWithMeasurementsQuery, DevicesWithMeasurementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DevicesWithMeasurementsQuery, DevicesWithMeasurementsQueryVariables>(DevicesWithMeasurementsDocument, options);
      }
export function useDevicesWithMeasurementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DevicesWithMeasurementsQuery, DevicesWithMeasurementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DevicesWithMeasurementsQuery, DevicesWithMeasurementsQueryVariables>(DevicesWithMeasurementsDocument, options);
        }
export type DevicesWithMeasurementsQueryHookResult = ReturnType<typeof useDevicesWithMeasurementsQuery>;
export type DevicesWithMeasurementsLazyQueryHookResult = ReturnType<typeof useDevicesWithMeasurementsLazyQuery>;
export type DevicesWithMeasurementsQueryResult = Apollo.QueryResult<DevicesWithMeasurementsQuery, DevicesWithMeasurementsQueryVariables>;
export const RsHeatDocument = gql`
    query RsHeat($id: String, $from: Float!, $to: Float!, $interval: Float!) {
  meteringNodeGet(id: $id) {
    _id
    currentDevice {
      _id
      pulseValue
    }
    measurementThroughputList(from: $from, to: $to, interval: $interval) {
      time
      eq
      ev
    }
    measurementList(from: $from, to: $to, interval: $interval) {
      time
      eq
      ev
      ht
    }
    unit {
      eq
      ev
      ht
    }
    devices {
      installationDate
    }
  }
}
    `;

/**
 * __useRsHeatQuery__
 *
 * To run a query within a React component, call `useRsHeatQuery` and pass it any options that fit your needs.
 * When your component renders, `useRsHeatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRsHeatQuery({
 *   variables: {
 *      id: // value for 'id'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      interval: // value for 'interval'
 *   },
 * });
 */
export function useRsHeatQuery(baseOptions: Apollo.QueryHookOptions<RsHeatQuery, RsHeatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RsHeatQuery, RsHeatQueryVariables>(RsHeatDocument, options);
      }
export function useRsHeatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RsHeatQuery, RsHeatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RsHeatQuery, RsHeatQueryVariables>(RsHeatDocument, options);
        }
export type RsHeatQueryHookResult = ReturnType<typeof useRsHeatQuery>;
export type RsHeatLazyQueryHookResult = ReturnType<typeof useRsHeatLazyQuery>;
export type RsHeatQueryResult = Apollo.QueryResult<RsHeatQuery, RsHeatQueryVariables>;
export const TemperatureDocument = gql`
    query Temperature($id: String, $from: Float!, $to: Float!, $interval: Float!) {
  meteringNodeGet(id: $id) {
    _id
    devices {
      installationDate
    }
    measurementList(from: $from, to: $to, interval: $interval) {
      time
      temperature
    }
    measurementLast {
      time
      temperature
    }
    unit {
      temperature
    }
  }
}
    `;

/**
 * __useTemperatureQuery__
 *
 * To run a query within a React component, call `useTemperatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemperatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemperatureQuery({
 *   variables: {
 *      id: // value for 'id'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      interval: // value for 'interval'
 *   },
 * });
 */
export function useTemperatureQuery(baseOptions: Apollo.QueryHookOptions<TemperatureQuery, TemperatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemperatureQuery, TemperatureQueryVariables>(TemperatureDocument, options);
      }
export function useTemperatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemperatureQuery, TemperatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemperatureQuery, TemperatureQueryVariables>(TemperatureDocument, options);
        }
export type TemperatureQueryHookResult = ReturnType<typeof useTemperatureQuery>;
export type TemperatureLazyQueryHookResult = ReturnType<typeof useTemperatureLazyQuery>;
export type TemperatureQueryResult = Apollo.QueryResult<TemperatureQuery, TemperatureQueryVariables>;
export const DeviceBasicInfoDocument = gql`
    query DeviceBasicInfo($id: String) {
  meteringNodeGet(id: $id) {
    _id
    name
    type
    category
    leaking
    restart
    location {
      _id
      city
      street
    }
    currentDevice {
      _id
      serial
    }
  }
}
    `;

/**
 * __useDeviceBasicInfoQuery__
 *
 * To run a query within a React component, call `useDeviceBasicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceBasicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceBasicInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeviceBasicInfoQuery(baseOptions?: Apollo.QueryHookOptions<DeviceBasicInfoQuery, DeviceBasicInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceBasicInfoQuery, DeviceBasicInfoQueryVariables>(DeviceBasicInfoDocument, options);
      }
export function useDeviceBasicInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceBasicInfoQuery, DeviceBasicInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceBasicInfoQuery, DeviceBasicInfoQueryVariables>(DeviceBasicInfoDocument, options);
        }
export type DeviceBasicInfoQueryHookResult = ReturnType<typeof useDeviceBasicInfoQuery>;
export type DeviceBasicInfoLazyQueryHookResult = ReturnType<typeof useDeviceBasicInfoLazyQuery>;
export type DeviceBasicInfoQueryResult = Apollo.QueryResult<DeviceBasicInfoQuery, DeviceBasicInfoQueryVariables>;
export const DeviceTypesDocument = gql`
    query DeviceTypes($filterByOrganisation: Boolean!) {
  deviceTypeList(filterByOrganisation: $filterByOrganisation) {
    category
  }
}
    `;

/**
 * __useDeviceTypesQuery__
 *
 * To run a query within a React component, call `useDeviceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceTypesQuery({
 *   variables: {
 *      filterByOrganisation: // value for 'filterByOrganisation'
 *   },
 * });
 */
export function useDeviceTypesQuery(baseOptions: Apollo.QueryHookOptions<DeviceTypesQuery, DeviceTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceTypesQuery, DeviceTypesQueryVariables>(DeviceTypesDocument, options);
      }
export function useDeviceTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceTypesQuery, DeviceTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceTypesQuery, DeviceTypesQueryVariables>(DeviceTypesDocument, options);
        }
export type DeviceTypesQueryHookResult = ReturnType<typeof useDeviceTypesQuery>;
export type DeviceTypesLazyQueryHookResult = ReturnType<typeof useDeviceTypesLazyQuery>;
export type DeviceTypesQueryResult = Apollo.QueryResult<DeviceTypesQuery, DeviceTypesQueryVariables>;
export const DevicesDocument = gql`
    query Devices($date: Float!, $offset: Int!, $limit: Int!, $search: String!, $category: String!, $sort: MeteringNodeListSortParameters!) {
  meteringNodeList(
    offset: $offset
    limit: $limit
    date: $date
    search: $search
    category: $category
    sort: $sort
  ) {
    result {
      _id
      name
      category
      leaking
      restart
      currentDevice {
        serial
        verificationDate
        currentModem {
          _id
          decdevid
        }
      }
      measurementLast {
        time
        rssi
        counter
        temperature
        humidity
        eq
        M3
        Wh
        co2
        ch4
        ppm10
        ppm25
        Irms1
        Vrms1
        bar
      }
      unit {
        counter
        temperature
        humidity
        eq
        M3
        Wh
        co2
        ch4
        ppm10
        ppm25
        Irms1
        Vrms1
        bar
      }
      location {
        _id
        city
        street
      }
    }
    paging {
      offset
      limit
      total
    }
  }
}
    `;

/**
 * __useDevicesQuery__
 *
 * To run a query within a React component, call `useDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesQuery({
 *   variables: {
 *      date: // value for 'date'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      category: // value for 'category'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useDevicesQuery(baseOptions: Apollo.QueryHookOptions<DevicesQuery, DevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DevicesQuery, DevicesQueryVariables>(DevicesDocument, options);
      }
export function useDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DevicesQuery, DevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DevicesQuery, DevicesQueryVariables>(DevicesDocument, options);
        }
export type DevicesQueryHookResult = ReturnType<typeof useDevicesQuery>;
export type DevicesLazyQueryHookResult = ReturnType<typeof useDevicesLazyQuery>;
export type DevicesQueryResult = Apollo.QueryResult<DevicesQuery, DevicesQueryVariables>;
export const SendToHorizonDocument = gql`
    mutation SendToHorizon {
  locationSendMeasurementsToHorizon
}
    `;
export type SendToHorizonMutationFn = Apollo.MutationFunction<SendToHorizonMutation, SendToHorizonMutationVariables>;

/**
 * __useSendToHorizonMutation__
 *
 * To run a mutation, you first call `useSendToHorizonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendToHorizonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendToHorizonMutation, { data, loading, error }] = useSendToHorizonMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendToHorizonMutation(baseOptions?: Apollo.MutationHookOptions<SendToHorizonMutation, SendToHorizonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendToHorizonMutation, SendToHorizonMutationVariables>(SendToHorizonDocument, options);
      }
export type SendToHorizonMutationHookResult = ReturnType<typeof useSendToHorizonMutation>;
export type SendToHorizonMutationResult = Apollo.MutationResult<SendToHorizonMutation>;
export type SendToHorizonMutationOptions = Apollo.BaseMutationOptions<SendToHorizonMutation, SendToHorizonMutationVariables>;
export const GroupDocument = gql`
    query Group($limit: Int!, $from: Float!, $to: Float!, $interval: Float!, $tag: String) {
  meteringNodeList(limit: $limit, tag: $tag) {
    result {
      _id
      name
      category
      leaking
      restart
      currentDevice {
        serial
      }
      location {
        _id
        city
        street
      }
      measurementList(from: $from, to: $to, interval: $interval) {
        time
        counter
        bar
      }
      measurementThroughputList(from: $from, to: $to, interval: $interval) {
        time
        counter
        bar
      }
      measurementLast {
        time
        counter
        bar
      }
      unit {
        counter
        bar
      }
    }
    paging {
      offset
      limit
      total
    }
  }
}
    `;

/**
 * __useGroupQuery__
 *
 * To run a query within a React component, call `useGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      interval: // value for 'interval'
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useGroupQuery(baseOptions: Apollo.QueryHookOptions<GroupQuery, GroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupQuery, GroupQueryVariables>(GroupDocument, options);
      }
export function useGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupQuery, GroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupQuery, GroupQueryVariables>(GroupDocument, options);
        }
export type GroupQueryHookResult = ReturnType<typeof useGroupQuery>;
export type GroupLazyQueryHookResult = ReturnType<typeof useGroupLazyQuery>;
export type GroupQueryResult = Apollo.QueryResult<GroupQuery, GroupQueryVariables>;
export const GroupMeteringNodesDocument = gql`
    query GroupMeteringNodes($offset: Int!, $limit: Int!, $tag: String!) {
  meteringNodeList(offset: $offset, limit: $limit, tag: $tag) {
    result {
      _id
      name
      category
      currentDevice {
        _id
        serial
      }
      location {
        _id
        city
        street
      }
    }
    paging {
      offset
      limit
      total
    }
  }
}
    `;

/**
 * __useGroupMeteringNodesQuery__
 *
 * To run a query within a React component, call `useGroupMeteringNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupMeteringNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupMeteringNodesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useGroupMeteringNodesQuery(baseOptions: Apollo.QueryHookOptions<GroupMeteringNodesQuery, GroupMeteringNodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupMeteringNodesQuery, GroupMeteringNodesQueryVariables>(GroupMeteringNodesDocument, options);
      }
export function useGroupMeteringNodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupMeteringNodesQuery, GroupMeteringNodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupMeteringNodesQuery, GroupMeteringNodesQueryVariables>(GroupMeteringNodesDocument, options);
        }
export type GroupMeteringNodesQueryHookResult = ReturnType<typeof useGroupMeteringNodesQuery>;
export type GroupMeteringNodesLazyQueryHookResult = ReturnType<typeof useGroupMeteringNodesLazyQuery>;
export type GroupMeteringNodesQueryResult = Apollo.QueryResult<GroupMeteringNodesQuery, GroupMeteringNodesQueryVariables>;
export const ListTagsDocument = gql`
    query ListTags {
  tagList
}
    `;

/**
 * __useListTagsQuery__
 *
 * To run a query within a React component, call `useListTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListTagsQuery(baseOptions?: Apollo.QueryHookOptions<ListTagsQuery, ListTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTagsQuery, ListTagsQueryVariables>(ListTagsDocument, options);
      }
export function useListTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTagsQuery, ListTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTagsQuery, ListTagsQueryVariables>(ListTagsDocument, options);
        }
export type ListTagsQueryHookResult = ReturnType<typeof useListTagsQuery>;
export type ListTagsLazyQueryHookResult = ReturnType<typeof useListTagsLazyQuery>;
export type ListTagsQueryResult = Apollo.QueryResult<ListTagsQuery, ListTagsQueryVariables>;
export const GetCurrentPulsesDocument = gql`
    query GetCurrentPulses($decdevid: String!, $port: Float!) {
  currentPulsesGet(decdevid: $decdevid, port: $port) {
    time
    counter
  }
}
    `;

/**
 * __useGetCurrentPulsesQuery__
 *
 * To run a query within a React component, call `useGetCurrentPulsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentPulsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentPulsesQuery({
 *   variables: {
 *      decdevid: // value for 'decdevid'
 *      port: // value for 'port'
 *   },
 * });
 */
export function useGetCurrentPulsesQuery(baseOptions: Apollo.QueryHookOptions<GetCurrentPulsesQuery, GetCurrentPulsesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentPulsesQuery, GetCurrentPulsesQueryVariables>(GetCurrentPulsesDocument, options);
      }
export function useGetCurrentPulsesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentPulsesQuery, GetCurrentPulsesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentPulsesQuery, GetCurrentPulsesQueryVariables>(GetCurrentPulsesDocument, options);
        }
export type GetCurrentPulsesQueryHookResult = ReturnType<typeof useGetCurrentPulsesQuery>;
export type GetCurrentPulsesLazyQueryHookResult = ReturnType<typeof useGetCurrentPulsesLazyQuery>;
export type GetCurrentPulsesQueryResult = Apollo.QueryResult<GetCurrentPulsesQuery, GetCurrentPulsesQueryVariables>;
export const LocationCreateDocument = gql`
    mutation locationCreate($country: String!, $city: String!, $street: String!, $latitude: Float, $longitude: Float, $externalId: String, $meteringNodes: [MeteringNodeParameters!]) {
  locationCreate(
    parameters: {country: $country, city: $city, street: $street, latitude: $latitude, longitude: $longitude, externalId: $externalId, meteringNodes: $meteringNodes}
  ) {
    _id
    country
    city
    street
    latitude
    longitude
  }
}
    `;
export type LocationCreateMutationFn = Apollo.MutationFunction<LocationCreateMutation, LocationCreateMutationVariables>;

/**
 * __useLocationCreateMutation__
 *
 * To run a mutation, you first call `useLocationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationCreateMutation, { data, loading, error }] = useLocationCreateMutation({
 *   variables: {
 *      country: // value for 'country'
 *      city: // value for 'city'
 *      street: // value for 'street'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      externalId: // value for 'externalId'
 *      meteringNodes: // value for 'meteringNodes'
 *   },
 * });
 */
export function useLocationCreateMutation(baseOptions?: Apollo.MutationHookOptions<LocationCreateMutation, LocationCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LocationCreateMutation, LocationCreateMutationVariables>(LocationCreateDocument, options);
      }
export type LocationCreateMutationHookResult = ReturnType<typeof useLocationCreateMutation>;
export type LocationCreateMutationResult = Apollo.MutationResult<LocationCreateMutation>;
export type LocationCreateMutationOptions = Apollo.BaseMutationOptions<LocationCreateMutation, LocationCreateMutationVariables>;
export const LocationGetDocument = gql`
    query LocationGet($id: String) {
  locationGet(id: $id) {
    _id
    country
    city
    street
    latitude
    longitude
    externalId
    meteringNodes {
      _id
      name
      type
      tags
      devices {
        _id
        serial
        initialMeasurement
        pulseValue
        installationDate
        externalId
        dismantlingDate
        verificationDate
        modems {
          _id
          decdevid
          initialPulses
          port
          installationDate
          dismantlingDate
        }
      }
    }
  }
}
    `;

/**
 * __useLocationGetQuery__
 *
 * To run a query within a React component, call `useLocationGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLocationGetQuery(baseOptions?: Apollo.QueryHookOptions<LocationGetQuery, LocationGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationGetQuery, LocationGetQueryVariables>(LocationGetDocument, options);
      }
export function useLocationGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationGetQuery, LocationGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationGetQuery, LocationGetQueryVariables>(LocationGetDocument, options);
        }
export type LocationGetQueryHookResult = ReturnType<typeof useLocationGetQuery>;
export type LocationGetLazyQueryHookResult = ReturnType<typeof useLocationGetLazyQuery>;
export type LocationGetQueryResult = Apollo.QueryResult<LocationGetQuery, LocationGetQueryVariables>;
export const LocationUpdateDocument = gql`
    mutation locationUpdate($id: String!, $country: String!, $city: String!, $street: String!, $latitude: Float, $longitude: Float, $externalId: String, $meteringNodes: [MeteringNodeParameters!]) {
  locationUpdate(
    parameters: {_id: $id, country: $country, city: $city, street: $street, latitude: $latitude, longitude: $longitude, externalId: $externalId, meteringNodes: $meteringNodes}
  ) {
    _id
    country
    city
    street
    latitude
    longitude
  }
}
    `;
export type LocationUpdateMutationFn = Apollo.MutationFunction<LocationUpdateMutation, LocationUpdateMutationVariables>;

/**
 * __useLocationUpdateMutation__
 *
 * To run a mutation, you first call `useLocationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationUpdateMutation, { data, loading, error }] = useLocationUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      country: // value for 'country'
 *      city: // value for 'city'
 *      street: // value for 'street'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      externalId: // value for 'externalId'
 *      meteringNodes: // value for 'meteringNodes'
 *   },
 * });
 */
export function useLocationUpdateMutation(baseOptions?: Apollo.MutationHookOptions<LocationUpdateMutation, LocationUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LocationUpdateMutation, LocationUpdateMutationVariables>(LocationUpdateDocument, options);
      }
export type LocationUpdateMutationHookResult = ReturnType<typeof useLocationUpdateMutation>;
export type LocationUpdateMutationResult = Apollo.MutationResult<LocationUpdateMutation>;
export type LocationUpdateMutationOptions = Apollo.BaseMutationOptions<LocationUpdateMutation, LocationUpdateMutationVariables>;
export const DeleteLocationDocument = gql`
    mutation DeleteLocation($id: String!) {
  locationDelete(id: $id)
}
    `;
export type DeleteLocationMutationFn = Apollo.MutationFunction<DeleteLocationMutation, DeleteLocationMutationVariables>;

/**
 * __useDeleteLocationMutation__
 *
 * To run a mutation, you first call `useDeleteLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationMutation, { data, loading, error }] = useDeleteLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLocationMutation, DeleteLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLocationMutation, DeleteLocationMutationVariables>(DeleteLocationDocument, options);
      }
export type DeleteLocationMutationHookResult = ReturnType<typeof useDeleteLocationMutation>;
export type DeleteLocationMutationResult = Apollo.MutationResult<DeleteLocationMutation>;
export type DeleteLocationMutationOptions = Apollo.BaseMutationOptions<DeleteLocationMutation, DeleteLocationMutationVariables>;
export const LocationImportFromHorizonDocument = gql`
    mutation locationImportFromHorizon($controlCounters: Boolean!, $counters: Boolean!, $locations: Boolean!) {
  locationImportFromHorizon(
    controlCounters: $controlCounters
    counters: $counters
    locations: $locations
  )
}
    `;
export type LocationImportFromHorizonMutationFn = Apollo.MutationFunction<LocationImportFromHorizonMutation, LocationImportFromHorizonMutationVariables>;

/**
 * __useLocationImportFromHorizonMutation__
 *
 * To run a mutation, you first call `useLocationImportFromHorizonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationImportFromHorizonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationImportFromHorizonMutation, { data, loading, error }] = useLocationImportFromHorizonMutation({
 *   variables: {
 *      controlCounters: // value for 'controlCounters'
 *      counters: // value for 'counters'
 *      locations: // value for 'locations'
 *   },
 * });
 */
export function useLocationImportFromHorizonMutation(baseOptions?: Apollo.MutationHookOptions<LocationImportFromHorizonMutation, LocationImportFromHorizonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LocationImportFromHorizonMutation, LocationImportFromHorizonMutationVariables>(LocationImportFromHorizonDocument, options);
      }
export type LocationImportFromHorizonMutationHookResult = ReturnType<typeof useLocationImportFromHorizonMutation>;
export type LocationImportFromHorizonMutationResult = Apollo.MutationResult<LocationImportFromHorizonMutation>;
export type LocationImportFromHorizonMutationOptions = Apollo.BaseMutationOptions<LocationImportFromHorizonMutation, LocationImportFromHorizonMutationVariables>;
export const ListLocationDocument = gql`
    query ListLocation($offset: Int!, $limit: Int!, $search: String!) {
  locationList(offset: $offset, limit: $limit, search: $search) {
    result {
      _id
      country
      city
      street
      latitude
      longitude
    }
    paging {
      offset
      limit
      total
    }
  }
}
    `;

/**
 * __useListLocationQuery__
 *
 * To run a query within a React component, call `useListLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLocationQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useListLocationQuery(baseOptions: Apollo.QueryHookOptions<ListLocationQuery, ListLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListLocationQuery, ListLocationQueryVariables>(ListLocationDocument, options);
      }
export function useListLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListLocationQuery, ListLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListLocationQuery, ListLocationQueryVariables>(ListLocationDocument, options);
        }
export type ListLocationQueryHookResult = ReturnType<typeof useListLocationQuery>;
export type ListLocationLazyQueryHookResult = ReturnType<typeof useListLocationLazyQuery>;
export type ListLocationQueryResult = Apollo.QueryResult<ListLocationQuery, ListLocationQueryVariables>;
export const UserOrganisationDocument = gql`
    query userOrganisation {
  userCurrent {
    _id
    organisation {
      _id
      name
    }
  }
}
    `;

/**
 * __useUserOrganisationQuery__
 *
 * To run a query within a React component, call `useUserOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOrganisationQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserOrganisationQuery(baseOptions?: Apollo.QueryHookOptions<UserOrganisationQuery, UserOrganisationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserOrganisationQuery, UserOrganisationQueryVariables>(UserOrganisationDocument, options);
      }
export function useUserOrganisationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserOrganisationQuery, UserOrganisationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserOrganisationQuery, UserOrganisationQueryVariables>(UserOrganisationDocument, options);
        }
export type UserOrganisationQueryHookResult = ReturnType<typeof useUserOrganisationQuery>;
export type UserOrganisationLazyQueryHookResult = ReturnType<typeof useUserOrganisationLazyQuery>;
export type UserOrganisationQueryResult = Apollo.QueryResult<UserOrganisationQuery, UserOrganisationQueryVariables>;
export const LogDocument = gql`
    query Log($offset: Int!, $limit: Int!) {
  logList(offset: $offset, limit: $limit) {
    result {
      _id
      type
      kind
      key
      lhs
      rhs
      address
      name
      serial
      decdevid
      date
    }
    paging {
      offset
      limit
      total
    }
  }
}
    `;

/**
 * __useLogQuery__
 *
 * To run a query within a React component, call `useLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useLogQuery(baseOptions: Apollo.QueryHookOptions<LogQuery, LogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LogQuery, LogQueryVariables>(LogDocument, options);
      }
export function useLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LogQuery, LogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LogQuery, LogQueryVariables>(LogDocument, options);
        }
export type LogQueryHookResult = ReturnType<typeof useLogQuery>;
export type LogLazyQueryHookResult = ReturnType<typeof useLogLazyQuery>;
export type LogQueryResult = Apollo.QueryResult<LogQuery, LogQueryVariables>;
export const LogApiDataAccessDocument = gql`
    query LogApiDataAccess($offset: Int!, $limit: Int!) {
  logApiDataAccessList(offset: $offset, limit: $limit) {
    result {
      _id
      url
      data
      createdAt
    }
    paging {
      offset
      limit
      total
    }
  }
}
    `;

/**
 * __useLogApiDataAccessQuery__
 *
 * To run a query within a React component, call `useLogApiDataAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogApiDataAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogApiDataAccessQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useLogApiDataAccessQuery(baseOptions: Apollo.QueryHookOptions<LogApiDataAccessQuery, LogApiDataAccessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LogApiDataAccessQuery, LogApiDataAccessQueryVariables>(LogApiDataAccessDocument, options);
      }
export function useLogApiDataAccessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LogApiDataAccessQuery, LogApiDataAccessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LogApiDataAccessQuery, LogApiDataAccessQueryVariables>(LogApiDataAccessDocument, options);
        }
export type LogApiDataAccessQueryHookResult = ReturnType<typeof useLogApiDataAccessQuery>;
export type LogApiDataAccessLazyQueryHookResult = ReturnType<typeof useLogApiDataAccessLazyQuery>;
export type LogApiDataAccessQueryResult = Apollo.QueryResult<LogApiDataAccessQuery, LogApiDataAccessQueryVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    lastName
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const SearchTabsDocument = gql`
    query searchTabs($search: String!) {
  meteringNodeList(search: $search) {
    paging {
      total
    }
  }
  baseStationList(search: $search) {
    paging {
      total
    }
  }
  locationList(search: $search) {
    paging {
      total
    }
  }
  userList(search: $search) {
    paging {
      total
    }
  }
}
    `;

/**
 * __useSearchTabsQuery__
 *
 * To run a query within a React component, call `useSearchTabsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTabsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTabsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchTabsQuery(baseOptions: Apollo.QueryHookOptions<SearchTabsQuery, SearchTabsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchTabsQuery, SearchTabsQueryVariables>(SearchTabsDocument, options);
      }
export function useSearchTabsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchTabsQuery, SearchTabsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchTabsQuery, SearchTabsQueryVariables>(SearchTabsDocument, options);
        }
export type SearchTabsQueryHookResult = ReturnType<typeof useSearchTabsQuery>;
export type SearchTabsLazyQueryHookResult = ReturnType<typeof useSearchTabsLazyQuery>;
export type SearchTabsQueryResult = Apollo.QueryResult<SearchTabsQuery, SearchTabsQueryVariables>;
export const CurrentUserDocument = gql`
    query currentUser {
  userCurrent {
    _id
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const LoginToHorizonDocument = gql`
    mutation LoginToHorizon($id: String!, $horizonUsername: String!, $horizonPassword: String!, $horizonClientKey: String!, $horizonDataSourceId: String!, $horizonMeasurementReportingType: String!) {
  userUpdate(
    parameters: {_id: $id, horizonUsername: $horizonUsername, horizonPassword: $horizonPassword, horizonClientKey: $horizonClientKey, horizonDataSourceId: $horizonDataSourceId, horizonMeasurementReportingType: $horizonMeasurementReportingType}
  ) {
    _id
    horizonUsername
    horizonClientKey
    horizonDataSourceId
    horizonMeasurementReportingType
  }
}
    `;
export type LoginToHorizonMutationFn = Apollo.MutationFunction<LoginToHorizonMutation, LoginToHorizonMutationVariables>;

/**
 * __useLoginToHorizonMutation__
 *
 * To run a mutation, you first call `useLoginToHorizonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginToHorizonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginToHorizonMutation, { data, loading, error }] = useLoginToHorizonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      horizonUsername: // value for 'horizonUsername'
 *      horizonPassword: // value for 'horizonPassword'
 *      horizonClientKey: // value for 'horizonClientKey'
 *      horizonDataSourceId: // value for 'horizonDataSourceId'
 *      horizonMeasurementReportingType: // value for 'horizonMeasurementReportingType'
 *   },
 * });
 */
export function useLoginToHorizonMutation(baseOptions?: Apollo.MutationHookOptions<LoginToHorizonMutation, LoginToHorizonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginToHorizonMutation, LoginToHorizonMutationVariables>(LoginToHorizonDocument, options);
      }
export type LoginToHorizonMutationHookResult = ReturnType<typeof useLoginToHorizonMutation>;
export type LoginToHorizonMutationResult = Apollo.MutationResult<LoginToHorizonMutation>;
export type LoginToHorizonMutationOptions = Apollo.BaseMutationOptions<LoginToHorizonMutation, LoginToHorizonMutationVariables>;
export const GetApiTokenDocument = gql`
    query getApiToken {
  apiTokenGet {
    token
  }
}
    `;

/**
 * __useGetApiTokenQuery__
 *
 * To run a query within a React component, call `useGetApiTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApiTokenQuery(baseOptions?: Apollo.QueryHookOptions<GetApiTokenQuery, GetApiTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApiTokenQuery, GetApiTokenQueryVariables>(GetApiTokenDocument, options);
      }
export function useGetApiTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApiTokenQuery, GetApiTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApiTokenQuery, GetApiTokenQueryVariables>(GetApiTokenDocument, options);
        }
export type GetApiTokenQueryHookResult = ReturnType<typeof useGetApiTokenQuery>;
export type GetApiTokenLazyQueryHookResult = ReturnType<typeof useGetApiTokenLazyQuery>;
export type GetApiTokenQueryResult = Apollo.QueryResult<GetApiTokenQuery, GetApiTokenQueryVariables>;
export const AddUserDocument = gql`
    mutation AddUser($firstName: String!, $lastName: String!, $email: String!, $password: String!, $language: String!, $allowedLocations: [String!], $admin: Boolean!, $devicesView: Boolean!, $devicesEdit: Boolean!, $baseStationsView: Boolean!, $baseStationsEdit: Boolean!, $locationsView: Boolean!, $locationsEdit: Boolean!, $logView: Boolean!) {
  userCreate(
    parameters: {firstName: $firstName, lastName: $lastName, email: $email, password: $password, language: $language, allowedLocations: $allowedLocations, admin: $admin, devicesView: $devicesView, devicesEdit: $devicesEdit, baseStationsView: $baseStationsView, baseStationsEdit: $baseStationsEdit, locationsView: $locationsView, locationsEdit: $locationsEdit, logView: $logView}
  ) {
    _id
    firstName
    lastName
    email
    allowedLocations
    admin
    devicesView
    devicesEdit
    baseStationsView
    baseStationsEdit
    locationsView
    locationsEdit
    logView
  }
}
    `;
export type AddUserMutationFn = Apollo.MutationFunction<AddUserMutation, AddUserMutationVariables>;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      language: // value for 'language'
 *      allowedLocations: // value for 'allowedLocations'
 *      admin: // value for 'admin'
 *      devicesView: // value for 'devicesView'
 *      devicesEdit: // value for 'devicesEdit'
 *      baseStationsView: // value for 'baseStationsView'
 *      baseStationsEdit: // value for 'baseStationsEdit'
 *      locationsView: // value for 'locationsView'
 *      locationsEdit: // value for 'locationsEdit'
 *      logView: // value for 'logView'
 *   },
 * });
 */
export function useAddUserMutation(baseOptions?: Apollo.MutationHookOptions<AddUserMutation, AddUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument, options);
      }
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = Apollo.MutationResult<AddUserMutation>;
export type AddUserMutationOptions = Apollo.BaseMutationOptions<AddUserMutation, AddUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: String!) {
  userDelete(id: $id)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const EditUserDocument = gql`
    mutation EditUser($id: String!, $firstName: String!, $lastName: String!, $email: String!, $password: String, $allowedLocations: [String!], $admin: Boolean!, $devicesView: Boolean!, $devicesEdit: Boolean!, $baseStationsView: Boolean!, $baseStationsEdit: Boolean!, $locationsView: Boolean!, $locationsEdit: Boolean!, $logView: Boolean!) {
  userUpdate(
    parameters: {_id: $id, firstName: $firstName, lastName: $lastName, email: $email, password: $password, allowedLocations: $allowedLocations, admin: $admin, devicesView: $devicesView, devicesEdit: $devicesEdit, baseStationsView: $baseStationsView, baseStationsEdit: $baseStationsEdit, locationsView: $locationsView, locationsEdit: $locationsEdit, logView: $logView}
  ) {
    _id
    firstName
    lastName
    email
    allowedLocations
    admin
    devicesView
    devicesEdit
    baseStationsView
    baseStationsEdit
    locationsView
    locationsEdit
    logView
  }
}
    `;
export type EditUserMutationFn = Apollo.MutationFunction<EditUserMutation, EditUserMutationVariables>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      allowedLocations: // value for 'allowedLocations'
 *      admin: // value for 'admin'
 *      devicesView: // value for 'devicesView'
 *      devicesEdit: // value for 'devicesEdit'
 *      baseStationsView: // value for 'baseStationsView'
 *      baseStationsEdit: // value for 'baseStationsEdit'
 *      locationsView: // value for 'locationsView'
 *      locationsEdit: // value for 'locationsEdit'
 *      logView: // value for 'logView'
 *   },
 * });
 */
export function useEditUserMutation(baseOptions?: Apollo.MutationHookOptions<EditUserMutation, EditUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditUserMutation, EditUserMutationVariables>(EditUserDocument, options);
      }
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<EditUserMutation, EditUserMutationVariables>;
export const ListUserDocument = gql`
    query ListUser($offset: Int!, $limit: Int!, $search: String!) {
  userList(offset: $offset, limit: $limit, search: $search) {
    result {
      _id
      firstName
      lastName
      email
      admin
      devicesView
      devicesEdit
      baseStationsView
      baseStationsEdit
      locationsView
      locationsEdit
      logView
    }
    paging {
      offset
      limit
      total
    }
  }
}
    `;

/**
 * __useListUserQuery__
 *
 * To run a query within a React component, call `useListUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useListUserQuery(baseOptions: Apollo.QueryHookOptions<ListUserQuery, ListUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUserQuery, ListUserQueryVariables>(ListUserDocument, options);
      }
export function useListUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUserQuery, ListUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUserQuery, ListUserQueryVariables>(ListUserDocument, options);
        }
export type ListUserQueryHookResult = ReturnType<typeof useListUserQuery>;
export type ListUserLazyQueryHookResult = ReturnType<typeof useListUserLazyQuery>;
export type ListUserQueryResult = Apollo.QueryResult<ListUserQuery, ListUserQueryVariables>;