import type { ReactNode } from 'react';
import React from 'react';
import type { PopoverContentProps } from '@radix-ui/react-popover';
import { Portal, Content } from '@radix-ui/react-popover';

import styles from './Popover.module.scss';

type PopoverProps = {
  side?: PopoverContentProps['side'];
  align?: PopoverContentProps['align'];
  children: ReactNode;
};

export const Popover = ({
  children,
  side = 'bottom',
  align = 'start',
}: PopoverProps) => (
  <Portal>
    <Content side={side} align={align} sideOffset={4} className={styles.root}>
      {children}
    </Content>
  </Portal>
);
