import React from 'react';

import styles from './Icon.module.scss';

interface Props {
  size?: number;
}

export const ChevronDown = ({ size }: Props) => (
  <svg
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.root}
    style={!size ? {} : { width: size, height: size }}
  >
    <path
      d="M5.00879 8.31165L10.0092 13.3121L15.0096 8.31165"
      stroke="currentColor"
      strokeWidth="1.66678"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
