import React from 'react';

import styles from './Icon.module.scss';

interface Props {
  size?: number;
}

export const Database = ({ size }: Props) => (
  <svg
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.root}
    style={!size ? {} : { width: size, height: size }}
  >
    <path
      d="M17.849 10.1877C17.849 11.5711 14.5155 12.6879 10.3485 12.6879C6.18149 12.6879 2.8479 11.5711 2.8479 10.1877"
      stroke="currentColor"
      strokeWidth="1.66678"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3485 6.85449C14.4909 6.85449 17.849 5.73512 17.849 4.3543C17.849 2.97348 14.4909 1.85411 10.3485 1.85411C6.20602 1.85411 2.8479 2.97348 2.8479 4.3543C2.8479 5.73512 6.20602 6.85449 10.3485 6.85449Z"
      stroke="currentColor"
      strokeWidth="1.66678"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.8479 4.35391V16.0215C2.8479 17.4049 6.18149 18.5217 10.3485 18.5217C14.5155 18.5217 17.849 17.4049 17.849 16.0215V4.35391"
      stroke="currentColor"
      strokeWidth="1.66678"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
