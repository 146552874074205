import React from 'react';

import styles from './Loading.module.scss';

interface Props {
  size?: number;
  color?: string;
  ariaLabel?: string;
}

export const Loading = ({ size = 16, color = '#8d8d8d', ariaLabel }: Props) => (
  <div style={{ fontSize: `${size}px` }} aria-label={ariaLabel}>
    <div className={styles.root}>
      <div className={styles.part} style={{ borderTopColor: color }} />
      <div className={styles.part} style={{ borderTopColor: color }} />
      <div className={styles.part} style={{ borderTopColor: color }} />
      <div className={styles.part} style={{ borderTopColor: color }} />
    </div>
  </div>
);
