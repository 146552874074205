import { create } from 'zustand';

type UserMenuState = {
  opened: boolean;
  close: () => void;
  toggle: () => void;
};

const useStore = create<UserMenuState>()((set) => ({
  opened: false,
  close: () => set(() => ({ opened: false })),
  toggle: () => set((state) => ({ opened: !state.opened })),
}));

export const useUserMenu = () => {
  return useStore((state) => state);
};
