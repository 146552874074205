import React from 'react';

import styles from './Icon.module.scss';

interface Props {
  size?: number;
}

export const Station = ({ size }: Props) => (
  <svg
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.root}
    style={!size ? {} : { width: size, height: size }}
  >
    <path
      d="M13 18.3336L7 18.3336L9 10L11 10L13 18.3336Z"
      stroke="currentColor"
      strokeWidth="1.66678"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 11C11.1046 11 12 10.1046 12 9C12 7.89543 11.1046 7 10 7C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11Z"
      fill="currentColor"
    />
    <path
      d="M4.10742 4.10779C5.67029 2.5454 7.7897 1.6677 9.99959 1.6677C12.2095 1.6677 14.3289 2.5454 15.8918 4.10779M7.04934 6C7.83077 5.2188 8.89048 4.77995 9.99543 4.77995C11.1004 4.77995 12.1601 5.2188 12.9415 6"
      stroke="currentColor"
      strokeWidth="1.66678"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
