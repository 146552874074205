import React from 'react';

import styles from './Icon.module.scss';

interface Props {
  size?: number;
}

export const MapPin = ({ size }: Props) => (
  <svg
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.root}
    style={!size ? {} : { width: size, height: size }}
  >
    <g clipPath="url(#clip0_304_17148)">
      <path
        d="M17.8126 8.84231C17.8126 14.6761 10.3119 19.6766 10.3119 19.6766C10.3119 19.6766 2.81128 14.6761 2.81128 8.84231C2.81128 6.85301 3.60152 4.94519 5.00817 3.53855C6.41481 2.13191 8.32263 1.34166 10.3119 1.34166C12.3012 1.34166 14.209 2.13191 15.6157 3.53855C17.0223 4.94519 17.8126 6.85301 17.8126 8.84231Z"
        stroke="currentColor"
        strokeWidth="1.66678"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.312 11.343C11.693 11.343 12.8125 10.2235 12.8125 8.8425C12.8125 7.46151 11.693 6.342 10.312 6.342C8.93104 6.342 7.81152 7.46151 7.81152 8.8425C7.81152 10.2235 8.93104 11.343 10.312 11.343Z"
        stroke="currentColor"
        strokeWidth="1.66678"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_304_17148">
        <rect
          width="20.0017"
          height="20.0017"
          fill="white"
          transform="translate(0.311035 0.508301)"
        />
      </clipPath>
    </defs>
  </svg>
);
