import React, { useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import {
  useUserMenuQuery,
  useSwitchOrganisationMutation,
} from 'generated/graphql';
import { useLogOut } from 'queries/user/useLogOut';
import { useAuthentication } from 'store/authentication';
import { Routes } from 'constants/routes';
import { getApolloContext } from '@apollo/client';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'ui-atoms/Button';
import { useUserMenu } from 'store/userMenu';
import { makeToast } from 'ui-atoms/Toast';
import { Check } from 'ui-atoms/Icons/Check';
import { usePermissions } from 'queries/user/usePermissions';
import styles from './Menu.module.scss';
import { Ball } from '../Ball';
import { Language } from '../Language';

const useLogout = () => {
  const authentication = useAuthentication();
  const userMenu = useUserMenu();
  const apolloContext = useContext(getApolloContext());
  const queryCache = useQueryClient();
  const navigate = useNavigate();
  const { mutate: logOut } = useLogOut({
    onSuccess: () => {
      authentication.refuse();
      userMenu.close();
      navigate(Routes.login);
      queryCache.clear();
      apolloContext.client?.resetStore();
    },
  });

  return logOut;
};

export const Menu = () => {
  const { data } = useUserMenuQuery();

  const { t } = useTranslation();

  const permissions = usePermissions();

  const [switchOrganisation] = useSwitchOrganisationMutation({
    onCompleted: () => {
      sessionStorage.clear();
      window.location.reload();
    },
    onError: (error) => makeToast.error(error.message),
  });

  const logOut = useLogout();

  const user = data?.userCurrent;

  return !user ? null : (
    <div className={styles.root}>
      <div className={styles.info}>
        <div className={styles.ball}>
          <Ball large />
        </div>
        <Language />
        {permissions.user.view && (
          <Link to={`/users/${user._id}/`} className={styles.name}>
            {t('Profile settings')}
          </Link>
        )}
        {user.organisations.length === 1 && (
          <p className={styles.text}>
            <span className={styles.name}>
              {user.firstName} {user.lastName}
            </span>
            <span className={styles.extraInfo}>{user.organisation.name}</span>
          </p>
        )}
        {user.organisations.length > 1 && (
          <>
            <p className={styles.text}>
              <span className={styles.name}>
                {user.firstName} {user.lastName}
              </span>
              <span className={styles.extraInfo}>{user.email}</span>
            </p>
            <ul className={styles.organisations}>
              {user.organisations.map((item) => {
                if (item._id === user.organisation._id) {
                  return (
                    <li key={user.organisation._id}>
                      <button
                        type="button"
                        className={classNames(
                          styles.organisation,
                          styles.active,
                        )}
                        onClick={() => {
                          switchOrganisation({
                            variables: {
                              id: user._id,
                              organisationId: item._id,
                            },
                          });
                        }}
                      >
                        <span className={styles.organisationInitial}>
                          {item.name[0].toUpperCase()}
                        </span>
                        <span className={styles.organisationName}>
                          {item.name}
                        </span>
                        <Check size={20} />
                      </button>
                    </li>
                  );
                }

                return (
                  <li key={item._id}>
                    <button
                      type="button"
                      className={styles.organisation}
                      onClick={() => {
                        switchOrganisation({
                          variables: {
                            id: user._id,
                            organisationId: item._id,
                          },
                        });
                      }}
                    >
                      <span className={styles.organisationInitial}>
                        {item.name[0].toUpperCase()}
                      </span>
                      <span className={styles.organisationName}>
                        {item.name}
                      </span>
                    </button>
                  </li>
                );
              })}
            </ul>
          </>
        )}
      </div>
      <Button type="button" theme="secondary" onClick={() => logOut()}>
        {t('Logout')}
      </Button>
    </div>
  );
};
