import type { ReactNode } from 'react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Routes } from 'constants/routes';

import { useWindowSize } from 'hooks/useWindowSize';

import { useNavigation } from 'store/navigation';

import { Counter } from 'ui-atoms/Icons/Counter';
import { Station } from 'ui-atoms/Icons/Station';
import { MapPin } from 'ui-atoms/Icons/MapPin';
import { Users } from 'ui-atoms/Icons/Users';
import { Bell } from 'ui-atoms/Icons/Bell';
import { Database } from 'ui-atoms/Icons/Database';
import { Settings } from 'ui-atoms/Icons/Settings';
import { Package } from 'ui-atoms/Icons/Package';

import { usePermissions } from 'queries/user/usePermissions';

import styles from './Navigation.module.scss';

interface LinkProps {
  to: string;
  name: string;
  icon: ReactNode;
  end?: boolean;
}

const Link = ({ to, name, icon, end = false }: LinkProps) => {
  const navigation = useNavigation();

  const { windowWidth } = useWindowSize();

  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames(styles.link, { [styles.active]: isActive })
      }
      end={end}
      onClick={() => {
        if (windowWidth <= 1200) {
          navigation.close();
        }
      }}
    >
      <span className={styles.icon}>{icon}</span>
      <span>{name}</span>
    </NavLink>
  );
};

export const Navigation = () => {
  const { t } = useTranslation();
  const permissions = usePermissions();

  return (
    <div className={styles.root}>
      <ul className={styles.list}>
        <li>
          <Link
            to={Routes.home}
            name={t('Counters')}
            icon={<Counter size={20} />}
            end
          />
        </li>
        <li>
          <Link
            to={Routes.groups}
            name={t('Groups')}
            icon={<Package size={20} />}
          />
        </li>
        <li>
          <Link
            to={Routes.alerts}
            name={t('Alerts')}
            icon={<Bell size={20} />}
          />
        </li>
        {permissions.baseStations.view && (
          <li>
            <Link
              to={Routes.baseStations}
              name={t('Base stations')}
              icon={<Station size={20} />}
            />
          </li>
        )}
        <li>
          <Link
            to={Routes.locations}
            name={t('Locations')}
            icon={<MapPin size={20} />}
          />
        </li>
        <li>
          <Link to={Routes.map} name={t('Map')} icon={<MapPin size={20} />} />
        </li>
        <li>
          <Link to={Routes.log} name={t('Log')} icon={<Database size={20} />} />
        </li>
        {permissions.users.view && (
          <li>
            <Link
              to={Routes.users}
              name={t('Users')}
              icon={<Users size={20} />}
            />
          </li>
        )}
        {permissions.admin.view && (
          <li>
            <Link
              to={Routes.admin}
              name={t('Admin panel')}
              icon={<Settings size={20} />}
            />
          </li>
        )}
      </ul>
    </div>
  );
};
