import React from 'react';

import styles from './Icon.module.scss';

interface Props {
  size?: number;
}

export const Menu = ({ size }: Props) => (
  <svg
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.root}
    style={!size ? {} : { width: size, height: size }}
  >
    <path
      d="M3.15967 15.509H18.1608"
      stroke="currentColor"
      strokeWidth="1.66678"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.15967 10.5089H18.1608"
      stroke="currentColor"
      strokeWidth="1.66678"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.15967 5.50857H18.1608"
      stroke="currentColor"
      strokeWidth="1.66678"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
