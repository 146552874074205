import { useMutation } from '@tanstack/react-query';

import { post } from 'helpers/api';

const logOut = () => post('/cloud/user/logout');

export const useLogOut = ({ onSuccess }: { onSuccess?: () => void }) =>
  useMutation(logOut, {
    onSuccess,
  });
