import { useMutation } from '@tanstack/react-query';

import { post } from 'helpers/api';

export type LoginParameters = {
  email: string;
  password: string;
};

const logIn = (parameters: LoginParameters) =>
  post('/cloud/user/login', parameters);

type LogInError = {
  result: {
    error:
      | string
      | {
          email: string;
        }
      | {
          password: string;
        };
  };
};

export const useLogIn = () =>
  useMutation<unknown, LogInError, LoginParameters>(logIn);
