import type {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ForwardedRef,
} from 'react';
import React, { forwardRef } from 'react';

import classNames from 'classnames';
import styles from './Pressable.module.scss';

type ButtonProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'href'> & {
  href?: undefined;
};

type AnchorProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & {
  /**
   * Enables component as an HTML Anchor Element.
   * */
  href: string;
};

export type PressableProps = ButtonProps | AnchorProps;

export type PressableElement = HTMLButtonElement | HTMLAnchorElement;

export const isAnchor = (props: PressableProps): props is AnchorProps =>
  props.href !== undefined;

export const Pressable = forwardRef<PressableElement, PressableProps>(
  ({ children, ...props }, ref) =>
    isAnchor(props) ? (
      <a
        {...props}
        ref={ref as ForwardedRef<HTMLAnchorElement>}
        className={classNames(styles.root, props.className)}
      >
        {children}
      </a>
    ) : (
      <button
        type="button"
        {...(props as ButtonProps)}
        ref={ref as ForwardedRef<HTMLButtonElement>}
        className={classNames(styles.root, props.className)}
      >
        {children}
      </button>
    ),
);

Pressable.displayName = 'Pressable';

/*
 * There are some limitations in regards of Typescript. For some reason when href is passed as template literal and the
 * template literal contains dynamic part, then event object has "any". This doesn't happen with just a simple string or
 * template literal that has no variables.
 *
 * For example this will cause a TS error:
 * <Pressable href={`/project/${projectId}`} onClick={(e) => e.preventDefault()}>
 *
 * While this will not cause an error:
 * <Pressable href={`/project/hello`} onClick={(e) => e.preventDefault()}>
 *
 * In case it is needed to have a dynamic url, then workaround for this is to store the url in a variable:
 * const linkToProject = `/project/${projectId}`;
 * <Pressable href={linkToProject} onClick={(e) => e.preventDefault()}>
 * */
