import { useState, useEffect } from 'react';

const getSize = () => ({
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
});

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    const handleResize = () => setWindowSize(getSize());

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
};
