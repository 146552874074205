/* eslint-disable react/button-has-type */
import type { ButtonHTMLAttributes, ReactNode } from 'react';
import React from 'react';
import classNames from 'classnames';

import { Loading } from '../Loading';

import styles from './Button.module.scss';

type Theme = 'primary' | 'secondary' | 'danger';

export const buttonClassNames = (
  theme: Theme = 'primary',
  isActive?: boolean,
) =>
  classNames(styles.root, styles[theme], {
    [styles.active]: isActive,
  });

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  theme?: Theme;
  isLoading?: boolean;
  isActive?: boolean;
}

export const Button = ({
  children,
  theme = 'primary',
  isLoading = false,
  isActive = false,
  ...restProps
}: Props) => (
  <button {...restProps} className={buttonClassNames(theme, isActive)}>
    {children}
    {isLoading && <Loading size={14} />}
  </button>
);
