import React from 'react';

import styles from './Icon.module.scss';

interface Props {
  size?: number;
}

export const Counter = ({ size }: Props) => (
  <svg
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.root}
    style={!size ? {} : { width: size, height: size }}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5012 5.00039C17.5012 4.54012 17.1281 4.16699 16.6678 4.16699L16.333 4.16699L16.333 7.00001C16.333 7.46028 15.9599 7.8334 15.4996 7.8334C15.0394 7.8334 14.6662 7.46028 14.6662 7.00001L14.6662 4.16699L13.833 4.16699L13.833 15.8346L16.6678 15.8346C17.1281 15.8346 17.5012 15.4614 17.5012 15.0012L17.5012 5.00039ZM12.1662 15.8346L12.1662 4.16699L7.83301 4.16699L7.83301 15.8346L12.1662 15.8346ZM6.16623 15.8346L6.16623 4.16699L3.33344 4.16699C2.87316 4.16699 2.50003 4.54012 2.50003 5.00039L2.50003 15.0012C2.50003 15.4614 2.87316 15.8346 3.33344 15.8346L6.16623 15.8346ZM15.4987 2.50021L3.33344 2.50021C1.95263 2.50021 0.833258 3.61958 0.833258 5.00039L0.833257 15.0012C0.833257 16.382 1.95263 17.5013 3.33344 17.5013L16.6678 17.5013C18.0486 17.5013 19.168 16.382 19.168 15.0012L19.168 5.00039C19.168 3.61958 18.0486 2.50021 16.6678 2.50021L15.5005 2.50021C15.5008 2.50021 15.5002 2.50021 15.5005 2.50021C15.5002 2.50021 15.499 2.50021 15.4987 2.50021ZM9.99962 6.66663C10.4599 6.66663 10.833 7.03975 10.833 7.50001L10.833 12.5C10.833 12.9603 10.4599 13.3334 9.99962 13.3334C9.53935 13.3334 9.16623 12.9603 9.16623 12.5L9.16623 7.50001C9.16623 7.03975 9.53935 6.66663 9.99962 6.66663ZM4.49962 6.66663C4.95989 6.66663 5.33301 7.03975 5.33301 7.50001L5.33301 12.5C5.33301 12.9603 4.95989 13.3334 4.49962 13.3334C4.03935 13.3334 3.66623 12.9603 3.66623 12.5L3.66623 7.50001C3.66623 7.03975 4.03935 6.66663 4.49962 6.66663Z"
    />
  </svg>
);
