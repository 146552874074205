import React from 'react';

import styles from './Icon.module.scss';

interface Props {
  size?: number;
}

export const Package = ({ size }: Props) => (
  <svg
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.root}
    style={!size ? {} : { width: size, height: size }}
  >
    <g clipPath="url(#clip0_304_17063)">
      <path
        d="M14.411 8.68173L6.9104 4.3564"
        stroke="currentColor"
        strokeWidth="1.66678"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1611 14.1822V7.51498C18.1608 7.22269 18.0836 6.93561 17.9373 6.68256C17.791 6.4295 17.5808 6.21935 17.3277 6.07321L11.4939 2.73962C11.2405 2.59333 10.9531 2.51631 10.6605 2.51631C10.3679 2.51631 10.0805 2.59333 9.82709 2.73962L3.99331 6.07321C3.74018 6.21935 3.52992 6.4295 3.38365 6.68256C3.23737 6.93561 3.16021 7.22269 3.15991 7.51498V14.1822C3.16021 14.4745 3.23737 14.7615 3.38365 15.0146C3.52992 15.2676 3.74018 15.4778 3.99331 15.6239L9.82709 18.9575C10.0805 19.1038 10.3679 19.1808 10.6605 19.1808C10.9531 19.1808 11.2405 19.1038 11.4939 18.9575L17.3277 15.6239C17.5808 15.4778 17.791 15.2676 17.9373 15.0146C18.0836 14.7615 18.1608 14.4745 18.1611 14.1822Z"
        stroke="currentColor"
        strokeWidth="1.66678"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.38477 6.64809L10.6604 10.8568L17.936 6.64809"
        stroke="currentColor"
        strokeWidth="1.66678"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6604 19.2491V10.8484"
        stroke="currentColor"
        strokeWidth="1.66678"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_304_17063">
        <rect
          width="20.0017"
          height="20.0017"
          fill="white"
          transform="translate(0.659668 0.8479)"
        />
      </clipPath>
    </defs>
  </svg>
);
