import type { QueryKey } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';

import { get } from 'helpers/api';

const USER_QUERY = 'USER_QUERY';

type Data = {
  result: {
    email?: string;
    firstName?: string;
    lastName?: string;
    admin?: boolean;
    superAdmin?: boolean;
    devicesView?: boolean;
    devicesEdit?: boolean;
    baseStationsView?: boolean;
    baseStationsEdit?: boolean;
    locationsView?: boolean;
    locationsEdit?: boolean;
    logView?: boolean;
  };
};

const initialData: Data = {
  result: {},
};

const getUser = (): Promise<Data> => get<Data>('/cloud/user');

export const useUserGet = () => {
  const { data = initialData, ...rest } = useQuery<Data, QueryKey>(
    [USER_QUERY],
    () => getUser(),
    {
      staleTime: 300000,
    },
  );

  return { data, ...rest };
};

export const usePermissions = () => {
  const user = useUserGet();

  if (user.data.result.email) {
    Sentry.setUser({ email: user.data.result.email });
  }

  const data = user.data.result;

  const isAdmin = data.admin || data.superAdmin;
  const isSuperAdmin = data.superAdmin;

  return {
    devices: {
      view: data.devicesView || isAdmin,
      edit: data.devicesEdit || isAdmin,
    },
    baseStations: {
      view: data.baseStationsView || isAdmin,
      edit: data.baseStationsEdit || isAdmin,
    },
    locations: {
      view: data.locationsView || isAdmin,
      edit: data.locationsEdit || isAdmin,
    },
    log: {
      view: data.logView || isAdmin,
    },
    users: {
      view: isAdmin,
      edit: isAdmin,
    },
    user: {
      view: isAdmin,
      viewToken: isAdmin,
      horizon: isSuperAdmin,
    },
    admin: {
      view: isSuperAdmin,
    },
  };
};
