export enum Routes {
  admin = '/admin/',
  alerts = '/alerts/',
  baseStations = '/base_stations/',
  device = '/devices/:id/',
  devices = '/devices/',
  group = '/groups/:tag/',
  groups = '/groups/',
  home = '/',
  locations = '/locations/',
  log = '/log/',
  login = '/login/',
  map = '/map/',
  search = '/search/',
  user = '/users/:id',
  users = '/users/',
}
