import React from 'react';

import styles from './Icon.module.scss';

interface Props {
  size?: number;
}

export const Check = ({ size }: Props) => (
  <svg
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.root}
    style={!size ? {} : { width: size, height: size }}
  >
    <path
      d="M16.6853 5.50857L7.51785 14.676L3.35083 10.509"
      stroke="currentColor"
      strokeWidth="1.66678"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
