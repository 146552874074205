import React from 'react';

import { Loading } from './Loading';

export const SuspenseFallback = () => (
  <div
    style={{
      width: '100%',
      marginTop: 24,
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <Loading size={30} />
  </div>
);
