import React from 'react';

import styles from './Error.module.scss';

type Props = {
  message: string | string[];
};

export const Error = ({ message }: Props) => (
  <p className={styles.root}>{message}</p>
);
