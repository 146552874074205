import React from 'react';

import styles from './Icon.module.scss';

interface Props {
  size?: number;
}

export const User = ({ size }: Props) => (
  <svg
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.root}
    style={!size ? {} : { width: size, height: size }}
  >
    <path
      d="M16.9883 17.9913V16.3245C16.9883 15.4404 16.6371 14.5925 16.0119 13.9673C15.3867 13.3421 14.5388 12.9909 13.6547 12.9909H6.98743C6.1033 12.9909 5.25538 13.3421 4.6302 13.9673C4.00503 14.5925 3.65381 15.4404 3.65381 16.3245V17.9913"
      stroke="currentColor"
      strokeWidth="1.66678"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3216 9.65824C12.1629 9.65824 13.6556 8.16556 13.6556 6.32424C13.6556 4.48292 12.1629 2.99023 10.3216 2.99023C8.48023 2.99023 6.98755 4.48292 6.98755 6.32424C6.98755 8.16556 8.48023 9.65824 10.3216 9.65824Z"
      stroke="currentColor"
      strokeWidth="1.66678"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
