import React from 'react';

import styles from './Icon.module.scss';

interface Props {
  size?: number;
}

export const Bell = ({ size }: Props) => (
  <svg
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.root}
    style={!size ? {} : { width: size, height: size }}
  >
    <g clipPath="url(#clip0_304_16565)">
      <path
        d="M15.0185 7.52388C15.0185 6.1977 14.4917 4.92583 13.554 3.98808C12.6162 3.05032 11.3443 2.5235 10.0181 2.5235C8.69197 2.5235 7.4201 3.05032 6.48235 3.98808C5.54459 4.92583 5.01777 6.1977 5.01777 7.52388C5.01777 13.3577 2.51758 15.0245 2.51758 15.0245H17.5187C17.5187 15.0245 15.0185 13.3577 15.0185 7.52388Z"
        stroke="currentColor"
        strokeWidth="1.66678"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4601 18.3579C11.3135 18.6106 11.1032 18.8202 10.8502 18.966C10.5971 19.1118 10.3102 19.1885 10.0181 19.1885C9.72609 19.1885 9.43917 19.1118 9.18611 18.966C8.93304 18.8202 8.72271 18.6106 8.57617 18.3579"
        stroke="currentColor"
        strokeWidth="1.66678"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_304_16565">
        <rect
          width="20.0017"
          height="20.0017"
          fill="white"
          transform="translate(0.0175781 0.856934)"
        />
      </clipPath>
    </defs>
  </svg>
);
