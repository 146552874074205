import type { HTMLAttributes, ReactNode } from 'react';
import React from 'react';
import type * as CSS from 'csstype';

export type SpacingLevels =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14;

export const space: { [key in SpacingLevels]: string } = {
  0: '0',
  1: '4px',
  2: '8px',
  3: '12px',
  4: '16px',
  5: '20px',
  6: '24px',
  7: '28px',
  8: '32px',
  9: '36px',
  10: '40px',
  11: '44px',
  12: '48px',
  13: '52px',
  14: '56px',
};

type FlexProps = {
  /**
   * Toggles inline-flex.
   */
  inline?: boolean;
  /**
   * Sets align-items.
   */
  align?: CSS.Properties['alignItems'];
  /**
   * Sets justify-content.
   */
  justify?: CSS.Properties['justifyContent'];
  /**
   * Sets flex-wrap.
   */
  wrap?: CSS.Properties['flexWrap'];
  /**
   * Sets flex-direction.
   */
  direction?: CSS.Properties['flexDirection'];
  /**
   * Sets gap, or row-gap and column-gap, using theme spacing.
   */
  gap?: SpacingLevels | [rowGap: SpacingLevels, columnGap: SpacingLevels];
  /**
   * Sets flex-basis when used as a child of another flex container.
   */
  basis?: CSS.Properties['flexBasis'];
  /**
   * Sets flex-grow when used as a child of another flex container.
   */
  grow?: CSS.Properties['flexGrow'];
  /**
   * Sets flex-shrink when used as a child of another flex container.
   */
  shrink?: CSS.Properties['flexShrink'];
  /**
   * Sets align-self when used as a child of another flex container.
   */
  alignSelf?: CSS.Properties['alignSelf'];
  /**
   * Renders any flex items in the flex container.
   * */
  children: ReactNode;
  fullWidth?: boolean;
  fullHeight?: boolean;
} & (
  | ({
      /**
       * Sets the HTML element to render for the flex container.
       * */
      as?: 'div';
    } & Omit<HTMLAttributes<HTMLDivElement>, 'children'>)
  | ({ as: 'span' } & Omit<HTMLAttributes<HTMLSpanElement>, 'children'>)
  | ({ as: 'li' } & Omit<HTMLAttributes<HTMLLIElement>, 'children'>)
  | ({ as: 'section' | 'article' | 'main' | 'aside' } & Omit<
      HTMLAttributes<HTMLElement>,
      'children'
    >)
);

export const Flex = ({
  inline = false,
  align = 'flex-start',
  justify = 'flex-start',
  wrap = 'wrap',
  direction = 'row',
  basis = 'auto',
  grow = 0,
  shrink = 1,
  alignSelf = 'normal',
  gap = 0,
  children,
  fullWidth,
  fullHeight,
  as: Element = 'div',
  ...rest
}: FlexProps) => {
  const normalizedGap = Array.isArray(gap)
    ? gap.map((item) => space[item])
    : [space[gap], space[gap]];

  return (
    // @ts-ignore
    <Element
      {...rest}
      style={{
        display: inline ? 'inline-flex' : 'flex',
        gap: `${normalizedGap[0]} ${normalizedGap[1]}`,
        maxWidth: '100%',
        width: fullWidth ? '100%' : 'auto',
        height: fullHeight ? '100%' : 'auto',
        alignItems: align,
        justifyContent: justify,
        flexWrap: wrap,
        flexDirection: direction,
        flexBasis: basis,
        flexGrow: grow,
        flexShrink: shrink,
        alignSelf,
      }}
    >
      {children}
    </Element>
  );
};
