import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useWindowSize } from 'hooks/useWindowSize';

import { useNavigation } from 'store/navigation';

import { IconButton } from 'ui-atoms/IconButton';
import { useUserMenu } from 'store/userMenu';
import { Menu } from 'ui-atoms/Icons/Menu';
import { Container } from './Container';
import styles from './NavigationPanel.module.scss';

export const NavigationPanel = () => {
  const { t } = useTranslation();

  const navigation = useNavigation();
  const userMenu = useUserMenu();

  const { windowWidth } = useWindowSize();

  const isSmallScreen = windowWidth <= 540;
  const isLargeScreen = windowWidth > 1200;

  useEffect(() => {
    if (!isSmallScreen) {
      navigation.close();
    }
    if (isLargeScreen) {
      navigation.open();
    }
  }, [isSmallScreen, isLargeScreen]);

  return (
    <>
      <IconButton
        type="button"
        ariaLabel={navigation.opened ? t('Close menu') : t('Open menu')}
        onClick={() => {
          navigation.toggle();
          userMenu.close();
        }}
      >
        <Menu size={20} />
      </IconButton>
      {!isSmallScreen && !isLargeScreen && navigation.opened && (
        <button
          aria-label={t('Close header')}
          tabIndex={-1}
          className={styles.overlay}
          type="button"
          onClick={() => navigation.close()}
        />
      )}
      {navigation.opened && <Container />}
    </>
  );
};
