import React from 'react';

import { useTranslation } from 'react-i18next';

import { FileText } from 'ui-atoms/Icons/FileText';
import PrivacyPolicyPDF from './PrivacyPolicyEN.pdf';
import styles from './PrivacyPolicy.module.scss';

export const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <a
      className={styles.link}
      href={PrivacyPolicyPDF}
      target="_blank"
      rel="noreferrer"
    >
      <span className={styles.icon}>
        <FileText size={20} />
      </span>
      <span>{t('Privacy policy')}</span>
    </a>
  );
};
