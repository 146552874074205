import React from 'react';

import { useUserMenuQuery } from 'generated/graphql';
import classNames from 'classnames';
import styles from './Ball.module.scss';

type BallProps = { large?: boolean };

export const Ball = ({ large = false }: BallProps) => {
  const { data } = useUserMenuQuery();

  return (
    <span className={classNames(styles.root, { [styles.large]: large })}>
      {data?.userCurrent?.firstName[0].toUpperCase()}
      {data?.userCurrent?.lastName[0].toUpperCase()}
    </span>
  );
};
