import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { initI18n } from './i18n';

import 'assets/css/index.scss';

import App from './App';

if (process.env.NODE_ENV === 'production' && process.env.SENTRY_DNS) {
  Sentry.init({
    dsn: process.env.SENTRY_DNS,
    // It is possible to record all user sessions. Might consider this over Hotjar integration.
    replaysSessionSampleRate: 0,
    // Record all sessions when an error occurs.
    replaysOnErrorSampleRate: 1,
    integrations: [new Sentry.Replay({ maskAllText: false })],
  });
}

initI18n();

const link = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  credentials: 'include',
});

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

createRoot(document.getElementById('root')!).render(
  <ApolloProvider client={client}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </ApolloProvider>,
);
