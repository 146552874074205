import React from 'react';

import * as DropDown from 'ui-atoms/DropDown';
import { ChevronDown } from 'ui-atoms/Icons/ChevronDown';
import { useWindowSize } from 'hooks/useWindowSize';
import { useNavigation } from 'store/navigation';
import { Menu } from '../Menu';
import styles from './LargeScreenMenu.module.scss';
import { Ball } from '../Ball';

export const LargeScreenMenu = () => {
  const { windowWidth } = useWindowSize();
  const navigation = useNavigation();

  return (
    <DropDown.Root>
      <DropDown.Trigger
        type="button"
        className={styles.trigger}
        onClick={() => {
          if (windowWidth <= 1200 && navigation.opened) {
            navigation.close();
          }
        }}
      >
        <Ball />
        <span className={styles.button}>
          <ChevronDown size={20} />
        </span>
      </DropDown.Trigger>
      <DropDown.Popover align="end">
        <div className={styles.popover}>
          <Menu />
        </div>
      </DropDown.Popover>
    </DropDown.Root>
  );
};
