import React, { useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import type { FormikProps } from 'formik';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { Routes } from 'constants/routes';

import { TextField } from 'ui-atoms/TextField';
import { Button } from 'ui-atoms/Button';
import { Flex } from 'ui-atoms/Flex';

import { useAuthentication } from 'store/authentication';

import type { LoginParameters } from 'queries/user/useLogIn';
import { useLogIn } from 'queries/user/useLogIn';

import { LogIn } from 'ui-atoms/Icons/LogIn';
import styles from './Login.module.scss';

enum FIELD {
  EMAIL = 'email',
  PASSWORD = 'password',
}

const useSchema = () => {
  const { t } = useTranslation();

  return Yup.object().shape({
    [FIELD.EMAIL]: Yup.string()
      .email(t('Please enter a valid email address!'))
      .required(t('Email is a required field.')),
    [FIELD.PASSWORD]: Yup.string().required(t('Password is a required field.')),
  });
};

type FormValues = LoginParameters;

const InnerForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  status = {},
  isSubmitting,
}: FormikProps<FormValues>) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  const isEmailError =
    (touched[FIELD.EMAIL] && errors[FIELD.EMAIL]) || status?.[FIELD.EMAIL];
  const isPasswordError =
    (touched[FIELD.PASSWORD] && errors[FIELD.PASSWORD]) ||
    status?.[FIELD.PASSWORD];

  const { t } = useTranslation();

  return (
    <Form noValidate className={styles.form}>
      <Flex direction="column" gap={6}>
        <Flex direction="column" gap={3}>
          <TextField
            ref={ref}
            name={FIELD.EMAIL}
            required
            label={t('Email')}
            type="email"
            value={values[FIELD.EMAIL]}
            error={
              (isEmailError && errors[FIELD.EMAIL]) || status[FIELD.EMAIL] || ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <TextField
            name={FIELD.PASSWORD}
            required
            label={t('Password')}
            type="password"
            value={values[FIELD.PASSWORD]}
            error={
              (isPasswordError && errors[FIELD.PASSWORD]) ||
              status[FIELD.PASSWORD] ||
              ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Flex>
        <Flex direction="column" align="stretch">
          <Button
            type="submit"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          >
            {t('Log in')} <LogIn />
          </Button>
        </Flex>
      </Flex>
    </Form>
  );
};

export const LoginForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authentication = useAuthentication();

  const { mutate: logIn } = useLogIn();

  const { from } = (location.state as any) || {
    from: { pathname: Routes.home },
  };

  const redirectTo = from !== Routes.login ? from : Routes.home;

  const schema = useSchema();

  return (
    <Formik
      initialValues={{
        [FIELD.EMAIL]: '',
        [FIELD.PASSWORD]: '',
      }}
      validationSchema={schema}
      onSubmit={async (values, actions) => {
        actions.setStatus({});
        actions.setSubmitting(true);

        await logIn(values, {
          onSuccess: () => {
            authentication.authenticate();
            navigate(redirectTo);
          },
          onError: (error) => {
            if (typeof Object.values(error.result.error)[0] !== 'string') {
              return;
            }

            const err = Object.entries(error.result.error)[0];
            const name = err[0];
            const message = err[1];

            if (!Object.values(FIELD).includes(name as FIELD)) {
              return;
            }

            if (typeof message !== 'string') {
              return;
            }

            actions.setStatus({
              [name]: message,
            });
          },
        });

        actions.setSubmitting(false);
      }}
    >
      {(formikProps) => <InnerForm {...formikProps} />}
    </Formik>
  );
};
