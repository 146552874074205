/* eslint-disable jsx-a11y/label-has-associated-control */
import type { ReactNode, LabelHTMLAttributes } from 'react';
import React from 'react';

import styles from './Label.module.scss';

interface Props extends LabelHTMLAttributes<HTMLLabelElement> {
  children: ReactNode;
  isRequired?: boolean;
}

export const Label = ({
  children,
  isRequired = false,
  ...restProps
}: Props) => (
  <label {...restProps} className={styles.root}>
    {children}
    {isRequired && <span className={styles.asterix}> *</span>}
  </label>
);
