import React from 'react';
import type { ToastContainerProps } from 'react-toastify';
import { ToastContainer, Slide, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

export const Toast = ({
  closeOnClick = true,
  ...restProps
}: ToastContainerProps) => (
  <ToastContainer
    position="bottom-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick={closeOnClick}
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    transition={Slide}
    {...restProps}
  />
);

export const makeToast = toast;
