import React from 'react';
import { useTranslation } from 'react-i18next';

import { useWindowSize } from 'hooks/useWindowSize';
import { useUserMenuQuery } from 'generated/graphql';
import { LargeScreenMenu } from './LargeScreenMenu';
import { SmallScreenMenu } from './SmallScreenMenu';

export const UserMenu = () => {
  const { data } = useUserMenuQuery();
  const { i18n } = useTranslation();

  const language = data?.userCurrent?.language;

  if (language && language !== i18n.language) {
    i18n.changeLanguage(language);
  }

  const { windowWidth } = useWindowSize();

  return windowWidth > 540 ? <LargeScreenMenu /> : <SmallScreenMenu />;
};
