import React from 'react';

import styles from './Icon.module.scss';

interface Props {
  size?: number;
}

export const Search = ({ size }: Props) => (
  <svg
    viewBox="0 0 20 21"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.root}
    style={!size ? {} : { width: size, height: size }}
  >
    <path
      d="M9.16651 16.3064C12.8487 16.3064 15.8337 13.3214 15.8337 9.63916C15.8337 5.95695 12.8487 2.97192 9.16651 2.97192C5.48429 2.97192 2.49927 5.95695 2.49927 9.63916C2.49927 13.3214 5.48429 16.3064 9.16651 16.3064Z"
      stroke="currentColor"
      strokeWidth="1.66678"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5007 17.9734L13.875 14.3477"
      stroke="currentColor"
      strokeWidth="1.66678"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
