import type { InputHTMLAttributes, ReactNode } from 'react';
import React, { forwardRef, useRef, useId } from 'react';
import classNames from 'classnames';

import { Flex } from 'ui-atoms/Flex';
import { Label } from 'ui-atoms/Label';
import { Loading } from 'ui-atoms/Loading';
import { Error } from 'ui-atoms/Error';
import styles from './TextField.module.scss';

export interface TextFieldProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
  /**
   * Renders text content and / or any HTML node on the left side of the input.
   */
  prefix?: ReactNode;
  /**
   * Renders text content and / or any HTML node on the right side of the input.
   */
  suffix?: ReactNode;
  label?: ReactNode;
  isLoading?: boolean;
  error?: string;
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    { prefix, suffix, label, isLoading, required, error, ...restProps },
    ref,
  ) => {
    const localRef = useRef<HTMLInputElement | null>(null);

    const id = useId();

    return (
      <Flex direction="column" gap={2} fullWidth>
        {label && (
          <Flex gap={2}>
            <Label isRequired={required} htmlFor={id}>
              {label}
            </Label>
            {isLoading && <Loading size={14} />}
          </Flex>
        )}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className={classNames(styles.root, {
            [styles.disabled]: restProps.disabled,
          })}
          onClick={() => {
            localRef.current?.focus();
          }}
        >
          {prefix}
          <div className={styles.inputWrap}>
            <input
              ref={(el) => {
                localRef.current = el;
                if (!ref) {
                  return;
                }

                if ('current' in ref) {
                  // eslint-disable-next-line no-param-reassign
                  ref.current = el;
                } else if (typeof ref === 'function') {
                  ref(el);
                }
              }}
              type="text"
              {...restProps}
              id={id}
              className={styles.input}
            />
          </div>
          {suffix}
        </div>
        {error && <Error message={error} />}
      </Flex>
    );
  },
);
