import type { HTMLAttributes } from 'react';
import React from 'react';
import classNames from 'classnames';

import styles from './Heading.module.scss';

export enum HeadingLevel {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
}

type HeadingProps = HTMLAttributes<HTMLHeadingElement> & {
  element: HeadingLevel;
  level: HeadingLevel;
};

const Heading = ({ element, level, children }: HeadingProps) => {
  const Element = element;

  return (
    <Element className={classNames(styles.root, styles[level])}>
      {children}
    </Element>
  );
};

type Props = HTMLAttributes<HTMLHeadingElement> & {
  element?: HeadingLevel;
};

export const H1 = ({ element = HeadingLevel.H1, children }: Props) => (
  <Heading element={element} level={HeadingLevel.H1}>
    {children}
  </Heading>
);

export const H2 = ({ element = HeadingLevel.H2, children }: Props) => (
  <Heading element={element} level={HeadingLevel.H2}>
    {children}
  </Heading>
);

export const H3 = ({ element = HeadingLevel.H3, children }: Props) => (
  <Heading element={element} level={HeadingLevel.H3}>
    {children}
  </Heading>
);

export const H4 = ({ element = HeadingLevel.H4, children }: Props) => (
  <Heading element={element} level={HeadingLevel.H4}>
    {children}
  </Heading>
);

export const H5 = ({ element = HeadingLevel.H5, children }: Props) => (
  <Heading element={element} level={HeadingLevel.H5}>
    {children}
  </Heading>
);

export const H6 = ({ element = HeadingLevel.H6, children }: Props) => (
  <Heading element={element} level={HeadingLevel.H6}>
    {children}
  </Heading>
);
