import { create } from 'zustand';

type NavigationState = {
  opened: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
};

const useStore = create<NavigationState>()((set) => ({
  opened: false,
  open: () => set(() => ({ opened: true })),
  close: () => set(() => ({ opened: false })),
  toggle: () => set((state) => ({ opened: !state.opened })),
}));

export const useNavigation = () => {
  return useStore((state) => state);
};
