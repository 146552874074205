import type { ReactNode } from 'react';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Loading } from 'ui-atoms/Loading';
import { Pressable } from 'ui-atoms/Pressable';
import type { PressableElement, PressableProps } from 'ui-atoms/Pressable';
import styles from './IconButton.module.scss';

export type IconButtonProps = {
  /**
   * Toggles the active state of the button.
   */
  active?: boolean;
  /**
   * Adds accessibility label.
   */
  ariaLabel: string;
  /**
   * Toggles disabled state of the button.
   */
  disabled?: boolean;
  /**
   * Toggles the loading icon within the button.
   */
  loading?: boolean;
  /**
   * Displays Icon component in the button.
   */
  children: ReactNode;
} & PressableProps;

export const IconButton = forwardRef<PressableElement, IconButtonProps>(
  ({ ariaLabel, loading = false, children, ...rest }, ref) => {
    const { t } = useTranslation();

    return (
      <Pressable
        ref={ref}
        aria-label={ariaLabel}
        disabled={('disabled' in rest && rest.disabled) || loading}
        className={styles.root}
        {...rest}
      >
        {loading ? (
          <Loading
            aria-busy="true"
            aria-live="polite"
            ariaLabel={t('Loading')}
          />
        ) : (
          children
        )}
      </Pressable>
    );
  },
);

IconButton.displayName = 'IconButton';
