import React from 'react';

import styles from './Icon.module.scss';

interface Props {
  size?: number;
}

export const FileText = ({ size }: Props) => (
  <svg
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.root}
    style={!size ? {} : { width: size, height: size }}
  >
    <g clipPath="url(#clip0_304_17308)">
      <path
        d="M12.3547 2.5146H5.68755C5.24548 2.5146 4.82153 2.69021 4.50894 3.0028C4.19636 3.31538 4.02075 3.73934 4.02075 4.1814V17.5157C4.02075 17.9578 4.19636 18.3818 4.50894 18.6943C4.82153 19.0069 5.24548 19.1825 5.68755 19.1825H15.6883C16.1304 19.1825 16.5543 19.0069 16.8669 18.6943C17.1795 18.3818 17.3551 17.9578 17.3551 17.5157V7.51498L12.3547 2.5146Z"
        stroke="currentColor"
        strokeWidth="1.66678"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0223 15.0153H7.35425"
        stroke="currentColor"
        strokeWidth="1.66678"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0223 11.6818H7.35425"
        stroke="currentColor"
        strokeWidth="1.66678"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.02125 8.34789H8.18775H7.35425"
        stroke="currentColor"
        strokeWidth="1.66678"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3545 2.51436V7.51536H17.3555"
        stroke="currentColor"
        strokeWidth="1.66678"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_304_17308">
        <rect
          width="20.0017"
          height="20.0017"
          fill="white"
          transform="translate(0.687256 0.8479)"
        />
      </clipPath>
    </defs>
  </svg>
);
