import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import i18nextBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const languages = ['lv', 'en'] as const;

export type Language = (typeof languages)[number];

export const initI18n = () =>
  i18n
    .use(i18nextBackend)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      fallbackLng: 'en',
      supportedLngs: languages,
      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
      react: {
        useSuspense: false, // Fixes error: "A component suspended while responding to synchronous input. This will cause the UI to be replaced with a loading indicator. To fix, updates that suspend should be wrapped with startTransition."
      },
      returnNull: false,
      detection: {
        caches: ['localStorage', 'cookie'],
      },
    });
