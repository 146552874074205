import React, { useEffect, useState } from 'react';

import { useWindowSize } from 'hooks/useWindowSize';
import { Search as SearchIcon } from 'ui-atoms/Icons/Search';
import { IconButton } from 'ui-atoms/IconButton';
import { useNavigation } from 'store/navigation';
import { useUserMenu } from 'store/userMenu';
import { useLocation, useNavigate } from 'react-router-dom';
import { Routes } from 'constants/routes';
import { useSearchOpened } from 'ui-layout/Shell/Search/useSearchOpened';
import { useSearch } from 'store/search';
import { SearchField } from './SearchField';

import styles from './Search.module.scss';

export const Search = () => {
  const { windowWidth } = useWindowSize();
  const navigation = useNavigation();
  const userMenu = useUserMenu();
  const navigate = useNavigate();
  const location = useLocation();
  const [previousPage, setPreviousPage] = useState<Routes>(Routes.home);
  const { setSearch } = useSearch();

  useEffect(() => {
    if (location.pathname !== Routes.search) {
      setPreviousPage(location.pathname as Routes);
      setSearch('');
    }
  }, [location.pathname]);

  const searchOpened = useSearchOpened();

  return windowWidth > 540 ? (
    <div className={styles.search}>
      <SearchField />
    </div>
  ) : (
    <IconButton
      type="button"
      ariaLabel={searchOpened ? 'Aizvērt meklēšanu' : 'Atvērt meklēšanu'}
      onClick={() => {
        if (searchOpened) {
          if (!userMenu.opened && !navigation.opened) {
            navigate(previousPage);
          }
        } else {
          navigate(Routes.search, {
            state: { previousRoute: location.pathname },
          });
        }

        userMenu.close();

        if (windowWidth <= 1200 && navigation.opened) {
          navigation.close();
        }
      }}
    >
      <SearchIcon size={20} />
    </IconButton>
  );
};
