import React, { Suspense, useState, useEffect } from 'react';
import {
  Route,
  Routes as ReactRouterRoutes,
  Navigate,
  useLocation,
} from 'react-router-dom';

import { lazyWithRetry } from 'helpers/lazyWithRetry';

import { Toast } from 'ui-atoms/Toast';
import { SuspenseFallback } from 'ui-atoms/Loading';

import { Routes } from 'constants/routes';

import { Shell } from 'ui-layout/Shell';

import { usePermissions } from 'queries/user/usePermissions';

import { useAuthenticate } from 'store/authentication';
import Login from 'views/Login';

const Admin = lazyWithRetry(() => import('views/Admin'));
const Alerts = lazyWithRetry(() => import('views/Alerts'));
const Groups = lazyWithRetry(() => import('views/Groups'));
const Group = lazyWithRetry(() => import('views/Group'));
const Locations = lazyWithRetry(() => import('views/Locations'));
const Log = lazyWithRetry(() => import('views/Log'));
const BaseStations = lazyWithRetry(() => import('views/BaseStations'));
const Devices = lazyWithRetry(() => import('views/Devices'));
const Device = lazyWithRetry(() => import('views/Device'));
const Users = lazyWithRetry(() => import('views/Users'));
const User = lazyWithRetry(() => import('views/User'));
const NotFound = lazyWithRetry(() => import('views/NotFound'));
const NoAccess = lazyWithRetry(() => import('views/NoAccess'));
const Search = lazyWithRetry(() => import('views/Search'));
const Map = lazyWithRetry(() => import('views/Map'));

const $style = document.createElement('style');
document.head.appendChild($style);
$style.setAttribute('id', 'show-focus');

const useShowFocusWhenKeyboardIsUsed = () => {
  const [showOutline, setShowOutline] = useState(true);

  useEffect(() => {
    document.addEventListener('mousedown', () => {
      setShowOutline(false);
    });

    document.addEventListener('keydown', () => {
      setShowOutline(true);
    });
  }, []);

  useEffect(() => {
    $style.innerHTML = `*:focus, button:focus { outline: ${
      showOutline ? 1 : 0
    }; }`;
  }, [showOutline]);

  return showOutline;
};

const AuthenticatedApp = () => {
  const permissions = usePermissions();

  return (
    <Shell>
      <Suspense fallback={<SuspenseFallback />}>
        <ReactRouterRoutes>
          <Route path="*" element={<NotFound />} />
          <Route path={Routes.home} element={<Devices />} />
          <Route path={`${Routes.device}/*`} element={<Device />} />
          <Route path={`${Routes.groups}/*`} element={<Groups />} />
          <Route path={`${Routes.group}/*`} element={<Group />} />
          <Route path={`${Routes.alerts}/*`} element={<Alerts />} />
          <Route path={Routes.locations} element={<Locations />} />
          <Route path={Routes.map} element={<Map />} />
          <Route path={Routes.log} element={<Log />} />
          <Route
            path={Routes.baseStations}
            element={
              permissions.baseStations.view ? <BaseStations /> : <NoAccess />
            }
          />
          <Route path={Routes.search} element={<Search />} />
          <Route
            path={`${Routes.admin}/*`}
            element={permissions.admin.view ? <Admin /> : <NoAccess />}
          />
          <Route
            path={Routes.users}
            element={permissions.users.view ? <Users /> : <NoAccess />}
          />
          <Route
            path={Routes.user}
            element={permissions.user.view ? <User /> : <NoAccess />}
          />
        </ReactRouterRoutes>
      </Suspense>
    </Shell>
  );
};

const UnauthenticatedApp = () => {
  const location = useLocation();

  const shouldRedirectToLogin = `${location.pathname}` !== Routes.login;

  return !shouldRedirectToLogin ? null : (
    <Navigate to={Routes.login} replace state={{ from: location }} />
  );
};

const App = () => {
  useShowFocusWhenKeyboardIsUsed();

  const authentication = useAuthenticate();

  const renderApp = authentication.authenticated !== null;

  return renderApp ? (
    <>
      <ReactRouterRoutes>
        <Route path={Routes.login} element={<Login />} />
        <Route
          path="*"
          element={
            <Suspense fallback={<SuspenseFallback />}>
              {authentication.authenticated === false && <UnauthenticatedApp />}
              {authentication.authenticated === true && <AuthenticatedApp />}
            </Suspense>
          }
        />
      </ReactRouterRoutes>
      <Toast />
    </>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
};

export default App;
