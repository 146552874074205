import { create } from 'zustand';

type SearchState = {
  value: string;
  set: (value: string) => void;
};

const useStore = create<SearchState>()((set) => ({
  value: '',
  set: (value) => set(() => ({ value })),
}));

export const useSearch = () => {
  const [search, setSearch] = useStore((state) => [state.value, state.set]);

  return { search, setSearch } as const;
};
