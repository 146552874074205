import React from 'react';

import styles from './Icon.module.scss';

interface Props {
  size?: number;
}

export const Users = ({ size }: Props) => (
  <svg
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.root}
    style={!size ? {} : { width: size, height: size }}
  >
    <g clipPath="url(#clip0_304_16726)">
      <path
        d="M20.1585 17.9919V16.3249C20.1579 15.5862 19.912 14.8686 19.4595 14.2847C19.0069 13.7009 18.3732 13.2839 17.658 13.0992"
        stroke="currentColor"
        strokeWidth="1.66678"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.158 17.9913V16.3245C15.158 15.4404 14.8067 14.5925 14.1816 13.9673C13.5564 13.3421 12.7085 12.9909 11.8243 12.9909H5.15711C4.27297 12.9909 3.42506 13.3421 2.79988 13.9673C2.17471 14.5925 1.82349 15.4404 1.82349 16.3245V17.9913"
        stroke="currentColor"
        strokeWidth="1.66678"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3242 3.09831C15.0414 3.28193 15.677 3.69902 16.1309 4.28381C16.5849 4.8686 16.8313 5.58784 16.8313 6.32813C16.8313 7.06842 16.5849 7.78766 16.1309 8.37245C15.677 8.95724 15.0414 9.37432 14.3242 9.55794"
        stroke="currentColor"
        strokeWidth="1.66678"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.49099 9.65824C10.3323 9.65824 11.825 8.16556 11.825 6.32424C11.825 4.48292 10.3323 2.99023 8.49099 2.99023C6.64967 2.99023 5.15698 4.48292 5.15698 6.32424C5.15698 8.16556 6.64967 9.65824 8.49099 9.65824Z"
        stroke="currentColor"
        strokeWidth="1.66678"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_304_16726">
        <rect
          width="20.0017"
          height="20.0017"
          fill="white"
          transform="translate(0.990234 0.48999)"
        />
      </clipPath>
    </defs>
  </svg>
);
