import React from 'react';
import { useTranslation } from 'react-i18next';

import { User } from 'ui-atoms/Icons/User';
import { IconButton } from 'ui-atoms/IconButton';
import { useNavigation } from 'store/navigation';
import { useUserMenu } from 'store/userMenu';
import { useWindowSize } from 'hooks/useWindowSize';
import { Container } from './Container';

export const SmallScreenMenu = () => {
  const { t } = useTranslation();
  const { windowWidth } = useWindowSize();
  const navigation = useNavigation();
  const userMenu = useUserMenu();

  return (
    <>
      <IconButton
        type="button"
        ariaLabel={
          navigation.opened
            ? t('Close user navigation')
            : t('Open user navigation')
        }
        onClick={() => {
          userMenu.toggle();

          if (windowWidth <= 1200 && navigation.opened) {
            navigation.close();
          }
        }}
      >
        <User size={20} />
      </IconButton>
      {userMenu.opened && <Container />}
    </>
  );
};
