import React, { useEffect } from 'react';
import FocusLock from 'react-focus-lock';
import { useTranslation } from 'react-i18next';

import { getScrollbarWidth } from 'helpers/getScrollbarWidth';
import { useUserMenu } from 'store/userMenu';
import { Menu } from '../Menu';
import styles from './SmallScreenMenu.module.scss';

const scrollbarWidth = getScrollbarWidth();

export const Container = () => {
  const { t } = useTranslation();
  const userMenu = useUserMenu();

  useEffect(() => {
    const isNoBodyScroll = userMenu.opened;
    document.body.style.paddingRight = !isNoBodyScroll
      ? ''
      : `${scrollbarWidth}px`;
    document.body.style.overflow = !isNoBodyScroll ? '' : 'hidden';

    return () => {
      document.body.style.paddingRight = '';
      document.body.style.overflow = '';
    };
  }, [userMenu.opened]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={styles.container}
      onKeyUp={(e) => {
        if (e.key === 'Escape') {
          userMenu.close();
        }
      }}
    >
      <FocusLock>
        <div className={styles.header}>
          <h2 className={styles.heading}>{t('Profile')}</h2>
        </div>
        <div className={styles.content}>
          <Menu />
        </div>
      </FocusLock>
    </div>
  );
};
